import React, { useEffect, useRef, useState } from 'react';
import { IonLoading, IonToast, IonRow, IonCol, IonList, IonItem, IonInput, IonText, IonGrid, getConfig, IonActionSheet, IonCheckbox, IonLabel, IonDatetime, IonRadioGroup, IonRadio, IonButton, IonImg } from '@ionic/react';
import './Auth.scss';
import { connect } from '../data/connect';
import { RouteComponentProps, useLocation } from 'react-router';
import { fetchCustomerBlock, registerCustomer } from '../data/dataApi';
import { ICustomerBlockResponse } from '../models/Service/CustomerBlockResponse';
import { Customer } from '../models/Base/Customer';
import { isPlatform } from '@ionic/react';

interface OwnProps extends RouteComponentProps { }

interface Error {
     showError: boolean;
     message?: string;
}

interface StateProps {
     mode: 'ios' | 'md';
}

interface DispatchProps {

}

interface AuthProps extends OwnProps, StateProps, DispatchProps { }

const Auth: React.FC<AuthProps> = ({ mode }) => {
     const ios = mode === 'ios';
     const [loading, setLoading] = useState<boolean>(false);
     const [error, setError] = useState<Error>({ showError: false });
     const [customerBlock, setCustomerBlock] = useState<ICustomerBlockResponse>({});
     const [businessId, setBusinessId] = useState('');
     const [businessName, setBusinessName] = useState('');
     const [logoUrl, setLogoUrl] = useState('');
     const [mobileNumber, setMobileNumberVal] = useState('');
     const [publicKey, setPublicKey] = useState('');
     const [firstName, setFirstName] = useState('');
     const [lastName, setLastName] = useState('');
     const [emailId, setEmailId] = useState('');
     const [address, setAddress] = useState('');
     const [addressSelected, setAddressSelected] = useState('');
     const [streetNumber, setStreetNumber] = useState('');
     const [route, setRoute] = useState('');
     const [locality, setLocality] = useState('');
     const [state, setState] = useState('');
     const [county, setCounty] = useState('');
     const [country, setCountry] = useState('');
     const [zipCode, setZipCode] = useState('');
     const [homeLat, setHomeLat] = useState(0);
     const [homeLng, setHomeLng] = useState(0);
     const [dob, setDob] = useState('01/01/1970');
     const [gender, setGender] = useState('Female');
     const [firstNameError, setFirstNameError] = useState(false);
     const [lastNameError, setLastNameError] = useState(false);
     const [addressError, setAddressError] = useState(false);
     const [emailError, setEmailError] = useState(false);
     const [signupVisible, setSignupVisibility] = useState(false);
     const [otpVisible, setOTPVisibility] = useState(true);
     const [socialLoginEnabled, setSocialLoginEnabled] = useState(false);
     const [otp1, setOTP1] = useState('');
     const [otp2, setOTP2] = useState('');
     const [otp3, setOTP3] = useState('');
     const [otp4, setOTP4] = useState('');
     const [otp5, setOTP5] = useState('');
     const [otp6, setOTP6] = useState('');
     const [showCountryPicker, setShowCountryPicker] = useState(false);
     const [errorMessage, setErrorMessage] = useState('');
     const [formSubmitted, setFormSubmitted] = useState(false);
     const [mobileNumberError, setMobileNumberError] = useState(false);
     const otp1Input = React.useRef<HTMLIonInputElement>(null);
     const otp2Input = React.useRef<HTMLIonInputElement>(null);
     const otp3Input = React.useRef<HTMLIonInputElement>(null);
     const otp4Input = React.useRef<HTMLIonInputElement>(null);
     const otp5Input = React.useRef<HTMLIonInputElement>(null);
     const otp6Input = React.useRef<HTMLIonInputElement>(null);
     const countryCode = useRef<string>('partial');
     const preserveLogin = useRef(false);
     let query = useQuery();

     function useQuery() {
          return new URLSearchParams(useLocation().search);
     }

     useEffect(() => {
          var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
          tz.toLowerCase().indexOf('america') >= 0 ? countryCode.current = "+1" : countryCode.current = "+91";
          const bid = query.get("bid");
          if (bid) {
               setBusinessId(bid);
               setLogoUrl("https://infopointblobstorage.blob.core.windows.net/logo-images/" + bid + ".jpg");
          }
          const bname = query.get("bname");
          if (bname) {
               setBusinessName(bname);
          }
          const pk = query.get("pk");
          if (pk) {
               setPublicKey(pk);
          }
     }, [countryCode]);

     const login = async (e: React.FormEvent) => {
          e.preventDefault();

          setFormSubmitted(true);
          if (!mobileNumber) {
               setMobileNumberError(true);
               setErrorMessage('Mobile number is required');
               return;
          }

          if (mobileNumber && mobileNumber.length === 10) {
               try {
                    setLoading(true);
                    var maxLat = 0.0, maxLng = 0.0, minLat = 0.0, minLng = 0.0;
                    var devicePlatform = ios ? "iOS" : "Android";
                    setCustomerBlock(await fetchCustomerBlock(publicKey, devicePlatform, countryCode.current, mobileNumber, '', true, maxLat, maxLng, minLat, minLng));
                    setOTP1('');
                    setLoading(false);
                    setOTPVisibility(false);
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
               }
               catch (e) {
                    setLoading(false);
                    setError({ showError: true, message: (e as Error).message });
               }
          }
     };

     const processMobile = async (e: CustomEvent, val: string) => {
          setMobileNumberError(false);
          setErrorMessage('');
          setMobileNumberVal(val);
          if (val.length === 10) {
               if (otp1Input.current !== null) {
                    otp1Input.current.setFocus();
               }
          } else {
               setOTPVisibility(true);
          }
     };

     const processOTP = async (e: CustomEvent, digit: number, val: string) => {
          e.preventDefault();
          if (digit === 1) {
               setMobileNumberError(false);
               setErrorMessage('');
               setOTP6('');
               setOTP5('');
               setOTP4('');
               setOTP3('');
               setOTP2('');
               setOTP1(val);
               if (val.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
                    return;
               }
               if (otp2Input.current !== null) {
                    otp2Input.current.setFocus();
               }
          } else if (digit === 2) {
               setOTP2(val);
               if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
                    return;
               }
               if (val.length > 0 && otp3Input.current !== null) {
                    otp3Input.current.setFocus();
               }
          } else if (digit === 3) {
               setOTP3(val);
               if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
                    return;
               }
               if (val.length > 0 && otp4Input.current !== null) {
                    otp4Input.current.setFocus();
               }
          } else if (digit === 4) {
               setOTP4(val);
               if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
                    return;
               }
               if (val.length > 0 && otp5Input.current !== null) {
                    otp5Input.current.setFocus();
               }
          } else if (digit === 5) {
               setOTP5(val);
               if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
                    return;
               }
               if (val.length > 0 && otp6Input.current !== null) {
                    otp6Input.current.setFocus();
               }
          } else if (digit === 6) {
               setOTP6(val);
               if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
                    return;
               } else {
                    var otpString = otp1 + otp2 + otp3 + otp4 + otp5 + val;
                    if (customerBlock !== null) {
                         if (customerBlock.otp !== otpString) {
                              setMobileNumberError(true);
                              setErrorMessage('Invalid access code');
                              if (otp1Input.current !== null) {
                                   otp1Input.current.setFocus();
                              }
                         } else {
                              if (customerBlock.customer) {
                                   customerBlock.keepMeLoggedIn = preserveLogin.current;
                                   window.parent.postMessage(customerBlock, "*");
                              } else {
                                   try {
                                        let input = document.getElementById('googlePlaces')!.getElementsByTagName('input')![0] || undefined;
                                        let autocomplete = new google.maps.places.Autocomplete(input!, { types: ['geocode'] });
                                        let country = countryCode.current === "+91" ? "in" : "us";
                                        autocomplete.setComponentRestrictions({ 'country': [country] });
                                        google.maps.event.addListener(autocomplete, 'place_changed', () => {
                                             let place = autocomplete.getPlace();
                                             setAddress(place.formatted_address!);
                                             setAddressSelected(place.formatted_address!);
                                             setHomeLat(place.geometry!.location.lat());
                                             setHomeLng(place.geometry!.location.lng());
                                             place.address_components!.forEach(function (n) {
                                                  n.types.forEach(function (t) {
                                                       if (t === 'locality') {
                                                            setLocality(n.long_name);
                                                       } else if (t === 'administrative_area_level_1') {
                                                            setState(n.long_name);
                                                       } else if (t === 'administrative_area_level_2') {
                                                            setCounty(n.long_name);
                                                       } else if (t === 'postal_code') {
                                                            setZipCode(n.long_name);
                                                       } else if (t === 'street_number') {
                                                            setStreetNumber(n.long_name);
                                                       } else if (t === 'route') {
                                                            setRoute(n.long_name);
                                                       } else if (t === 'country') {
                                                            setCountry(n.short_name);
                                                       }
                                                  });
                                             });
                                        });
                                   } catch { }
                                   setSignupVisibility(true);
                              }
                         }
                    } else {

                    }
               }
          }
     };

     const processKeyUp = (digit: number, key: number) => {
          if (key === 8 || key === 37) {
               if (digit === 2) {
                    if (otp1Input.current !== null) {
                         otp1Input.current.setFocus();
                    }
               } else if (digit === 3) {
                    if (otp2Input.current !== null) {
                         otp2Input.current.setFocus();
                    }
               } else if (digit === 4) {
                    if (otp3Input.current !== null) {
                         otp3Input.current.setFocus();
                    }
               } else if (digit === 5) {
                    if (otp4Input.current !== null) {
                         otp4Input.current.setFocus();
                    }
               } else if (digit === 6) {
                    if (otp5Input.current !== null) {
                         otp5Input.current.setFocus();
                    }
               }
          }
     }

     const validate = async (e: React.FormEvent) => {
          e.preventDefault();
          setFormSubmitted(true);
          let noerrors = true;
          if (!firstName) {
               noerrors = false;
               setFirstNameError(true);
          }

          if (!lastName) {
               noerrors = false;
               setLastNameError(true);
          }

          if (!mobileNumber) {
               noerrors = false;
               setMobileNumberError(true);
          }

          if (!address || !addressSelected || address !== addressSelected) {
               noerrors = false;
               setAddressError(true);
          }

          if (!emailId) {
               noerrors = false;
               setEmailError(true);
          }

          if (noerrors === true) {
               await register();
          }
     };

     const register = async () => {
          if (firstName && lastName && address && mobileNumber) {
               setLoading(true);
               let customer = new Customer();
               customer.address = streetNumber + ' ' + route;
               customer.city = locality;
               customer.country = country;
               customer.countryCode = countryCode.current;
               customer.county = county;
               customer.dob = new Date(dob);
               customer.emailId = emailId;
               customer.firstName = firstName;
               customer.gender = gender;
               customer.homeLocation = address;
               customer.latitude = homeLat;
               customer.lastName = lastName;
               customer.locality = locality;
               customer.longitude = homeLng;
               customer.mobileNumber = mobileNumber;
               customer.pictureURL = '';
               customer.state = state;
               customer.zipCode = zipCode;
               try {
                    var maxLat = 0.0, maxLng = 0.0, minLat = 0.0, minLng = 0.0;
                    const mapElement = document.createElement("div")
                    var map = new google.maps.Map(mapElement, {
                         center: {
                              lat: homeLat,
                              lng: homeLng
                         },
                         zoom: 16
                    });
                    var circ = new google.maps.Circle({
                         center: {
                              lat: homeLat,
                              lng: homeLng
                         },
                         radius: 100000
                    });
                    map.fitBounds(circ.getBounds(), 0);
                    var sw = circ.getBounds().getSouthWest();
                    var ne = circ.getBounds().getNorthEast();
                    maxLat = ne.lat();
                    maxLng = ne.lng();
                    minLat = sw.lat();
                    minLng = sw.lng();

                    var selectedBusiness: string[] = [];
                    selectedBusiness.push(businessId);
                    let customerBlock = await registerCustomer(publicKey, customer, selectedBusiness, maxLat, maxLng, minLat, minLng);
                    customerBlock.keepMeLoggedIn = preserveLogin.current;
                    setLoading(false);
                    window.parent.postMessage(customerBlock, "*");
               }
               catch (e) {
                    setLoading(false);
                    setError({ showError: true, message: (e as Error).message });
               }
          }
     };

     const backToLogin = () => {
          setMobileNumberVal('');
          setOTPVisibility(false);
          setSignupVisibility(false);
     }

     return (
          <div className="main">
               <IonToast
                    isOpen={error.showError}
                    position="top"
                    onDidDismiss={() => setError({ message: "", showError: false })}
                    message={error.message}
                    duration={3000}
               />
               <IonLoading
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={'Processing...Please wait...'}
               />
               <div style={{ alignSelf: "center", textAlign: "center" }}>
                    <div>
                         <img src={logoUrl} height="75" alt={businessName} />
                         <div style={{ color: "black", fontSize: "25px", textAlign: "start", marginTop: "15px" }}>{businessName}</div>
                    </div>
                    <div hidden={signupVisible}>
                         <form noValidate onSubmit={login}>
                              <IonGrid className="ion-no-margin ion-no-padding">
                                   <IonRow>
                                        <IonCol className="ion-no-margin ion-no-padding">
                                             <div>
                                                  <IonList className="transparent">
                                                       <div style={{ color: "gray", fontSize: "14px", textAlign: "start" }}>Enter your mobile number to login or sign-up</div>
                                                       <IonGrid className="ion-no-padding ion-no-margin" style={{ marginTop: "20px" }}>
                                                            <IonRow>
                                                                 <IonCol size="4">
                                                                      <IonItem className="ion-no-padding ion-no-margin">
                                                                           <IonImg src={countryCode.current === "+1" ? "/assets/img/usa.png" : "/assets/img/india.png"} onClick={() => setShowCountryPicker(true)}></IonImg>
                                                                           <IonInput
                                                                                style={{ fontSize: "20px", textAlign: "center" }}
                                                                                value={countryCode.current}
                                                                                onClick={() => setShowCountryPicker(true)}
                                                                                readonly>
                                                                           </IonInput>
                                                                           <IonActionSheet
                                                                                isOpen={showCountryPicker}
                                                                                onDidDismiss={() => setShowCountryPicker(false)}
                                                                                buttons={[{
                                                                                     text: 'India (+91)',
                                                                                     handler: () => {
                                                                                          countryCode.current = "+91";
                                                                                     }
                                                                                }, {
                                                                                     text: 'USA (+1)',
                                                                                     handler: () => {
                                                                                          countryCode.current = "+1";
                                                                                     }
                                                                                }]}
                                                                           >
                                                                           </IonActionSheet>
                                                                      </IonItem>
                                                                 </IonCol>
                                                                 <IonCol size="8">
                                                                      <IonItem>
                                                                           <IonInput name="mobilenumber"
                                                                                style={{ fontSize: "20px" }}
                                                                                type="tel"
                                                                                inputMode="tel"
                                                                                value={mobileNumber}
                                                                                minlength={10}
                                                                                maxlength={10}
                                                                                spellCheck={false}
                                                                                autocapitalize="off"
                                                                                placeholder="mobile number"
                                                                                onIonChange={async e => await processMobile(e, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                            </IonRow>
                                                       </IonGrid>
                                                       <IonGrid style={{ marginTop: "20px", marginLeft: "20px" }} hidden={otpVisible}>
                                                            <IonRow>
                                                                 <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                                                                      <IonItem lines="none" className="ion-no-padding ion-text-center">
                                                                           <IonInput name="otp1"
                                                                                ref={otp1Input}
                                                                                className="otp-digit"
                                                                                type="tel"
                                                                                inputMode="numeric"
                                                                                value={otp1}
                                                                                maxlength={1}
                                                                                onKeyUp={(e) => processKeyUp(1, e.keyCode)}
                                                                                onIonChange={e => processOTP(e, 1, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                                 <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                                                                      <IonItem lines="none" className="ion-no-padding ion-text-center">
                                                                           <IonInput name="otp2"
                                                                                ref={otp2Input}
                                                                                className="otp-digit"
                                                                                type="tel"
                                                                                inputMode="numeric"
                                                                                value={otp2}
                                                                                maxlength={1}
                                                                                onKeyUp={(e) => processKeyUp(2, e.keyCode)}
                                                                                onIonChange={e => processOTP(e, 2, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                                 <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                                                                      <IonItem lines="none" className="ion-no-padding ion-text-center">
                                                                           <IonInput name="otp3"
                                                                                ref={otp3Input}
                                                                                className="otp-digit"
                                                                                type="tel"
                                                                                inputMode="numeric"
                                                                                value={otp3}
                                                                                maxlength={1}
                                                                                onKeyUp={(e) => processKeyUp(3, e.keyCode)}
                                                                                onIonChange={e => processOTP(e, 3, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                                 <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                                                                      <IonItem lines="none" className="ion-no-padding ion-text-center">
                                                                           <IonInput name="otp4"
                                                                                ref={otp4Input}
                                                                                className="otp-digit"
                                                                                type="tel"
                                                                                inputMode="numeric"
                                                                                value={otp4}
                                                                                maxlength={1}
                                                                                onKeyUp={(e) => processKeyUp(4, e.keyCode)}
                                                                                onIonChange={e => processOTP(e, 4, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                                 <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                                                                      <IonItem lines="none" className="ion-no-padding ion-text-center">
                                                                           <IonInput name="otp5"
                                                                                ref={otp5Input}
                                                                                className="otp-digit"
                                                                                type="tel"
                                                                                inputMode="numeric"
                                                                                value={otp5}
                                                                                maxlength={1}
                                                                                onKeyUp={(e) => processKeyUp(5, e.keyCode)}
                                                                                onIonChange={e => processOTP(e, 5, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                                 <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                                                                      <IonItem lines="none" className="ion-no-padding ion-text-center">
                                                                           <IonInput name="otp6"
                                                                                ref={otp6Input}
                                                                                className="otp-digit"
                                                                                type="tel"
                                                                                inputMode="numeric"
                                                                                value={otp6}
                                                                                maxlength={1}
                                                                                onKeyUp={(e) => processKeyUp(6, e.keyCode)}
                                                                                onIonChange={e => processOTP(e, 6, e.detail.value!)}
                                                                                required>
                                                                           </IonInput>
                                                                      </IonItem>
                                                                 </IonCol>
                                                            </IonRow>
                                                       </IonGrid>
                                                       {formSubmitted && mobileNumberError && <IonText color="danger">
                                                            <p className="ion-no-padding ion-no-margin text-xsmall">
                                                                 {errorMessage}
                                                            </p>
                                                       </IonText>}
                                                  </IonList>
                                             </div>
                                        </IonCol>
                                   </IonRow>
                                   <IonRow>
                                        <IonCol>
                                             <div style={{ color: "gray", fontSize: "10px", textAlign: "start" }}>By continuing, you may receive a 6 digit pin via SMS for verification. Message and data rates may apply.</div>
                                        </IonCol>
                                   </IonRow>
                                   <IonRow>
                                        <IonCol className="ion-no-padding ion-no-margin">
                                             <button style={{ color: "white", backgroundColor: "black", height: "50px", width: "100%", marginTop: "10px", fontSize: "20px" }} type="submit">
                                                  Next
                                             </button>
                                        </IonCol>
                                   </IonRow>
                                   <IonRow>
                                        <IonCol style={{ marginTop: "10px", padding: "0", textAlign: "start" }}>
                                             <IonItem lines="none" className="ion-no-padding">
                                                  <IonCheckbox checked={preserveLogin.current} onIonChange={e => preserveLogin.current = e.detail.checked} />
                                                  <IonLabel style={{ color: "gray", fontSize: "13px", paddingLeft: "5px" }}>
                                                       Keep me logged in for a week
                                                  </IonLabel>
                                             </IonItem>
                                        </IonCol>
                                   </IonRow>
                                   {socialLoginEnabled &&
                                        <IonRow>
                                             <IonCol style={{ borderTop: "1px solid gainsboro", marginTop: "20px" }}>
                                                  <div style={{ color: "royalblue" }}>
                                                       Or connect using your social account
                                                  </div>
                                             </IonCol>
                                        </IonRow>}
                                   {socialLoginEnabled &&
                                        <IonRow>
                                             <IonCol style={{ marginTop: "10px" }}>
                                                  <div><img src="assets/img/gmail.png" width="45" /></div>
                                                  <div style={{ color: "gray", fontSize: "12px" }}>Gmail</div>
                                             </IonCol>
                                             <IonCol style={{ marginTop: "15px" }}>
                                                  <div><img src="assets/img/yahoo.png" width="40" /></div>
                                                  <div style={{ color: "gray", fontSize: "12px" }}>Yahoo!</div>
                                             </IonCol>
                                             <IonCol style={{ marginTop: "15px" }}>
                                                  <div><img src="assets/img/microsoft.png" width="40" /></div>
                                                  <div style={{ color: "gray", fontSize: "12px" }}>Outlook/Hotmail</div>
                                             </IonCol>
                                        </IonRow>}
                              </IonGrid>
                         </form>
                    </div>
                    <div hidden={!signupVisible} style={{ marginTop: "0px" }}>
                         <form noValidate>
                              <IonList>
                                   <IonItem className="ion-no-margin ion-no-padding">
                                        <IonLabel position="stacked" color="primary">Mobile Number</IonLabel>
                                        <IonInput name="mobileNumber" type="tel" value={mobileNumber} readonly={mobileNumber !== ''} onIonChange={e => {
                                             setMobileNumberVal(e.detail.value!);
                                        }}>
                                        </IonInput>
                                   </IonItem>

                                   {formSubmitted && mobileNumberError && <IonText color="danger">
                                        <p className="ion-no-padding ion-no-margin text-xsmall" style={{ textAlign: "start" }}>
                                             Mobile number is required
                                        </p>
                                   </IonText>}

                                   <IonGrid className="ion-no-margin ion-no-padding">
                                        <IonRow>
                                             <IonCol style={{ marginRight: "10px" }}>
                                                  <IonItem className="ion-no-margin ion-no-padding">
                                                       <IonLabel position="stacked" color="primary">First Name</IonLabel>
                                                       <IonInput name="firstName" type="text" value={firstName} spellCheck={false} autocapitalize="off" onIonChange={e => {
                                                            setFirstName(e.detail.value!);
                                                            setFirstNameError(false);
                                                       }}
                                                            required>
                                                       </IonInput>
                                                  </IonItem>

                                                  {formSubmitted && firstNameError && <IonText color="danger">
                                                       <p className="ion-no-padding ion-no-margin text-xsmall" style={{ textAlign: "start" }}>
                                                            First name is required
                                                       </p>
                                                  </IonText>}
                                             </IonCol>
                                             <IonCol>
                                                  <IonItem className="ion-no-margin ion-no-padding">
                                                       <IonLabel position="stacked" color="primary">Last Name</IonLabel>
                                                       <IonInput name="lastName" type="text" value={lastName} onIonChange={e => {
                                                            setLastName(e.detail.value!);
                                                            setLastNameError(false);
                                                       }}>
                                                       </IonInput>
                                                  </IonItem>

                                                  {formSubmitted && lastNameError && <IonText color="danger">
                                                       <p className="ion-no-padding ion-no-margin text-xsmall" style={{ textAlign: "start" }}>
                                                            Last name is required
                                                       </p>
                                                  </IonText>}
                                             </IonCol>
                                        </IonRow>
                                   </IonGrid>

                                   <IonItem className="ion-no-margin ion-no-padding">
                                        <IonLabel position="stacked" color="primary">Address</IonLabel>
                                        <IonInput id="googlePlaces" name="address" type="text" value={address} onIonChange={e => {
                                             setAddress(e.detail.value!);
                                             setAddressError(false);
                                        }}>
                                        </IonInput>
                                   </IonItem>

                                   {formSubmitted && addressError && <IonText color="danger">
                                        <p className="ion-no-padding ion-no-margin text-xsmall" style={{ textAlign: "start" }}>
                                             Address is required
                                        </p>
                                   </IonText>}

                                   <IonItem className="ion-no-margin ion-no-padding">
                                        <IonLabel position="stacked" color="primary">Email</IonLabel>
                                        <IonInput name="emailId" type="email" value={emailId} pattern="[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}" onIonChange={e => {
                                             setEmailId(e.detail.value!);
                                             setEmailError(false);
                                        }}>
                                        </IonInput>
                                   </IonItem>

                                   {formSubmitted && emailError && <IonText color="danger">
                                        <p className="ion-no-padding ion-no-margin text-xsmall" style={{ textAlign: "start" }}>
                                             Email is required
                                        </p>
                                   </IonText>}

                                   <IonGrid className="ion-no-margin ion-no-padding">
                                        <IonRow>
                                             <IonCol className="ion-no-margin ion-no-padding">
                                                  <IonItem className="ion-no-margin ion-no-padding" lines='none' style={{ alignSelf: "center" }}>
                                                       <IonRadioGroup value={gender} onIonChange={e => setGender(e.detail.value)}>
                                                            <IonGrid>
                                                                 <IonRow>
                                                                      <IonCol style={{ textAlign: "center" }}>
                                                                           <IonLabel position="stacked" color="primary">Male</IonLabel>
                                                                           <IonRadio value="Male" mode="md" />
                                                                      </IonCol>
                                                                      <IonCol style={{ marginLeft: "20px", textAlign: "center" }}>
                                                                           <IonLabel position="stacked" color="primary">Female</IonLabel>
                                                                           <IonRadio value="Female" mode="md" />
                                                                      </IonCol>
                                                                 </IonRow>
                                                            </IonGrid>
                                                       </IonRadioGroup>
                                                  </IonItem>
                                             </IonCol>
                                             <IonCol size="6" className="m-l-10">
                                                  <IonItem>
                                                       <IonLabel position="stacked" color="primary">Date of birth *</IonLabel>
                                                       {isPlatform('mobile') ? (
                                                            <IonDatetime placeholder="Select Date" value={dob} onIonChange={e => setDob(e.detail.value!)}></IonDatetime>
                                                       ) : (
                                                            <IonInput type="date" placeholder="Select Date" value={dob} onIonChange={e => setDob(e.detail.value!)} />
                                                       )}
                                                  </IonItem>
                                             </IonCol>
                                        </IonRow>
                                   </IonGrid>

                                   <IonGrid style={{ marginTop: "20px" }}>
                                        <IonRow>
                                             <IonCol>
                                                  <IonButton onClick={backToLogin} color="warning" expand="block">Back</IonButton>
                                             </IonCol>
                                             <IonCol>
                                                  <IonButton onClick={validate} color="success" expand="block">SignUp</IonButton>
                                             </IonCol>
                                        </IonRow>
                                   </IonGrid>
                              </IonList>
                         </form>
                    </div>
               </div>
               <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
                    <IonGrid>
                         <IonRow>
                              <IonCol style={{ textAlign: "start" }}>
                                   <a href="https://www.infopoint.com/privacypolicy" style={{ fontSize: "10px" }} target="_blank">Privacy Policy</a>
                              </IonCol>
                              <IonCol style={{ textAlign: "center" }}>
                                   <a href="https://www.infopoint.com" style={{ fontSize: "10px" }} target="_blank">www.infopoint.com</a>
                              </IonCol>
                              <IonCol style={{ textAlign: "end" }}>
                                   <a href="https://www.infopoint.com/websiteterms" style={{ fontSize: "10px" }} target="_blank">Terms &amp; Conditions</a>
                              </IonCol>
                         </IonRow>
                    </IonGrid>
               </div>
          </div>
     );
};

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          mode: getConfig()!.get('mode')
     }),
     mapDispatchToProps: {

     },
     component: Auth
})