import { CustomerActions } from './customer.actions';
import { CustomerState } from './customer.state';

export const customerReducer = (state: CustomerState, action: CustomerActions): CustomerState => {
     switch (action.type) {
          case 'set-customer':
               return { ...state, ...action.data };
          case 'set-customer-data':
               return { ...state, customer: action.customer };
          case 'set-passes-data':
               return { ...state, passes: action.passes };
          case 'set-reservations-data':
               return { ...state, reservations: action.reservations };
          case 'set-visits-data':
               return { ...state, visits: action.visits };
          case 'set-businesses-data':
               return { ...state, businesses: action.businesses };
          case 'set-campaigns-data':
               return { ...state, campaigns: action.campaigns };
          case 'set-beacons-data':
               return { ...state, beacons: action.beacons };
     }
}