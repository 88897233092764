import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText, IonGrid, IonDatetime, IonFooter, IonThumbnail, IonCheckbox, IonToast, IonLoading, IonSearchbar, IonAlert, useIonViewDidEnter } from '@ionic/react';
import './LoginPage.scss';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import { setCustomer, setPasses, setPayVisits } from '../data/customer/customer.actions';
import { selectBusiness, setSearchBusiness, unselectBusiness } from '../data/session/session.actions';
import { registerCustomer, saveCustomer, setCustomerBlockData } from '../data/dataApi';
import * as selectors from '../data/selectors';
import { Plugins, CameraResultType } from '@capacitor/core';
import { alertController } from '@ionic/core';
import { IBusinessEntity } from '../models/Base/BusinessEntity';
import { Customer, ICustomer } from '../models/Base/Customer';

interface OwnProps extends RouteComponentProps {
  manageAccount: boolean;
}

interface StateProps {
  countryCode?: string;
  mobileNumber?: string;
  businesses?: IBusinessEntity[];
  selectedBusinesses: string[];
  customer: ICustomer;
}

interface DispatchProps {
  setCustomer: typeof setCustomer;
  setPasses: typeof setPasses;
  setPayVisits: typeof setPayVisits;
  setSearchBusiness: typeof setSearchBusiness;
  selectBusiness: typeof selectBusiness;
  unselectBusiness: typeof unselectBusiness;
}

interface Error {
  showError: boolean;
  message?: string;
}

interface SignupPageProps extends OwnProps, StateProps, DispatchProps { }

const SignupPage: React.FC<SignupPageProps> = ({ history, businesses, customer, countryCode, manageAccount, mobileNumber, setCustomer, setPasses, setPayVisits, setSearchBusiness, selectedBusinesses: selectedBusiness, selectBusiness, unselectBusiness }) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>({ showError: false });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [address, setAddress] = useState('');
  const [addressSelected, setAddressSelected] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [route, setRoute] = useState('');
  const [locality, setLocality] = useState('');
  const [state, setState] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [homeLat, setHomeLat] = useState(0);
  const [homeLng, setHomeLng] = useState(0);
  const [dob, setDob] = useState('01/01/1970');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showConfirmTakePhoto, setShowConfirmTakePhoto] = useState(false);
  const { Camera } = Plugins;

  useIonViewDidEnter(() => {
    setShowConfirmTakePhoto(false);
    if (!manageAccount && customer) {
      return;
    } else if (manageAccount && customer) {
      setFirstName(customer.firstName!);
      setLastName(customer.lastName!);
      setAddressSelected(customer.homeLocation!);
      setAddress(customer.homeLocation!);
      setStreetNumber(customer.address!);
      setHomeLat(customer.latitude);
      setHomeLng(customer.longitude);
      setLocality(customer.locality!);
      setState(customer.state!);
      setCounty(customer.county!);
      setZipCode(customer.zipCode!);
      setCountry(customer.country!);
      setEmailId(customer.emailId!);
      setDob(new Date(customer.dob).toLocaleDateString());
    }
    all(true);

    try {
      let input = document.getElementById('googlePlaces')!.getElementsByTagName('input')![0] || undefined;
      let autocomplete = new google.maps.places.Autocomplete(input!, { types: ['geocode'] });
      let country = countryCode === "+91" ? "in" : "us";
      autocomplete.setComponentRestrictions({ 'country': [country] });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace();
        setAddress(place.formatted_address!);
        setAddressSelected(place.formatted_address!);
        setHomeLat(place.geometry!.location.lat());
        setHomeLng(place.geometry!.location.lng());
        place.address_components!.forEach(function (n) {
          n.types.forEach(function (t) {
            if (t === 'locality') {
              setLocality(n.long_name);
            } else if (t === 'administrative_area_level_1') {
              setState(n.long_name);
            } else if (t === 'administrative_area_level_2') {
              setCounty(n.long_name);
            } else if (t === 'postal_code') {
              setZipCode(n.long_name);
            } else if (t === 'street_number') {
              setStreetNumber(n.long_name);
            } else if (t === 'route') {
              setRoute(n.long_name);
            } else if (t === 'country') {
              setCountry(n.short_name);
            }
          });
        });
      });
    } catch { }
  });

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 50,
        allowEditing: true,
        resultType: CameraResultType.Base64
      });
      return image.base64String;
    } catch (e) {
      setError({ showError: true, message: (e as Error).message });
      return '';
    }
  }

  const validate = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    let noerrors = true;
    if (!firstName) {
      noerrors = false;
      setFirstNameError(true);
    }

    if (!lastName) {
      noerrors = false;
      setLastNameError(true);
    }

    if (!address || !addressSelected || address !== addressSelected) {
      noerrors = false;
      setAddressError(true);
    }

    if (selectedBusiness.length <= 0) {
      noerrors = false;
      setError({ showError: true, message: 'Atleast 1 business must be selected' });
    }

    if (noerrors && !showConfirmTakePhoto) {
      setShowConfirmTakePhoto(true);
    }
  };

  const register = async (imageData: string) => {
    if (firstName && lastName && address) {
      setLoading(true);
      let customer = new Customer();
      customer.address = streetNumber + ' ' + route;
      customer.city = locality;
      customer.country = country;
      customer.countryCode = countryCode;
      customer.county = county;
      customer.dob = new Date(dob);
      customer.emailId = emailId;
      customer.firstName = firstName;
      customer.gender = "Male";
      customer.homeLocation = address;
      customer.latitude = homeLat;
      customer.lastName = lastName;
      customer.locality = locality;
      customer.longitude = homeLng;
      customer.mobileNumber = mobileNumber;
      customer.pictureURL = imageData;
      customer.state = state;
      customer.zipCode = zipCode;
      try {
        var maxLat = 0.0, maxLng = 0.0, minLat = 0.0, minLng = 0.0;
        const mapElement = document.createElement("div")
        var map = new google.maps.Map(mapElement, {
          center: {
            lat: homeLat,
            lng: homeLng
          },
          zoom: 16
        });
        var circ = new google.maps.Circle({
          center: {
            lat: homeLat,
            lng: homeLng
          },
          radius: 100000
        });
        map.fitBounds(circ.getBounds(), 0);
        var sw = circ.getBounds().getSouthWest();
        var ne = circ.getBounds().getNorthEast();
        maxLat = ne.lat();
        maxLng = ne.lng();
        minLat = sw.lat();
        minLng = sw.lng();
        
        let customerBlock = manageAccount ? await saveCustomer(customer, selectedBusiness, maxLat, maxLng, minLat, minLng) : await registerCustomer(null, customer, selectedBusiness, maxLat, maxLng, minLat, minLng);
        await setCustomerBlockData(customerBlock);
        await setCustomer(customerBlock.customer);
        await setPasses(customerBlock.passes);
        await setPayVisits(customerBlock.visits);
        setLoading(false);
        if (!manageAccount) {
          history.replace('/wallet', { direction: 'forward' });
        } else {
          await alertController.create(
            {
                 message: "Personal info update successful.",
                 buttons: [
                      {
                           text: 'OK',
                           role: 'cancel'
                      }
                 ]
            }).then(alert => alert.present());
        }
        setShowConfirmTakePhoto(false);
      }
      catch (e) {
        setShowConfirmTakePhoto(false);
        setLoading(false);
        setError({ showError: true, message: (e as Error).message });
      }
    }
  };

  const all = async (selected: boolean) => {
    setSelectAll(selected);
    businesses!.forEach(function (business) {
      unselectBusiness(business.id!);
      if (selected) {
        selectBusiness(business.id!);
      }
    });
  };

  return (
    <IonPage id="signup-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{manageAccount ? "Personal Info" : "Signup"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={error.showError}
          position="top"
          onDidDismiss={() => setError({ message: "", showError: false })}
          message={error.message}
          duration={3000}
        />
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Processing...Please wait...'}
        />
        <IonAlert
          isOpen={showConfirmTakePhoto}
          cssClass='my-custom-class'
          header={'Profile Picture'}
          message={'Click yes to take a profile picture'}
          buttons={[{
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary',
            handler: async () => {
              await register('');
            }
          },
          {
            text: 'YES',
            handler: async () => {
              let imageData = await takePicture();
              await register(imageData!);
            }
          }]}
        />
        <form noValidate>
          <IonList>
            <IonGrid className="ion-no-margin ion-no-padding">
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="stacked" color="primary">First Name</IonLabel>
                    <IonInput name="firstName" type="text" value={firstName} spellCheck={false} autocapitalize="off" onIonChange={e => {
                      setFirstName(e.detail.value!);
                      setFirstNameError(false);
                    }}
                      required>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && firstNameError && <IonText color="danger">
                    <p className="ion-no-margin ion-padding-start text-xsmall">
                      First name is required
                    </p>
                  </IonText>}
                </IonCol>
                <IonCol className="m-l-10">
                  <IonItem>
                    <IonLabel position="stacked" color="primary">Last Name</IonLabel>
                    <IonInput name="lastName" type="text" value={lastName} onIonChange={e => {
                      setLastName(e.detail.value!);
                      setLastNameError(false);
                    }}>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && lastNameError && <IonText color="danger">
                    <p className="ion-no-margin ion-padding-start text-xsmall">
                      Last name is required
                    </p>
                  </IonText>}
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonItem>
              <IonLabel position="stacked" color="primary">Address</IonLabel>
              <IonInput id="googlePlaces" name="address" type="text" value={address} onIonChange={e => {
                setAddress(e.detail.value!);
                setAddressError(false);
              }}>
              </IonInput>
            </IonItem>

            {formSubmitted && addressError && <IonText color="danger">
              <p className="ion-no-margin ion-padding-start text-xsmall">
                Address is required
              </p>
            </IonText>}

            <IonGrid className="ion-no-margin ion-no-padding">
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="stacked" color="primary">Email</IonLabel>
                    <IonInput name="emailId" type="email" value={emailId} onIonChange={e => {
                      setEmailId(e.detail.value!);
                    }}>
                    </IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="4" className="m-l-10">
                  <IonItem>
                    <IonLabel position="stacked" color="primary">Date of birth</IonLabel>
                    <IonDatetime placeholder="Select Date" value={dob} onIonChange={e => setDob(e.detail.value!)}></IonDatetime>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>

          </IonList>
          <IonGrid>
            <IonRow className="ion-margin-top">
              <IonCol>
                <IonItem lines="none">
                  <IonLabel slot="start" className="title">Select Business</IonLabel>
                  <IonLabel slot="end" color="primary" className="category">Select All</IonLabel>
                  <IonCheckbox className="ion-no-margin" slot="end" checked={selectAll} onIonChange={e => all(e.detail.checked)} />
                </IonItem>
                <IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchBusiness(e.detail.value)}></IonSearchbar>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonList className="scrollable-list">
                  {businesses && businesses.map((business) => (
                    <IonItem>
                      <IonThumbnail slot="start">
                        <img src={business.logoUrl} object-fit="cover" alt={business.companyName} />
                      </IonThumbnail>
                      <IonGrid className="business">
                        <IonRow>
                          <IonLabel className="company">
                            {business.companyName}
                          </IonLabel>
                        </IonRow>
                        <IonRow>
                          <IonLabel color="danger" className="category">
                            {business.category} | {(business.branchLocations!.map(e => e.city).filter((city, index, arr) => arr.indexOf(city) === index)).join(", ")}
                          </IonLabel>
                        </IonRow>
                      </IonGrid>
                      <IonCheckbox className="ion-no-margin ion-no-padding" checked={selectedBusiness && selectedBusiness.indexOf(business.id!) >= 0} onIonChange={e => e.detail.checked ? selectBusiness(business.id!) : unselectBusiness(business.id!)} />
                    </IonItem>
                  ))}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>

      <IonFooter>
        <IonRow>
          <IonCol>
            <IonButton onClick={validate} expand="block">{manageAccount ? "Save" : "Register"}</IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>

    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    businesses: selectors.getSearchedBusinesses(state),
    countryCode: state.user.countryCode,
    customer: state.customer.customer!,
    mobileNumber: state.user.mobileNumber,
    selectedBusinesses: state.session.selectedBusinesses
  }),
  mapDispatchToProps: {
    setCustomer,
    setPasses,
    setPayVisits,
    setSearchBusiness,
    selectBusiness,
    unselectBusiness
  },
  component: SignupPage
})