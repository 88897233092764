import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { loadCustomerData } from './data/customer/customer.actions';
import { setIsLoggedIn, loadUserData } from './data/user/user.actions';
import { loadSessionData, setSession, setWalletPay } from './data/session/session.actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setCustomer } from './data/customer/customer.actions';
import SignupPage from './pages/SignupPage';
import RedirectToLogin from './components/RedirectToLogin';
import WalletPage from './pages/WalletPage';
import PassDetailsPage from './pages/PassDetailsPage';
import VisitDetailsPage from './pages/VisitDetailsPage';
import InvoicePage from './pages/InvoicePage';
import ReceiptPage from './pages/ReceiptPage';
import PaymentsPage from './pages/PaymentsPage';
import CardForm from './components/CardForm';
import { IBusinessEntity } from './models/Base/BusinessEntity';
import { ICustomer } from './models/Base/Customer';
import { ICustomerPass } from './models/Base/CustomerPass';
import { ICustomerVisit } from './models/Base/CustomerVisit';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import Auth from './components/Auth';
import Cart from './components/Cart';
import Pay from './components/Pay';
import ReservationSlots from './components/ReservationSlots';
import Orders from './components/Orders';

//Switch to this test key when debugging in local
// const stripePromise = loadStripe('pk_test_51IUFrvKnOrf8gXPIg0neLVNK3PVAOhJ547jNN0swbbt3KMMkiTuiqBUfHFuVgAE0LgZwrHOe0inYTCYICMpzvLak00KfMtjR9n', {});
const stripePromise = loadStripe('pk_live_51IUFrvKnOrf8gXPIs1fzEs9TbpYmwC39NRNGziitTL5Liy1eOR3HgUY04DJ5rh6D0h3zXGXZEl19DRgkSWfYAKsn006VeGePUJ', {});

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <Elements stripe={stripePromise}>
        <IonicAppConnected />
      </Elements>
    </AppContextProvider>
  );
};

interface StateProps {
  authenticated: boolean;
  darkMode: boolean;
  customer?: ICustomer;
  business?: IBusinessEntity;
  pass?: ICustomerPass;
  payVisits?: ICustomerVisit[];
  selectedVisit?: ICustomerVisit;
  selectedVisits: ICustomerVisit[];
  walletPay?: string;
}

interface DispatchProps {
  loadCustomerData: typeof loadCustomerData;
  loadUserData: typeof loadUserData;
  loadSessionData: typeof loadSessionData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setCustomer: typeof setCustomer;
  setSession: typeof setSession;
  setWalletPay: typeof setWalletPay;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ authenticated, business, customer, pass, selectedVisit, selectedVisits, darkMode, setIsLoggedIn, setCustomer, setWalletPay, loadCustomerData, loadSessionData, loadUserData, walletPay }) => {

  useEffect(() => {
    loadUserData();
    loadCustomerData();
    loadSessionData();
  }, []);

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/home" render={props => {
              return <LandingPage {...props} />;
            }} />
            <Route path="/auth" render={props => {
              return <Auth {...props} />;
            }} />
            <Route path="/cart" render={props => {
              return <Cart {...props} />;
            }} />
            <Route path="/pay" render={props => {
              return <Pay isComponent={true} {...props} />;
            }} />
            <Route path="/reservationslots" render={props => {
              return <ReservationSlots {...props} />;
            }} />
            <Route path="/orders" render={props => {
              return <Orders {...props} />;
            }} />
            <Route path="/auth/signup" render={props => {
              return <SignupPage manageAccount={false} {...props} />;
            }} />
            <Route path="/res" render={props => {
              return <LandingPage reservationPayment={true} {...props} />;
            }} />
            <Route path="/payments" exact render={props => {
              return authenticated && customer ? <PaymentsPage {...props} /> : <Redirect to={{ pathname: '/login' }} />;
            }} />
            <Route path="/wallet" exact render={props => {
              return authenticated && customer ? <WalletPage {...props} /> : <Redirect to={{ pathname: '/login' }} />;
            }} />
            <Route path="/wallet/pay" exact render={props => {
              if ((!authenticated || !customer) && (!walletPay && props.location.search.indexOf("?data=") >= 0)) {
                setWalletPay(props.location.search.replace('?data=', ''));
              }
              return authenticated && customer ? <WalletPage {...props} /> : <Redirect to={{ pathname: '/login' }} />;
            }} />
            <Route path="/wallet/passdetails" exact render={props => {
              return authenticated && customer && business && pass ? <PassDetailsPage {...props} /> : authenticated && customer ? <Redirect to={{ pathname: '/wallet' }} /> : <Redirect to={{ pathname: '/login' }} />;
            }} />
            <Route path="/login" exact render=
              {
                props => {
                  return authenticated && customer ? <Redirect to={{ pathname: '/wallet' }} /> : <LoginPage {...props} />;
                }
              } />
            <Route path="/login/checkout" exact render=
              {
                props => {
                  return customer && business && selectedVisits && selectedVisits.length > 0
                    ? <CardForm linkCard={false} payVisits={false} {...props} />
                    : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/signup" exact render=
              {
                props => {
                  return authenticated && !customer ? <SignupPage manageAccount={false} {...props} /> : authenticated && customer ? <Redirect to={{ pathname: '/wallet' }} /> : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/account" exact render=
              {
                props => {
                  return authenticated && customer ? <SignupPage manageAccount={true} {...props} /> : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/wallet/checkout" exact render=
              {
                props => {
                  return authenticated && customer && business && selectedVisits && selectedVisits.length > 0
                    ? <CardForm linkCard={false} payVisits={true} {...props} />
                    : authenticated && customer
                      ? <Redirect to={{ pathname: '/wallet' }} />
                      : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/wallet/passdetails/checkout" exact render=
              {
                props => {
                  return authenticated && customer && business && selectedVisits && selectedVisits.length > 0
                    ? <CardForm linkCard={false} payVisits={false} {...props} />
                    : authenticated && customer && business && pass
                      ? <Redirect to={{ pathname: '/wallet/passdetails' }} />
                      : authenticated && customer
                        ? <Redirect to={{ pathname: '/wallet' }} />
                        : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/payments/linkcard" exact render=
              {
                props => {
                  return authenticated && customer
                    ? <CardForm linkCard={true} payVisits={false} {...props} />
                    : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/wallet/passdetails/visitdetails" exact render=
              {
                props => {
                  return authenticated && customer && business && pass && selectedVisit
                    ? <VisitDetailsPage {...props} />
                    : authenticated && customer && business && pass
                      ? <Redirect to={{ pathname: '/wallet/passdetails' }} />
                      : authenticated && customer
                        ? <Redirect to={{ pathname: '/wallet' }} />
                        : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/wallet/passdetails/invoice" exact render=
              {
                props => {
                  return authenticated && customer && business && pass && selectedVisit
                    ? <InvoicePage {...props} />
                    : authenticated && customer && business && pass
                      ? <Redirect to={{ pathname: '/wallet/passdetails' }} />
                      : authenticated && customer
                        ? <Redirect to={{ pathname: '/wallet' }} />
                        : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/wallet/passdetails/receipt" exact render=
              {
                props => {
                  return authenticated && customer && business && pass && selectedVisit
                    ? <ReceiptPage {...props} />
                    : authenticated && customer && business && pass
                      ? <Redirect to={{ pathname: '/wallet/passdetails' }} />
                      : authenticated && customer
                        ? <Redirect to={{ pathname: '/wallet' }} />
                        : <Redirect to={{ pathname: '/login' }} />;
                }
              } />
            <Route path="/logout" exact render={() => {
              return <RedirectToLogin
                setIsLoggedIn={setIsLoggedIn}
                setCustomer={setCustomer}
                setSession={setSession}
              />;
            }} />
            <Route path="/" exact render={props => {
              return authenticated && customer ? <Redirect to={{ pathname: '/wallet' }} /> : <LandingPage {...props} />;
            }} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    authenticated: state.user.isLoggedin,
    customer: state.customer.customer,
    business: state.session.selectedBusiness,
    pass: state.session.selectedPass,
    payVisits: state.customer.visits,
    selectedVisit: state.session.selectedVisit,
    selectedVisits: state.session.selectedVisits,
    walletPay: state.session.walletPay
  }),
  mapDispatchToProps:
  {
    loadCustomerData,
    loadSessionData,
    loadUserData,
    setIsLoggedIn,
    setCustomer,
    setSession,
    setWalletPay
  },
  component: IonicApp
});