import React, { useState } from 'react';
import { IonContent, IonPage, IonFooter, getConfig, useIonViewWillEnter, IonLoading, IonToast, IonModal } from '@ionic/react';
import './LandingPage.scss';
import { connect } from '../data/connect';
import { RouteComponentProps, useLocation } from 'react-router';
import { setCustomer } from '../data/customer/customer.actions';
import { getReservation } from '../data/dataApi';
import { setSelectedBranch, setSelectedBusiness, setSelectedPass, setSelectedVisits, setBusinessId, setBranchId, setViewPay } from '../data/session/session.actions';
import { ICustomerVisit } from '../models/Base/CustomerVisit';
import { match } from 'react-router-dom';
import Pay from '../components/Pay';

interface OwnProps extends RouteComponentProps 
{
  reservationPayment?: boolean | false;
}

interface Error {
  showError: boolean;
  message?: string;
}

interface StateProps {
  mode: 'ios' | 'md',
  viewPay: boolean
}

interface DispatchProps {
  setBranchId: typeof setBranchId;
  setBusinessId: typeof setBusinessId;
  setCustomer: typeof setCustomer;
  setViewPay: typeof setViewPay;
  setSelectedBusiness: typeof setSelectedBusiness;
  setSelectedBranch: typeof setSelectedBranch;
  setSelectedPass: typeof setSelectedPass;
  setSelectedVisits: typeof setSelectedVisits;
}

interface LandingPageProps extends OwnProps, StateProps, DispatchProps { }

const LandingPage: React.FC<LandingPageProps> = ({ history, location, match, mode, viewPay, reservationPayment, setBranchId, setBusinessId, setCustomer, setViewPay, setSelectedBranch, setSelectedBusiness, setSelectedPass, setSelectedVisits }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>({ showError: false });
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useIonViewWillEnter(async () => {
    const code = query.get("code");
    if (reservationPayment && code) {
      try {
        setLoading(true);
        var paymentViewModel = await getReservation(code);
        if (paymentViewModel &&
          paymentViewModel.branch &&
          paymentViewModel.business &&
          paymentViewModel.customer &&
          paymentViewModel.pass &&
          paymentViewModel.visit) {
          setSelectedBranch(paymentViewModel.branch);
          setSelectedBusiness(paymentViewModel.business);
          setCustomer(paymentViewModel.customer);
          setSelectedPass(paymentViewModel.pass);
          var visits: ICustomerVisit[] = [];
          visits.push(paymentViewModel.visit);
          setSelectedVisits(visits);
          setBusinessId(paymentViewModel.business.id!);
          setBranchId(paymentViewModel.branch.branchId!);
          setViewPay(true);
        }
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
        setError({ showError: true, message: (e as Error).message });
      }
    } 
  });

  const login = () => {
    history.push('/login', { direction: 'forward' });
  }
  
  return (
    <IonPage id="landing-page" style={{ backgroundColor: "#8bbb29", height:"100%", width: "100%", overflow: "hidden" }}>
      <IonContent>
      <IonToast
          isOpen={error.showError}
          position="top"
          onDidDismiss={() => setError({ message: "", showError: false })}
          message={error.message}
          duration={3000}
        />
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Processing...Please wait...'}
        />
        <div className="main">
          <div style={{ alignSelf: "center", width: "20%" }}>
            <img  src="assets/icon/icon.png" alt="Infopass logo" />
          </div>
          <div style={{ position: "fixed", bottom: "15%", left: "0", marginLeft: "20px" }}>
            <div style={{ fontSize: "40px"}}>Infopass</div>
            <div style={{ fontSize: "16px", color: "gray", marginTop: "10px" }}>Build loyalty with your local business and get exclusive offers and discounts</div>
          </div>
        </div>
      </IonContent>
      <IonFooter className="ion-text-center ion-padding" onClick={() => login()}>
        <div style={{ color:"white", fontSize: "20px"}}>Get Started<div className="fa fa-arrow-circle-right" style={{ paddingLeft: "10px"}}></div></div>
      </IonFooter>
      <IonModal isOpen={viewPay} showBackdrop={true}>
        <Pay history={history} location={location} match={match} reservationPayment={reservationPayment} />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode'),
    viewPay: state.session.viewPay,
  }),
  mapDispatchToProps: {
    setBranchId,
    setBusinessId,
    setCustomer,
    setViewPay,
    setSelectedBranch,
    setSelectedBusiness,
    setSelectedPass,
    setSelectedVisits
  },
  component: LandingPage
})