import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonMenuButton, IonFab, IonFabButton, IonIcon, IonItemSliding, IonItemOptions, IonItemOption, IonLoading, IonToast } from '@ionic/react';
import { connect } from '../data/connect';
import { add } from 'ionicons/icons';
import * as helpers from '../util/helpers';
import { RouteComponentProps } from 'react-router';
import { deletePaymentMethod } from '../data/dataApi';
import { setCustomer } from '../data/customer/customer.actions';
import { alertController } from '@ionic/core';
import { ICustomer, PaymentMethod } from '../models/Base/Customer';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
     customer?: ICustomer;
};

interface DispatchProps {
     setCustomer: typeof setCustomer
};

interface Error {
     showError: boolean;
     message?: string;
}

interface PaymentPageProps extends OwnProps, StateProps, DispatchProps { };

const PaymentsPage: React.FC<PaymentPageProps> = ({ history, customer, setCustomer }) => {

     const [loading, setLoading] = useState<boolean>(false);
     const [error, setError] = useState<Error>({ showError: false });

     const routeToStripe = () => {
          history.push('/payments/linkcard', { direction: 'forward' });
     }

     const deletePayment = async (paymentMethod: PaymentMethod) => {
          await alertController.create(
               {
                    message: "Are you sure you want to delete this payment method?",
                    buttons: [
                         {
                              text: 'No',
                              role: 'cancel'
                         },
                         {
                              text: 'Yes',
                              handler: async () => {
                                   try {
                                        setLoading(true);
                                        const cust = await deletePaymentMethod(customer!.id!, paymentMethod);
                                        setCustomer(cust);
                                        setLoading(false);
                                   } catch (e) {
                                        setLoading(false);
                                        setError({ showError: true, message: (e as Error).message });
                                   }
                              }
                         }
                    ]
               }).then(alert => alert.present());
     }

     return (
          <IonPage id="visit-details">
               <IonHeader>
                    <IonToolbar>
                         <IonButtons slot="start">
                              <IonMenuButton />
                         </IonButtons>
                         <IonTitle>Payments</IonTitle>
                    </IonToolbar>
               </IonHeader>
               <IonContent>
                    <IonToast
                         isOpen={error.showError}
                         position="top"
                         onDidDismiss={() => setError({ message: "", showError: false })}
                         message={error.message}
                         duration={3000}
                    />
                    <IonLoading
                         isOpen={loading}
                         onDidDismiss={() => setLoading(false)}
                         message={'Processing...Please wait...'}
                    />
                    <IonFab vertical="bottom" horizontal="end" slot="fixed">
                         <IonFabButton onClick={() => routeToStripe()}>
                              <IonIcon icon={add} />
                         </IonFabButton>
                    </IonFab>
                    <IonList>
                         {customer && customer.paymentMethods!.map((paymentMethod) => (
                              <IonItemSliding key={paymentMethod.methodId}>
                                   <IonItem>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol>
                                                       <div style={{ fontWeight: "bold" }}>{paymentMethod.methodName}</div>
                                                       <div>{paymentMethod.network} {paymentMethod.funding} {paymentMethod.type.toLowerCase()} expires {helpers.pad(paymentMethod.expMonth, 2)} / {paymentMethod.expYear}</div>
                                                  </IonCol>
                                                  <IonCol size="2" className="ion-text-right green">
                                                       <IonLabel style={{ fontWeight: "bold" }}>{paymentMethod.last4}</IonLabel>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>
                                   <IonItemOptions side="end" >
                                        <IonItemOption>
                                             <div onClick={() => deletePayment(paymentMethod)}>Delete</div>
                                        </IonItemOption>
                                   </IonItemOptions>
                              </IonItemSliding>
                         ))}
                    </IonList>
               </IonContent>
          </IonPage>
     );
};

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          customer: state.customer.customer
     }),
     mapDispatchToProps: {
          setCustomer
     },
     component: PaymentsPage
});