import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { RouteComponentProps } from "react-router";
import { setSelectedPass, setSelectedVisits, setVisits } from "../data/session/session.actions";
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonCard, IonInput, IonItem, IonGrid, IonRow, IonCol, useIonViewDidEnter, IonLoading, IonToast, IonText } from "@ionic/react";
import { connect } from "../data/connect";
import { addPaymentMethod, processPayment, setupPaymentIntent } from "../data/dataApi";
import { setCustomer, setPayVisits } from "../data/customer/customer.actions";
import * as helpers from '../util/helpers';
import { IBusinessEntity } from "../models/Base/BusinessEntity";
import { ICustomer, PaymentMethod } from "../models/Base/Customer";
import { ICustomerVisit } from "../models/Base/CustomerVisit";
import { PaymentMethodTypes } from "../models/Base/Enums";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

interface OwnProps extends RouteComponentProps 
{ 
  linkCard: boolean,
  payVisits: boolean
};

interface StateProps {
  autoPay: boolean,
  business?: IBusinessEntity,
  countryCode?: string;
  customer?: ICustomer,
  paidWithPoints: boolean,
  payAmount: number,
  pointsToRedeem: number,
  selectedVisits?: ICustomerVisit[],
  tipAmount: number,
  visits?: ICustomerVisit[]
};

interface DispatchProps {
  setCustomer: typeof setCustomer,
  setPayVisits: typeof setPayVisits,
  setSelectedPass: typeof setSelectedPass,
  setSelectedVisits: typeof setSelectedVisits,
  setVisits: typeof setVisits
};

interface Error {
  showError: boolean;
  message?: string;
}

interface CardFormProps extends OwnProps, StateProps, DispatchProps { };

const CardForm: React.FC<CardFormProps> = ({ history, autoPay, payVisits, business, countryCode, customer, linkCard, paidWithPoints, payAmount, pointsToRedeem, tipAmount, selectedVisits, setCustomer, setPayVisits, setVisits, setSelectedPass, setSelectedVisits }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>({ showError: false });
  const [name, setName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [address, setAddress] = useState('');
  const [addressSelected, setAddressSelected] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [route, setRoute] = useState('');
  const [locality, setLocality] = useState('');
  const [state, setState] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [homeLat, setHomeLat] = useState(0);
  const [homeLng, setHomeLng] = useState(0);
  const [cardName, setCardName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cardError, setCardError] = useState(true);
  const [cardNameError, setCardNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => { 
      isMounted.current = true;
  }, [stripe, elements, options]);

  useIonViewDidEnter(() => {
    try {
      setEmailId(customer!.emailId!);
      let input = document.getElementById('googlePlaces')!.getElementsByTagName('input')![0] || undefined;
      let autocomplete = new google.maps.places.Autocomplete(input!, { types: ['geocode'] });
      let country = countryCode === "+91" ? "in" : "us";
      autocomplete.setComponentRestrictions({ 'country': [country] });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace();
        setAddress(place.formatted_address!);
        setAddressSelected(place.formatted_address!);
        setHomeLat(place.geometry!.location.lat());
        setHomeLng(place.geometry!.location.lng());
        place.address_components!.forEach(function (n) {
          n.types.forEach(function (t) {
            if (t === 'locality') {
              setLocality(n.long_name);
            } else if (t === 'administrative_area_level_1') {
              setState(n.long_name);
            } else if (t === 'administrative_area_level_2') {
              setCounty(n.long_name);
            } else if (t === 'postal_code') {
              setZipCode(n.long_name);
            } else if (t === 'street_number') {
              setStreetNumber(n.long_name);
            } else if (t === 'route') {
              setRoute(n.long_name);
            } else if (t === 'country') {
              setCountry(n.short_name);
            }
          });
        });
      });
    } catch { }
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormSubmitted(true);
    if (!stripe || !elements) {
      return;
    }

    var noerrors = true;
    if (!address || !addressSelected || address !== addressSelected) {
      noerrors = false;
      setAddressError(true);
    }

    if (!name) {
      noerrors = false;
      setNameError(true);
    }

    if (!emailId) {
      noerrors = false;
      setEmailError(true);
    }

    if (linkCard && !cardName) {
      noerrors = false;
      setCardNameError(true);
    }

    if (cardError) {
      noerrors = false;
    }

    if (noerrors && customer) {
      try {
        setLoading(true);
        const intentSecret = await setupPaymentIntent(customer.country!, customer.paymentAccountId!);
        if (isMounted && intentSecret) {
          const payload = await stripe.confirmCardSetup(intentSecret, {
            payment_method: {
              card: elements.getElement(CardElement)!
            }
          });

          const paymentMethod = new PaymentMethod();
          paymentMethod.name = name;
          paymentMethod.emailId = emailId;
          paymentMethod.address = address;
          paymentMethod.addressLine1 = streetNumber + " " + route;
          paymentMethod.city = locality;
          paymentMethod.locality = locality;
          paymentMethod.latitude = homeLat;
          paymentMethod.longitude = homeLng;
          paymentMethod.country = country;
          paymentMethod.county = county;
          paymentMethod.state = state;
          paymentMethod.zipCode = zipCode;
          paymentMethod.intentId = payload.setupIntent!.id;
          paymentMethod.methodId = payload.setupIntent!.payment_method!;
          paymentMethod.methodName = cardName;
          if (linkCard) {
            const cust = await addPaymentMethod(customer.id!, paymentMethod, false, false, autoPay);
            setCustomer(cust);
            setLoading(false);
            history.push('/payments', { direction: 'back' });
          } else if (selectedVisits && business) {
            const chargeResponse = await processPayment(
              payAmount,
              paidWithPoints,
              tipAmount,
              pointsToRedeem,
              business.id!,
              '',
              business.companyName!,
              business.currency!,
              customer.id!,
              emailId,
              customer.paymentAccountId!,
              paymentMethod,
              PaymentMethodTypes.Online,
              '',
              selectedVisits.map(e => e.id!),
              null);
            if (chargeResponse) {
                setVisits(chargeResponse.visits!);
                setSelectedPass(chargeResponse.pass!);
            }
            setLoading(false);
            setSelectedVisits([]);
            if (payVisits) {
              setPayVisits([]);
              history.push('/wallet', { direction: 'back' });
            } else {
              history.push('/wallet/passdetails', { direction: 'back' });
            }
          }
        }
      } catch (e) {
        setLoading(false);
        setError({ showError: true, message: (e as Error).message });
      }
    }
  };

  return (
    <IonPage id="card-form">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{linkCard ? "Payment Setup" : business!.companyName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={error.showError}
          position="top"
          onDidDismiss={() => setError({ message: "", showError: false })}
          message={error.message}
          duration={3000}
        />
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Processing...Please wait...'}
        />
        <IonCard className="ion-padding">
          <form onSubmit={handleSubmit} className="stripe-element">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <label className="group-title">
                    Account details
                    <IonItem className="group-item">
                      <IonInput placeholder="Name on card" clear-input onIonChange={e => {
                        setName(e.detail.value!);
                        setNameError(false);
                      }} />
                    </IonItem>
                    {formSubmitted && nameError && <IonText color="danger">
                      <p className="ion-no-margin ion-padding-start text-xsmall">
                        Name is required
                    </p>
                    </IonText>}
                    <IonItem className="group-item">
                      <IonInput id="googlePlaces" name="address" placeholder="Address" type="text" value={address} onIonChange={e => {
                        setAddress(e.detail.value!);
                        setAddressError(false);
                      }}>
                      </IonInput>
                    </IonItem>
                    {formSubmitted && addressError && <IonText color="danger">
                      <p className="ion-no-margin ion-padding-start text-xsmall">
                        Address is required
                    </p>
                    </IonText>}
                    <IonItem className="group-item">
                      <IonInput placeholder="Email id" clear-input onIonChange={e => { setEmailId(e.detail.value!); }} value={emailId} />
                    </IonItem>
                    {formSubmitted && emailError && <IonText color="danger">
                      <p className="ion-no-margin ion-padding-start text-xsmall">
                        Email is required
                    </p>
                    </IonText>}
                    <IonItem className="group-item">
                      <IonInput placeholder="Card friendly name" clear-input onIonChange={e => { setCardName(e.detail.value!); setCardNameError(false); }} />
                    </IonItem>
                    {formSubmitted && cardNameError && <IonText color="danger">
                      <p className="ion-no-margin ion-padding-start text-xsmall">
                        Card name is required
                    </p>
                    </IonText>}
                  </label>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-padding-top">
                  <label className="group-title">
                    Payment details
                    <CardElement
                      options={options}
                      onReady={() => {
                        console.log("CardElement [ready]");
                      }}
                      onChange={event => {
                        setCardError(false);
                      }}
                      onBlur={() => {
                        console.log("CardElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardElement [focus]");
                      }}
                    />
                    {formSubmitted && cardError && <IonText color="danger">
                      <p className="ion-no-margin ion-padding-start text-xsmall">
                        Card number required
                    </p>
                    </IonText>}
                  </label>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <button type="submit" disabled={!stripe}>{linkCard ? "Link card to your account" : "Pay"} {!linkCard && helpers.toCurrency(customer!.country!, (payAmount / 100))}</button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    autoPay: state.user.autoPay,
    business: state.session.selectedBusiness,
    countryCode: state.user.countryCode,
    customer: state.customer.customer,
    paidWithPoints: state.session.paidWithPoints,
    payAmount: state.session.payAmount,
    pointsToRedeem: state.session.pointsToRedeem,
    selectedVisits: state.session.selectedVisits,
    tipAmount: state.session.tipAmount
  }),
  mapDispatchToProps: {
    setCustomer,
    setPayVisits,
    setSelectedPass,
    setSelectedVisits,
    setVisits
  },
  component: CardForm
});