import { ActionType } from '../../util/types';
import { getCustomerData } from '../dataApi';
import { CustomerState } from './customer.state';
import { setLoading } from '../session/session.actions';
import { IBusinessEntity, IBeaconDevice } from '../../models/Base/BusinessEntity';
import { ICustomerPass } from '../../models/Base/CustomerPass';
import { IReservation } from '../../models/Base/Reservation';
import { ICampaign } from '../../models/Base/Campaign';
import { ICustomer } from '../../models/Base/Customer';
import { ICustomerVisit } from '../../models/Base/CustomerVisit';

export const loadCustomerData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getCustomerData();
  if (data != null) {
    dispatch(setData(data));
  }
  dispatch(setLoading(false));
}

export const setData = (data: Partial<CustomerState>) => ({
  type: 'set-customer',
  data
} as const);

export const setCustomer = (customer?: ICustomer) => ({
  type: 'set-customer-data',
  customer
} as const);

export const setPasses = (passes?: ICustomerPass[]) => ({
  type: 'set-passes-data',
  passes
} as const);

export const setReservations = (reservations?: IReservation[]) => ({
  type: 'set-reservations-data',
  reservations
} as const);

export const setPayVisits = (visits?: ICustomerVisit[]) => ({
  type: 'set-visits-data',
  visits
} as const);

export const setBusinesses = (businesses?: IBusinessEntity[]) => ({
  type: 'set-businesses-data',
  businesses
} as const);

export const setCampaigns = (campaigns?: { [key: string]: ICampaign[]; }) => ({
  type: 'set-campaigns-data',
  campaigns
} as const);

export const setBeacons = (beacons?: { [key: string]: IBeaconDevice[]; }) => ({
  type: 'set-beacons-data',
  beacons
} as const);

export type CustomerActions =
  | ActionType<typeof setData>
  | ActionType<typeof setCustomer>
  | ActionType<typeof setPasses>
  | ActionType<typeof setReservations>
  | ActionType<typeof setPayVisits>
  | ActionType<typeof setBusinesses>
  | ActionType<typeof setCampaigns>
  | ActionType<typeof setBeacons>