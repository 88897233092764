import React, { useRef, useEffect, useState, useMemo } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonToast, IonLoading, getConfig, IonList, IonLabel, IonRadioGroup, IonRadio, IonText, IonCard, IonButton } from '@ionic/react'
import { connect } from '../data/connect';
import { setLoading, setPaidWithPoints, setPayAmount, setPointsToRedeem, setSelectedBranch, setSelectedBusiness, setSelectedPass, setSelectedVisits, setTipAmount, setViewPay, setVisits } from '../data/session/session.actions';
import { addPaymentMethod, processPayment, setCustomerBlockData, setupPaymentIntent } from '../data/dataApi';
import { PaymentRequest, StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js';
import { alertController } from '@ionic/core';
import { RouteComponentProps, useLocation } from 'react-router';
import * as helpers from '../util/helpers';
import { loadCustomerData, setCustomer, setPayVisits } from '../data/customer/customer.actions';
import { IBranchLocation, IBusinessEntity } from '../models/Base/BusinessEntity';
import { ICustomerPass } from '../models/Base/CustomerPass';
import { ICustomer, IPaymentMethod, PaymentMethod } from '../models/Base/Customer';
import { ICustomerVisit } from '../models/Base/CustomerVisit';
import { FeeTypes, PaymentMethodTypes, ReservationStatuses } from '../models/Base/Enums';
import { ServiceItem } from '../models/ServiceItem';
import { ICustomerBlockResponse } from '../models/Service/CustomerBlockResponse';
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "./useResponsiveFontSize";

const useOptions = () => {
     const fontSize = useResponsiveFontSize();
     const options = useMemo(
          () => ({
               style: {
                    base: {
                         fontSize,
                         color: "#424770",
                         letterSpacing: "0.025em",
                         fontFamily: "Source Code Pro, monospace",
                         "::placeholder": {
                              color: "#aab7c4"
                         }
                    },
                    invalid: {
                         color: "#9e2146"
                    }
               }
          }),
          [fontSize]
     );

     return options;
};

interface OwnProps extends RouteComponentProps {
     reservationPayment?: boolean | false;
     isComponent?: boolean | false;
};

interface StateProps {
     branch?: IBranchLocation;
     business?: IBusinessEntity;
     customer?: ICustomer;
     loading: boolean;
     mode: 'ios' | 'md';
     pass?: ICustomerPass;
     selectedVisits?: ICustomerVisit[];
}

interface DispatchProps {
     loadCustomerData: typeof loadCustomerData;
     setLoading: typeof setLoading;
     setPaidWithPoints: typeof setPaidWithPoints;
     setPayAmount: typeof setPayAmount;
     setPayVisits: typeof setPayVisits;
     setPointsToRedeem: typeof setPointsToRedeem;
     setSelectedBusiness: typeof setSelectedBusiness;
     setSelectedBranch: typeof setSelectedBranch;
     setSelectedPass: typeof setSelectedPass;
     setTipAmount: typeof setTipAmount;
     setViewPay: typeof setViewPay;
     setVisits: typeof setVisits;
}

interface Error {
     showError: boolean;
     message?: string;
}

interface PayProps extends OwnProps, StateProps, DispatchProps { }

const Pay: React.FC<PayProps> = ({ business, branch, customer, history, mode, pass, selectedVisits, setPaidWithPoints, setPayAmount, setPayVisits, setPointsToRedeem, setSelectedBranch, setSelectedBusiness, setSelectedPass, setTipAmount, setViewPay, setVisits, reservationPayment, isComponent, loadCustomerData }) => {

     const [loading, setLoading] = useState<boolean>(false);
     const [error, setError] = useState<Error>({ showError: false });
     const [action, setAction] = useState(false);
     const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
     const [paymentOptions, setPaymentOptions] = useState<StripePaymentRequestButtonElementOptions>();
     const [serviceItems, setServiceItemsVal] = useState<ServiceItem[]>([]);
     const [percent, setPercent] = useState(0);
     const [tipAmountVal, setTipAmountValue] = useState(0);
     const [paymentAmountVal, setPaymentAmountValue] = useState(0);
     const [country, setCountry] = useState('');
     const spendAmount = useRef(0);
     const tipAmount = useRef(0);
     const taxAmount = useRef(0);
     const feesAmount = useRef(0);
     const paidAmount = useRef(0);
     const pointsToRedeem = useRef(0);
     const pointsToRedeemValue = useRef(0);
     const discount = useRef(0);
     const subtotal = useRef(0);
     const total = useRef(0);
     const payWithPoints = useRef(false);
     const paidWithPoints = useRef(false);
     const includeFees = useRef(false);
     const paymentAmount = useRef(0);
     const reservationAmount = useRef(0);
     const reservationPaymentMode = useRef<string>('partial');
     const stripe = useStripe();
     const elements = useElements();
     const options = useOptions();
     const cart = useRef<ICustomerVisit>();
     const customerBlock = useRef<ICustomerBlockResponse>();
     const [cardError, setCardError] = useState(true);
     const [cardInfo, setCardInfo] = useState(false);
     let query = useQuery();
     const isMounted = useRef(false);

     function useQuery() {
          return new URLSearchParams(useLocation().search);
     }

     async function showPaymentFailedAlert(errorMessage: string) {
          await alertController.create({
               message: errorMessage,
               buttons: [
                    {
                         text: 'OK'
                    }
               ]
          }).then(alert => alert.present());
     }

     useEffect(() => {
          try {
               isMounted.current = true;
               var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
               tz.toLowerCase().indexOf('america') >= 0 ? setCountry("US") : setCountry("IN");
               const cartId = query.get("cartId");
               if (cartId) {
                    window.addEventListener("message", async (event) => {
                         if (event.data.cv !== undefined) {
                              cart.current = event.data.cv as ICustomerVisit;
                              customerBlock.current = event.data.cb as ICustomerBlockResponse;
                              var rDate = event.data.rd as Date;
                              if (customerBlock && customerBlock.current.customer && cart && cart.current.bookingRef === cartId && cart.current.serviceItems && cart.current.serviceItems.length > 0) {
                                   const business = customerBlock.current.businessEntities!.find(c => c.businessId === cart.current!.businessId);
                                   cart.current.customerCountryCode = customerBlock.current.customer.countryCode;
                                   cart.current.customerEmailId = customerBlock.current.customer.emailId;
                                   cart.current.customerId = customerBlock.current.customer.id;
                                   cart.current.customerMobileNumber = customerBlock.current.customer.mobileNumber;
                                   cart.current.customerName = customerBlock.current.customer.userName;
                                   cart.current.discount = cart.current.serviceItems.map(item => item.discountTotal).reduce((prev, curr) => prev + curr, 0);
                                   cart.current.includeFees = false;
                                   cart.current.id = "vis-" + cart.current.businessId + cart.current.customerId + "-" + new Date().toISOString().replace(/[-:.Z]/g, "").replace("T", "-");
                                   cart.current.invoiceNumber = '';
                                   cart.current.paidAmount = 0;
                                   cart.current.paymentId = '';
                                   cart.current.serviceTime = parseInt(cart.current.serviceItems.map(item => item.serviceTime).reduce((prev, curr) => prev + curr, 0).toString());
                                   cart.current.serviceQty = cart.current.serviceItems.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0);
                                   cart.current.serviceType = cart.current.serviceItems[0].productClass;
                                   cart.current.cost = cart.current.serviceItems.map(item => item.cost).reduce((prev, curr) => prev + curr, 0);
                                   cart.current.spend = cart.current.serviceItems.map(item => item.totalPrice).reduce((prev, curr) => prev + curr, 0);
                                   cart.current.tax = cart.current.serviceItems.map(item => item.taxAmount).reduce((prev, curr) => prev + curr, 0);
                                   cart.current.taxFree = false;
                                   cart.current.tip = 0;
                                   cart.current.paymentAmount = cart.current.spend + cart.current.tax;
                                   cart.current.reservationDT = rDate;
                                   cart.current.reservationConfirmationDT = new Date();
                                   cart.current.reservationConfirmationRequestDT = new Date();
                                   cart.current.reservationStatus = ReservationStatuses.Active;

                                   await setCustomerBlockData(customerBlock.current).then(() => {
                                        loadCustomerData();
                                        const visits: ICustomerVisit[] = [];
                                        visits.push(cart.current!);
                                        setServiceItems(visits);
                                        if (business) {
                                             setSelectedBusiness(business);
                                             var branch = business.branchLocations!.find(c => c.branchId === cart.current!.branchId);
                                             if (branch) {
                                                  setSelectedBranch(branch);
                                             }
                                        }
                                   });
                              }
                         }
                    }, false);
               }

               if (stripe && customer && business && paymentRequest === null) {
                    const pr = stripe.paymentRequest({
                         country: customer.country! || 'US',
                         currency: business.currency!.toLowerCase() || 'usd',
                         total: {
                              label: "Demo",
                              amount: 100,
                              pending: false
                         },
                         requestPayerName: true
                    });

                    pr.canMakePayment().then(result => {
                         if (result) {
                              setPaymentOptions(({
                                   paymentRequest: pr,
                                   style: {
                                        paymentRequestButton: {
                                             theme: 'dark',
                                             height: undefined,
                                             type: undefined
                                        }
                                   }
                              }));
                              setPaymentRequest(pr);
                         }
                    });
               }
               setServiceItems(selectedVisits!);
               if (paymentRequest && business && customer) {
                    if (action === false) {
                         setAction(true);
                         paymentRequest.on('token', async function (event) {
                              const success = await makePayment(event.token.id, null);
                              if (success) {
                                   event.complete('success');
                              } else {
                                   event.complete('fail');
                              }
                         });
                    }

                    paymentRequest.update({
                         currency: business.currency!.toLowerCase(),
                         total: {
                              label: business.companyName!,
                              amount: paymentAmount.current,
                              pending: false
                         },
                    });
               }
          } catch (e) {
               setLoading(false);
               setError({ showError: true, message: (e as Error).message });
          }
     }, []);

     const confirmPaymentMethod = async (paymentMethod: IPaymentMethod) => {
          setTotals(PaymentMethodTypes.Online);
          if (paymentAmountVal > 0) {
               await alertController.create(
                    {
                         message: "Are you sure you want to pay " + helpers.toCurrency(country, paymentAmountVal / 100) + " with " + paymentMethod.methodName + " - " + paymentMethod.network + " ending " + paymentMethod.last4 + " with expiration " + paymentMethod.expMonth + "/" + paymentMethod.expYear,
                         buttons: [
                              {
                                   text: 'No',
                                   role: 'cancel'
                              },
                              {
                                   text: 'Yes',
                                   handler: async () => {
                                        await makePayment('', paymentMethod);
                                        if (reservationPayment == false) {
                                             setViewPay(false);
                                        }
                                   }
                              }
                         ]
                    }).then(alert => alert.present());
          }
     }

     const makePayment = async (token: string, paymentMethod: IPaymentMethod | null) => {
          try {
               if (selectedVisits && business && customer) {
                    setLoading(true);
                    const chargeResponse = await processPayment(
                         paymentAmount.current,
                         paidWithPoints.current,
                         tipAmount.current,
                         pointsToRedeem.current,
                         business.id!,
                         '',
                         business.companyName!,
                         business.currency!,
                         customer.id!,
                         customer.emailId!,
                         customer.paymentAccountId!,
                         paymentMethod,
                         paidWithPoints.current
                              ? PaymentMethodTypes.Points
                              : paymentMethod
                                   ? PaymentMethodTypes.Online
                                   : PaymentMethodTypes.Wallet,
                         token,
                         selectedVisits.map(e => e.id!),
                         null);
                    if (chargeResponse) {
                         setVisits(chargeResponse.visits!);
                         setSelectedPass(chargeResponse.pass!);
                         if (reservationPayment == false) {
                              setSelectedVisits([]);
                              setPayVisits([]);
                         } else {
                              setSelectedVisits(chargeResponse.visits!);
                         }
                    }

                    setLoading(false);
                    return true;
               }
          } catch (e) {
               setLoading(false);
               setError({ showError: true, message: (e as Error).message });
          }

          return false;
     }

     const promptPayment = async () => {
          try {
               if (paymentRequest) {
                    setTotals(PaymentMethodTypes.Wallet);
                    setViewPay(false);
                    paymentRequest.show();
               }
          } catch (e) {
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const setTip = async (pct: number) => {
          setPercent(pct);
          if (pct >= 0) {
               var tip = ((pct / 100.0) * (spendAmount.current - discount.current + taxAmount.current + pointsToRedeemValue.current)).toFixed(2);
               tipAmount.current = parseFloat(tip);
               setTipAmountValue(tipAmount.current);
               setTotals(PaymentMethodTypes.Online);
          }
     }

     const setTipAmountVal = async (tip: number) => {
          if (!tip) tip = 0;
          tipAmount.current = tip;
          setTipAmountValue(tip);
          setTotals(PaymentMethodTypes.Online);
          await promptPayWithPoints();
     }

     const setReservationPayment = (mode: string) => {
          reservationPaymentMode.current = mode;
          setServiceItems(selectedVisits!);
     }

     const setServiceItems = async (visits: ICustomerVisit[]) => {
          var serviceItems: ServiceItem[] = [];
          var sequence = 1;
          var spend = 0, tip = 0, tax = 0, disc = 0, payment = 0, paid = 0;
          var taxFree = false, includeFees = false;
          visits!.forEach((visit) => {
               taxFree = taxFree || visit.taxFree;
               includeFees = includeFees || visit.includeFees;
               spend += visit.spend;
               tip += visit.tip;
               tax += visit.taxFree == false ? visit.tax : 0;
               disc += visit.discount;
               payment += visit.paymentAmount;
               paid += visit.paidAmount;
               visit.serviceItems!.forEach(item => {
                    serviceItems.push(new ServiceItem(sequence++, false, false, item.quantity, item.description!, '', item.price, item.totalPrice));
                    item.discounts!.filter(e => e.orderLevel == false)!.map((discount) => {
                         serviceItems.push(new ServiceItem(sequence++, true, false, discount.quantity, discount.description!, discount.descriptionDetail!, 0, discount.discountTotal * -1));
                    });
                    if (item.refundPrice > 0) {
                         serviceItems.push(new ServiceItem(sequence++, true, false, item.quantity, item.description!, "*** Refund ***", item.price, item.refundPrice * -1));
                    }
               });
               visit.serviceItems!.forEach(item => {
                    item.discounts!.filter(e => e.orderLevel == true)!.map((discount) => {
                         serviceItems.push(new ServiceItem(sequence++, true, false, discount.quantity, discount.description!, discount.descriptionDetail!, 0, discount.discountTotal * -1));
                    });
               });
               if (!payWithPoints.current && visit.pointsValue > 0) {
                    serviceItems.push(new ServiceItem(sequence++, true, true, visit.pointsRedeemed, "Points Redeemed", "", 0, visit.pointsValue * -1));
               }
          });
          spendAmount.current = spend;
          discount.current = disc;
          tipAmount.current = tip;
          taxAmount.current = tax;
          pointsToRedeem.current = 0;
          paidAmount.current = paid;
          if (tipAmount.current <= 0) {
               if (percent > 0) {
                    tipAmount.current = parseFloat(((percent / 100.0) * (spendAmount.current - discount.current + taxAmount.current + feesAmount.current)).toFixed(2));
               } else if (tipAmountVal > 0) {
                    tipAmount.current = tipAmountVal;
               }
          }
          if (pass && payWithPoints.current && (spendAmount.current - discount.current - paidAmount.current + tipAmount.current) > 0) {
               pointsToRedeem.current = (spendAmount.current - discount.current - paidAmount.current + tipAmount.current) < pass.points / pass.pointsRedemptionRatio ? parseFloat(((spendAmount.current - discount.current - paidAmount.current + tipAmount.current) * pass.pointsRedemptionRatio).toFixed(0)) : pass.points;
               pointsToRedeemValue.current = (spendAmount.current - discount.current - paidAmount.current + tipAmount.current) < pass.points / pass.pointsRedemptionRatio ? (spendAmount.current - discount.current - paidAmount.current + tipAmount.current) : parseFloat((pass.points / pass.pointsRedemptionRatio).toFixed(2));
               discount.current = discount.current + pointsToRedeemValue.current;
               const branch = business!.branchLocations!.find(e => e.branchId === selectedVisits![0].branchId);
               taxAmount.current = taxFree ? 0 : (spendAmount.current - discount.current) * (branch!.taxPercent / 100.0);
               serviceItems.push(new ServiceItem(sequence++, true, true, pointsToRedeem.current, "Points Redeemed", "", 0, pointsToRedeemValue.current * -1));
          } else {
               payWithPoints.current = false;
          }

          setServiceItemsVal(serviceItems);
          subtotal.current = spendAmount.current - discount.current;
          setTotals(PaymentMethodTypes.Online);
          await promptPayWithPoints();
     }

     const setTotals = (paymentMethod: PaymentMethodTypes) => {
          total.current = spendAmount.current - discount.current + taxAmount.current + tipAmount.current - paidAmount.current;
          feesAmount.current = 0.0;
          if (business && business.feeMethods && includeFees.current) {
               var feeMethod = business.feeMethods.find(c => c.paymentMethod === paymentMethod);
               if (feeMethod) {
                    feesAmount.current = feeMethod.type === FeeTypes.Percent
                         ? parseFloat(((total.current + paidAmount.current) * (feeMethod.value / 100)).toFixed(2)) + feeMethod.constant
                         : feeMethod.type === FeeTypes.Fixed
                              ? feeMethod.value
                              : 0.0;
               }
          }

          if (reservationPayment &&
               reservationPaymentMode.current == "partial" &&
               branch && branch.reservationPaymentRequired &&
               branch.reservationPaymentPercent > 0 &&
               branch.reservationPaymentPercent < 100 &&
               paidAmount.current <= 0) {
               reservationAmount.current = branch && branch.reservationPaymentRequired ? parseFloat(((total.current + feesAmount.current) * branch.reservationPaymentPercent).toFixed(2)) : 0;
               paymentAmount.current = reservationAmount.current
          } else {
               paymentAmount.current = (total.current + feesAmount.current) * 100;
          }
          setPaymentAmountValue(paymentAmount.current);
     }

     const redeemPoints = () => {
          payWithPoints.current = payWithPoints.current === false;
          setServiceItems(selectedVisits!);
     }

     const promptPayWithPoints = async () => {
          if (paymentAmount.current === 0 && payWithPoints.current) {
               setTotals(PaymentMethodTypes.Points);
               await alertController.create(
                    {
                         message: "Are you sure you want to use " + pointsToRedeem.current + " points to pay " + helpers.toCurrency(country, pointsToRedeemValue.current),
                         buttons: [
                              {
                                   text: 'No',
                                   role: 'cancel',
                                   handler: () => {
                                        setTotals(PaymentMethodTypes.Online);
                                   }
                              },
                              {
                                   text: 'Yes',
                                   handler: async () => {
                                        paidWithPoints.current = true;
                                        await makePayment('', null);
                                        setViewPay(false);
                                   }
                              }
                         ]
                    }).then(alert => alert.present());
          }
     }

     const routeToStripe = () => {
          setTotals(PaymentMethodTypes.Online);
          if (paymentAmount.current > 0) {
               setViewPay(false);
               setPaidWithPoints(paidWithPoints.current);
               setPayAmount(paymentAmount.current);
               setPointsToRedeem(pointsToRedeem.current);
               setTipAmount(tipAmount.current);
               if (isComponent) {
                    setCardInfo(cardInfo === false);
               } else {
                    if (reservationPayment == true) {
                         history.push('/login/checkout', { direction: 'forward' });
                    } else {
                         history.push('/wallet/passdetails/checkout', { direction: 'forward' });
                    }
               }
          }
     }

     const pay = async () => {
          try {
               if (stripe && elements && cart.current && customerBlock.current && customerBlock.current.customer) {
                    setLoading(true);
                    const intentSecret = await setupPaymentIntent(customerBlock.current.customer.country!, customerBlock.current.customer.paymentAccountId!);
                    if (isMounted && intentSecret) {
                         const payload = await stripe.confirmCardSetup(intentSecret, {
                              payment_method: {
                                   card: elements.getElement(CardElement)!
                              }
                         });
                         if (payload.error !== undefined) {
                              await showPaymentFailedAlert(payload.error.message!);
                              setLoading(false);
                              return;
                         }
                         const paymentMethod = new PaymentMethod();
                         paymentMethod.name = '';
                         paymentMethod.emailId = customerBlock.current.customer.emailId;
                         paymentMethod.address = customerBlock.current.customer.homeLocation;
                         paymentMethod.addressLine1 = customerBlock.current.customer.address;
                         paymentMethod.city = customerBlock.current.customer.locality;
                         paymentMethod.locality = customerBlock.current.customer.locality;
                         paymentMethod.latitude = customerBlock.current.customer.latitude;
                         paymentMethod.longitude = customerBlock.current.customer.longitude;
                         paymentMethod.country = country;
                         paymentMethod.county = customerBlock.current.customer.county;
                         paymentMethod.state = customerBlock.current.customer.state;
                         paymentMethod.zipCode = customerBlock.current.customer.zipCode;
                         paymentMethod.intentId = payload.setupIntent!.id;
                         paymentMethod.methodId = payload.setupIntent!.payment_method!;
                         paymentMethod.methodName = '';

                         const business = customerBlock.current.businessEntities!.find(c => c.businessId === cart.current!.businessId);
                         const visitIds: string[] = [];
                         visitIds.push(cart.current.id!);
                         const chargeResponse = await processPayment(
                              paymentAmount.current,
                              false,
                              tipAmount.current,
                              pointsToRedeem.current,
                              business!.businessId!,
                              '',
                              business!.companyName!,
                              business!.currency!,
                              customerBlock.current.customer.id!,
                              customerBlock.current.customer.emailId!,
                              customerBlock.current.customer.paymentAccountId!,
                              paymentMethod,
                              PaymentMethodTypes.Online,
                              '',
                              visitIds,
                              cart.current);
                         if (chargeResponse) {
                              if (chargeResponse.pass != null) {
                                   window.parent.postMessage(chargeResponse.pass, "*");
                              }
                              else {
                                   await showPaymentFailedAlert(chargeResponse.error!);
                              }

                         }
                         setLoading(false);
                    }
               }
          } catch (e) {
               setLoading(false);
               setError({ showError: true, message: (e as Error).message });
          }
     }

     return (
          <div style={{ height: "100%", overflow: "auto" }}>
               <IonToast
                    isOpen={error.showError}
                    position="top"
                    onDidDismiss={() => setError({ message: "", showError: false })}
                    message={error.message}
                    duration={3000}
               />
               <IonLoading
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={'Processing...Please wait...'}
               />
               <IonGrid className={isComponent ? "component" : "container"}>
                    <IonRow>
                         <IonCol>
                              <span className="company">{business && business.companyName} - {branch && branch.branchName}</span>
                              {!isComponent && <div style={{ float: 'right', color: "lightgray", paddingLeft: "10px" }} className="fa fa-times-circle fa-lg" onClick={() => setViewPay(false)}></div>}
                         </IonCol>
                    </IonRow>
                    <IonRow>
                         <IonCol className="ion-text-center">
                              <div className="cart-col-sec flex">
                                   <div className="cart-col-list">
                                        <div className="service-lists">
                                             <IonGrid>
                                                  <IonRow style={{ margin: "3px", fontSize: "12px", color: "gray" }}>
                                                       <IonCol className="ion-no-margin ion-no-padding ion-text-end" size="1">Qty</IonCol>
                                                       <IonCol className="ion-no-margin ion-no-padding ion-text-left" style={{ marginLeft: "20px" }}>Description</IonCol>
                                                       <IonCol className="ion-no-margin ion-no-padding ion-text-end" size="3">Unit Price</IonCol>
                                                       <IonCol className="ion-no-margin ion-no-padding ion-text-end" size="3">Total Price</IonCol>
                                                  </IonRow>

                                                  <div className="service-list-items">
                                                       {serviceItems && serviceItems.map((item) => (
                                                            <IonRow style={{ margin: "6px 3px", fontSize: "13px", fontWeight: "600" }}>
                                                                 <IonCol className="ion-no-margin ion-no-padding ion-text-end blue" size="1">{item.quantity > 0 ? item.quantity : ""}</IonCol>
                                                                 <IonCol className="ion-no-margin ion-no-padding ion-text-left" style={{ marginLeft: "20px" }}>
                                                                      <div>{item.description}</div>
                                                                      {item.descriptionDetail && <div style={{ paddingLeft: "5px", fontSize: "10px", color: "gray" }}>{item.descriptionDetail}</div>}
                                                                 </IonCol>
                                                                 {item.retailPrice > 0 && <IonCol className="ion-no-margin ion-no-padding ion-text-end blue" size="2">{helpers.toCurrency(country, item.retailPrice)}</IonCol>}
                                                                 <IonCol className={item.totalPrice > 0 ? "ion-no-margin ion-no-padding ion-text-end green" : "ion-no-margin ion-no-padding ion-text-end red"} size="3">{helpers.toCurrency(country, item.totalPrice)}</IonCol>
                                                            </IonRow>
                                                       ))}
                                                  </div>

                                                  <IonRow style={{ height: "10px" }}>
                                                       <IonCol className="ion-no-margin ion-no-padding" />
                                                       <IonCol className="ion-no-margin ion-no-padding" size="3"><hr style={{ borderTop: "1px solid gray" }} /></IonCol>
                                                  </IonRow>
                                                  <IonRow style={{ fontSize: "13px", marginRight: "3px" }}>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding" style={{ color: "gray" }}>Total</IonCol>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding green" size="3">{helpers.toCurrency(country, subtotal.current)}</IonCol>
                                                  </IonRow>
                                                  <IonRow style={{ fontSize: "13px", marginRight: "3px" }}>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding" style={{ color: "gray" }}>Tax</IonCol>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding" size="3">+ {helpers.toCurrency(country, taxAmount.current)}</IonCol>
                                                  </IonRow>
                                                  <IonRow style={{ fontSize: "13px", marginRight: "3px" }}>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding" style={{ color: "gray" }}>Tips</IonCol>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding" size="3">+ {helpers.toCurrency(country, tipAmount.current)}</IonCol>
                                                  </IonRow>
                                                  {feesAmount.current > 0 &&
                                                       <IonRow style={{ fontSize: "13px", marginRight: "3px" }}>
                                                            <IonCol className="ion-text-end ion-no-margin ion-no-padding" style={{ color: "gray" }}>Fees</IonCol>
                                                            <IonCol className="ion-text-end ion-no-margin ion-no-padding" size="3">+ {helpers.toCurrency(country, feesAmount.current)}</IonCol>
                                                       </IonRow>}
                                                  {paidAmount.current > 0 &&
                                                       <IonRow style={{ fontSize: "13px", marginRight: "3px" }}>
                                                            <IonCol className="ion-text-end ion-no-margin ion-no-padding" style={{ color: "gray" }}>Paid</IonCol>
                                                            <IonCol className="ion-text-end ion-no-margin ion-no-padding red" size="3">- {helpers.toCurrency(country, paidAmount.current)}</IonCol>
                                                       </IonRow>}
                                                  <IonRow style={{ height: "10px" }}>
                                                       <IonCol className="ion-no-margin ion-no-padding" />
                                                       <IonCol className="ion-no-margin ion-no-padding" size="3"><hr style={{ borderTop: "1px solid gray" }} /></IonCol>
                                                  </IonRow>
                                                  <IonRow>
                                                       <IonCol className="ion-text-end ion-no-margin ion-no-padding payment-amount">{helpers.toCurrency(country, total.current)}</IonCol>
                                                  </IonRow>
                                             </IonGrid>
                                        </div>
                                        <div className="tip-sec">
                                             <IonGrid>
                                                  <IonRow>
                                                       <IonCol className="ion-no-margin ion-no-padding">
                                                            <IonGrid>
                                                                 <IonRow>
                                                                      <IonCol><div className={percent === 0 ? "selected" : ""} onClick={() => setTip(0)} style={{ border: "1px solid gainsboro", borderRadius: "10px", padding: "5px" }}>0%</div></IonCol>
                                                                      <IonCol><div className={percent === 5 ? "selected" : ""} onClick={() => setTip(5)} style={{ border: "1px solid gainsboro", borderRadius: "10px", padding: "5px" }}>5%</div></IonCol>
                                                                      <IonCol><div className={percent === 10 ? "selected" : ""} onClick={() => setTip(10)} style={{ border: "1px solid gainsboro", borderRadius: "10px", padding: "5px" }}>10%</div></IonCol>
                                                                 </IonRow>
                                                                 <IonRow>
                                                                      <IonCol><div className={percent === 15 ? "selected" : ""} onClick={() => setTip(15)} style={{ border: "1px solid gainsboro", borderRadius: "10px", padding: "5px" }}>15%</div></IonCol>
                                                                      <IonCol><div className={percent === 20 ? "selected" : ""} onClick={() => setTip(20)} style={{ border: "1px solid gainsboro", borderRadius: "10px", padding: "5px" }}>20%</div></IonCol>
                                                                      <IonCol><div className={percent === 25 ? "selected" : ""} onClick={() => setTip(25)} style={{ border: "1px solid gainsboro", borderRadius: "10px", padding: "5px" }}>25%</div></IonCol>
                                                                 </IonRow>
                                                            </IonGrid>
                                                       </IonCol>
                                                       <IonCol className="ion-no-margin ion-no-padding" size="4">
                                                            <div style={{ color: "MediumVioletRed", marginTop: "15px" }}>Add Tip</div>
                                                            <IonInput style={{ borderBottom: "1px solid black", fontSize: "20px", fontWeight: "bold" }} type="number" inputMode="decimal" value={tipAmountVal} onIonChange={e => setTipAmountVal(parseFloat(e.detail.value!))} />
                                                       </IonCol>
                                                  </IonRow>
                                             </IonGrid>
                                        </div>
                                   </div>
                                   <div className="payment-container">
                                        {pass && <div className={payWithPoints.current ? "payment-item selected" : "payment-item"} onClick={() => redeemPoints()}>
                                             <div className="payment-item-content">
                                                  <div style={{ alignSelf: "center" }}>
                                                       <div style={{ color: "goldenrod", fontSize: "14px" }}>Redeem Points</div>
                                                       <div style={{ color: "black", fontSize: "12px" }}>{helpers.toCurrency(country, pass.points / pass.pointsRedemptionRatio)}</div>
                                                  </div>
                                             </div>
                                        </div>}
                                        {paymentRequest && paymentOptions &&
                                             <div className="payment-item" style={{ backgroundColor: "black", alignSelf: "center" }} onClick={() => { promptPayment(); }}>
                                                  <div className={mode === "ios" ? "apple-pay" : "google-pay"}>
                                                       <img className={mode === "ios" ? "apple-pay-img" : "google-pay-img"} src={mode === "ios" ? "/assets/img/apple.png" : "/assets/img/google.png"} />
                                                       <span className={mode === "ios" ? "apple-pay-text" : "google-pay-text"}>Pay</span>
                                                  </div>
                                             </div>}
                                        <div className="payment-item" style={{ color: "purple" }} onClick={() => routeToStripe()}>
                                             <div className="payment-item-content">
                                                  <div style={{ alignSelf: "center", fontSize: "14px" }}>
                                                       New Credit Card
                                                  </div>
                                             </div>
                                        </div>
                                        {customer && customer.paymentMethods!.map((method) => (
                                             <div key={method.methodId} className="payment-item" onClick={() => confirmPaymentMethod(method)}>
                                                  <div className="payment-item-content">
                                                       <div style={{ alignSelf: "center" }}>
                                                            <div style={{ fontSize: "25px", color: "lightseagreen" }} className={(method.network === "visa" ? "fa fa-cc-visa" : method.network === "mastercard" ? "fa fa-cc-mastercard" : method.network === "discover" ? "fa fa-cc-discover" : method.network === "amex" ? "fa fa-cc-amex" : "fa fa-credit-card")} />
                                                            <div style={{ fontSize: "10px", color: "gray" }}>Ending {method.last4}</div>
                                                            <div style={{ fontSize: "10px", color: "gray" }}>Exp {method.expMonth}/{method.expYear}</div>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}

                                        {isComponent && <IonCard hidden={!cardInfo} style={{ textAlign: "center" }}>
                                             <label style={{ width: "100%" }}>
                                                  <CardElement
                                                       options={options}
                                                       onReady={() => {
                                                            console.log("CardElement [ready]");
                                                       }}
                                                       onChange={event => {
                                                            setCardError(false);
                                                       }}
                                                       onBlur={() => {
                                                            console.log("CardElement [blur]");
                                                       }}
                                                       onFocus={() => {
                                                            console.log("CardElement [focus]");
                                                       }}
                                                  />
                                                  {cardError && <IonText color="danger">
                                                       <p className="ion-no-margin ion-no-padding text-xsmall">
                                                            Card number required
                                                       </p>
                                                  </IonText>}
                                             </label>
                                             <div className="payment-cancel-policy" style={{ color: "purple" }}>
                                                  <div className="payment-cancel-policy-content">
                                                       <div>
                                                            Cancellation Policy
                                                       </div>
                                                       <div>
                                                            Cancel for free upto  <strong>48</strong> hours ahead, otherwise you can be
                                                            charged <strong>50%</strong> of service price for late cancellation or
                                                            <strong> 100%</strong> for not showing up.
                                                       </div>
                                                  </div>
                                             </div>
                                             <IonButton onClick={pay} disabled={!stripe}>Pay</IonButton>
                                        </IonCard>}
                                   </div>
                              </div>
                         </IonCol>
                    </IonRow>
                    {reservationPayment && reservationAmount.current > 0 && branch!.reservationPaymentPercent < 100 &&
                         <IonRow>
                              <IonCol className="ion-text-center">
                                   <div style={{ backgroundColor: "white", border: "1px solid gainsboro", borderRadius: "5px" }}>
                                        <div style={{ color: "MediumVioletRed", marginTop: "5px" }}>Reservation Payment Due Now</div>
                                        <IonList>
                                             <IonRadioGroup value={reservationPaymentMode.current} onIonChange={e => setReservationPayment(e.detail.value)}>
                                                  <IonGrid>
                                                       <IonRow>
                                                            <IonCol>
                                                                 <IonLabel style={{ marginRight: "10px", fontWeight: "bold" }}>{helpers.toCurrency(country, total.current)}</IonLabel>
                                                                 <IonRadio style={{ alignSelf: "center" }} mode="md" value="full" />
                                                            </IonCol>
                                                            <IonCol>
                                                                 <IonLabel style={{ marginRight: "10px", fontWeight: "bold" }}>{helpers.toCurrency(country, reservationAmount.current / 100)}</IonLabel>
                                                                 <IonRadio value="partial" mode="md" />
                                                            </IonCol>
                                                       </IonRow>
                                                  </IonGrid>
                                             </IonRadioGroup>
                                        </IonList>
                                   </div>
                              </IonCol>
                         </IonRow>
                    }
               </IonGrid>
          </div>
     );
}

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          branch: state.session.selectedBranch,
          business: state.session.selectedBusiness,
          customer: state.customer.customer,
          loading: state.session.loading,
          mode: getConfig()!.get('mode'),
          pass: state.session.selectedPass,
          selectedVisits: state.session.selectedVisits
     }),
     mapDispatchToProps: {
          loadCustomerData,
          setLoading,
          setPaidWithPoints,
          setPayAmount,
          setPayVisits,
          setPointsToRedeem,
          setSelectedBranch,
          setSelectedBusiness,
          setSelectedPass,
          setTipAmount,
          setViewPay,
          setVisits
     },
     component: Pay
})
