export class Filter {
     id: string;
     name: string;
     selected: boolean;

     constructor(id: string, name: string, selected: boolean) {
          this.id = id;
          this.name = name;
          this.selected = selected;
     }
}