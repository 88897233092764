import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { grid, card, moonOutline, logIn, logOut, person } from 'ionicons/icons';

import { connect } from '../data/connect';
import { setAutoPay, setDarkMode } from '../data/user/user.actions';

import './Menu.css'
import { ICustomer } from '../models/Base/Customer';

const routes = {
  appPages: [
    { title: 'Home', path: '/wallet', icon: grid },
    { title: 'Payments', path: '/payments', icon: card }
  ],
  loggedInPages: [
    { title: 'Personal Info', path: '/account', icon: person },
    { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn }
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}
interface StateProps {
  autoPay: boolean;
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
  customer?: ICustomer
}

interface DispatchProps {
  setAutoPay: typeof setAutoPay,
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ autoPay, darkMode, history, isAuthenticated, customer, setAutoPay, setDarkMode, menuEnabled }) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none" hidden={isAuthenticated && customer ? false : true}>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {isAuthenticated && customer ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
          {isAuthenticated && customer && <IonItem>
            <IonIcon slot="start" icon={card}></IonIcon>
            <IonLabel>Auto Pay</IonLabel>
            <IonToggle checked={autoPay} onClick={() => setAutoPay(!autoPay)} />
          </IonItem>}
          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    autoPay: state.user.autoPay,
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.session.menuEnabled,
    customer: state.customer.customer
  }),
  mapDispatchToProps: ({
    setAutoPay,
    setDarkMode
  }),
  component: withRouter(Menu)
})
