import React, { useEffect, useRef, useState } from 'react';
import './ReservationSlots.css';
import { IonLabel, IonToast } from '@ionic/react'
import { connect } from '../data/connect';
import { useLocation } from 'react-router';
import { getReservationSlots } from '../data/dataApi';
import { IReservationSlotsResponse } from '../models/Service/ReservationSlotsResponse';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { ICustomerVisit } from '../models/Base/CustomerVisit';

interface Error {
     showError: boolean;
     message?: string;
}

interface ReservationSlotsProps { }

const ReservationSlots: React.FC<ReservationSlotsProps> = () => {

     const [loading, setLoading] = useState<boolean>(false);
     const [error, setError] = useState<Error>({ showError: false });
     const [selectedDate, setSelectedDate] = useState<Date>(new Date());
     const [selectedTime, setSelectedTime] = useState<Date>(new Date());
     const businessId = useRef('');
     const branchId = useRef('');
     const customerId = useRef('');
     const forUserId = useRef('');
     const [dates, setDates] = useState<Date[]>();
     const [times, setTimes] = useState<IReservationSlotsResponse>();
     const customerVisit = useRef<ICustomerVisit>();

     let query = useQuery();
     const monthOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
     const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

     function useQuery() {
          return new URLSearchParams(useLocation().search);
     }

     useEffect(() => {
          businessId.current = query.get("bid")!;
          branchId.current = query.get("brid")!;
          customerId.current = query.get("cid")!;
          forUserId.current = query.get("uid")!;
          const maxDays = parseInt(query.get("md") || '') || 30;
          const dates: Date[] = [];
          for (var i = 0; i < maxDays; i++) {
               const date = new Date();
               date.setDate(date.getDate() + i);
               if (i !== 0) {
                    date.setHours(0, 0, 0, 0);
               }
               dates.push(date);
          }
          setDates(dates);
          setSelectedDate(dates[0]);

          if (businessId) {
               window.addEventListener("message", async (event) => {
                    if (event.data.cv !== undefined) {
                         customerVisit.current = event.data.cv as ICustomerVisit;
                         getSlots(dates[0]);
                    }
               });
          }
     }, []);

     const getSlots = async (sDate: Date) => {
          try {
               setLoading(true);
               var serviceIds: string[];
               if (customerVisit.current! !== undefined)
                    serviceIds = customerVisit.current!.serviceItems!.map(({ productId }) => productId!);
               else
                    serviceIds = [];
               var reservationSlots = await getReservationSlots(businessId.current, branchId.current, customerId.current, forUserId.current, sDate, serviceIds);
               if (reservationSlots) {
                    setTimes(reservationSlots);
                    if (reservationSlots) {
                         setSelectedTime(reservationSlots.selectedTime);
                    }
               }
               setLoading(false);
          } catch (e) {
               setLoading(false);
               setError({ showError: true, message: (e as Error).message });
          }
     };

     const selectDate = (date: Date) => {
          setSelectedDate(new Date(date));
          getSlots(new Date(date));
     }

     const confirm = () => {
          window.parent.postMessage(selectedTime, "*");
     }

     return (
          <div className="bg">
               <IonToast
                    isOpen={error.showError}
                    position="top"
                    onDidDismiss={() => setError({ message: "", showError: false })}
                    message={error.message}
                    duration={3000}
               />
               {times &&
                    <div className="col-xs-12 form-group box-sec">
                         <div className="row">
                              <div className="col-md-12 pa-2 date-time-slot">
                                   <Swiper
                                        spaceBetween={20}
                                        slidesPerView={5}
                                   >
                                        {dates && dates.map((date: Date) => (
                                             <SwiperSlide key={date}>
                                                  <a data-toggle="tab" onClick={() => selectDate(date)} className={date.toDateString() === selectedDate.toDateString() ? "active" : "inactive"}>
                                                       <span className="slot-month">{monthOfYear[new Date(date).getMonth()]}</span>
                                                       <span className="slot-date">{new Date(date).getDate()}</span>
                                                       <span className="slot-day">{dayOfWeek[new Date(date).getDay()]}</span>
                                                  </a>
                                             </SwiperSlide>
                                        ))}
                                   </Swiper>
                              </div>
                              <div className="col-xs-12 date-time-slotSec">
                                   {loading && <div className="loader-gif"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
                                   {!loading && times.times &&
                                        <div className="tab-content">
                                             {times.times.length > 0 &&
                                                  <div className="tab-pane fade in active">
                                                       <div className="slot-time-carousel tab-container active">
                                                            {times.times.map((time: Date) => (
                                                                 <IonLabel key={new Date(time).getTime()} onClick={() => setSelectedTime(time)} className={new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) === new Date(selectedTime!).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) ? "slot-time-item active" : "slot-time-item"}>
                                                                      <strong>{new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</strong>
                                                                 </IonLabel>
                                                            ))}
                                                       </div>
                                                  </div>}
                                             {times.times.length <= 0 &&
                                                  <div id="date3" className="tab-pane fade">
                                                       <div className="col-xs-12 text-center py-2 tab-container">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 69" width="100"><g fill="none" fillRule="evenodd"><path fill="#F2F2F7" d="M7.39 0h60.765v35.309H7.39z"></path><path fill="#101928" d="M75.184 26.8515h-57.94c-.6802 0-1.2317.5514-1.2317 1.2317 0 .6802.5515 1.2317 1.2318 1.2317h57.94c.6803 0 1.2317-.5515 1.2317-1.2317 0-.6803-.5514-1.2317-1.2317-1.2317z"></path><path fill="#101928" d="M18.4758 60.4774v-45.82H74.642v24.5813c0 .6803.5515 1.2317 1.2318 1.2317.6802 0 1.2317-.5514 1.2317-1.2317v-25.813c0-.6802-.5515-1.2317-1.2317-1.2317H17.244c-.6803 0-1.2318.5515-1.2318 1.2317v48.2834c0 .6803.5515 1.2317 1.2318 1.2317h24.0016c.6803 0 1.2318-.5514 1.2318-1.2317s-.5515-1.2317-1.2318-1.2317h-22.77z"></path><path fill="#101928" d="M29.4627 7.3903V19.806c0 .6803.5515 1.2318 1.2317 1.2318.6803 0 1.2317-.5515 1.2317-1.2318V7.3903c0-.6802-.5514-1.2317-1.2317-1.2317-.6802 0-1.2317.5515-1.2317 1.2317zm31.0393 0V19.806c0 .6803.5515 1.2318 1.2317 1.2318.6803 0 1.2317-.5515 1.2317-1.2318V7.3903c0-.6802-.5514-1.2317-1.2317-1.2317-.6802 0-1.2317.5515-1.2317 1.2317zm1.2317 33.9462c7.5373 0 13.6475 6.1101 13.6475 13.6474 0 7.5373-6.1102 13.6474-13.6475 13.6474-7.5372 0-13.6474-6.1101-13.6474-13.6474 0-7.5373 6.1102-13.6474 13.6474-13.6474zm0 2.4634c-6.1767 0-11.184 5.0073-11.184 11.184 0 6.1768 5.0073 11.184 11.184 11.184 6.1768 0 11.184-5.0072 11.184-11.184 0-6.1767-5.0072-11.184-11.184-11.184z"></path><path fill="#101928" d="M62.9654 49.8107c0-.6803-.5514-1.2317-1.2317-1.2317-.6802 0-1.2317.5514-1.2317 1.2317v5.1732c0 .6803.5515 1.2317 1.2317 1.2317h5.1732c.6803 0 1.2318-.5514 1.2318-1.2317 0-.6802-.5515-1.2317-1.2318-1.2317h-3.9415v-3.9415z"></path></g></svg>
                                                            <h2 className="my-2 h2">We are all<br />booked today</h2>
                                                            <p>but you can book for next week, 8 Sep</p>
                                                            <p><a data-toggle="tab" href="#date4" className="cs-btn">Go to Wednesday, 8 Sep</a></p>
                                                       </div>
                                                  </div>}
                                        </div>}
                                   <button className="payPK pk-btn" onClick={confirm}>
                                        Pay
                                   </button>
                              </div>

                         </div>
                    </div>}
          </div>
     );
}

export default connect({
     component: ReservationSlots
})