import { Plugins } from '@capacitor/core';
import { IBusinessEntity, IBeaconDevice, IBranchLocation } from '../models/Base/BusinessEntity';
import { ICampaign } from '../models/Base/Campaign';
import { Customer, IPaymentMethod, ICustomer } from '../models/Base/Customer';
import { ICustomerPass } from '../models/Base/CustomerPass';
import { ICustomerVisit } from '../models/Base/CustomerVisit';
import { PaymentMethodTypes } from '../models/Base/Enums';
import { IProduct } from '../models/Base/Product';
import { ICustomerBlockResponse } from '../models/Service/CustomerBlockResponse';
import { ICustomerCheckInResponse } from '../models/Service/CustomerCheckInResponse';
import { IPaymentChargeResponse } from '../models/Service/PaymentChargeResponse';
import { IPaymentIntentResponse } from '../models/Service/PaymentIntentResponse';
import { IReservationResponse } from '../models/Service/ReservationResponse';
import { IReservationSlotsResponse } from '../models/Service/ReservationSlotsResponse';
import { IReservationsResponse } from '../models/Service/ReservationsResponse';
import { IWalletPayRequest } from '../models/Service/WalletPayRequest';

const { Storage } = Plugins;
// export const SERVER_URL = 'https://infopointapp-staging.azurewebsites.net';
// export const SERVER_URL = 'http://localhost:12750';
export const SERVER_URL = 'https://infopoint.com/Services';
const secret = 'QQjQZ2NNpltVT4ZEgdit9xKmqQkqySGW/3ub1wamPZhi4T5JKrrcXg';
const publicKey = "bsLpuQT3SAWTHv9BoU2HCvM9xy3Lqq2sox60a8lXl3Ywj4eJWXtcBG3X6vu8J7q7siiGWFxISDGNfPRnp79gxw==";

const HAS_LOGGED_IN = 'hasLoggedIn';
const AUTO_PAY = 'autoPay';
const COUNTRYCODE = 'countryCode';
const MOBILENUMBER = 'mobileNumber';
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
const CUSTOMERBLOCK = 'customerBlock';
const USERNAME = 'username';
const TOKEN = 'token';
const SESSIONSTATE = "sessionState"

export const setToken = async (pubKey: string | null) => {
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        'data': {
          'id': secret,
          'publicKey': pubKey ? pubKey : publicKey,
          'publicKeyType': 'AES'
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Token/GetToken", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const tokenResponse = serviceResponse.data as ITokenResponse;
  await Storage.set({ key: TOKEN, value: tokenResponse.token });
}

export const fetchCustomerBlock = async (pk: string | null,
  devicePlatform: string,
  countryCode: string,
  mobileNumber: string,
  emailId: string,
  otpRequired: boolean,
  maxLat: number,
  maxLng: number,
  minLat: number,
  minLng: number) => {
  await setToken(pk);
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'countryCode': countryCode,
          'mobileNumber': mobileNumber,
          'emailId': emailId,
          'otp': otpRequired,
          'secret': secret,
          'devicePlatform': devicePlatform,
          'maxLatitude': maxLat,
          'maxLongitude': maxLng,
          'minLatitude': minLat,
          'minLongitude': minLng
        },
        'currentPositionMinMax': {
          'maxLatitude': maxLat,
          'maxLongitude': maxLng,
          'minLatitude': minLat,
          'minLongitude': minLng
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/Login", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const customerBlock = serviceResponse.data as ICustomerBlockResponse;
  return customerBlock;
}

export const registerCustomer = async (pk: string | null, customer: Customer, businesses: string[], maxLat: number,
  maxLng: number,
  minLat: number,
  minLng: number) => {
  const token = await Storage.get({ key: TOKEN });
  if (token.value === null) {
    await setToken(pk);
  }
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'address': customer.address,
          'businesses': businesses,
          'city': customer.city,
          'country': customer.country,
          'countryCode': customer.countryCode,
          'county': customer.county,
          'dob': customer.dob,
          'emailId': customer.emailId,
          'firstName': customer.firstName,
          'gender': customer.gender,
          'homeLocation': customer.homeLocation,
          'latitude': customer.latitude,
          'lastName': customer.lastName,
          'locality': customer.locality,
          'longitude': customer.longitude,
          'mobileNumber': customer.mobileNumber,
          'pictureURL': customer.pictureURL,
          'secret': secret,
          'state': customer.state,
          'update': false,
          'zipCode': customer.zipCode
        },
        'currentPositionMinMax': {
          'maxLatitude': maxLat,
          'maxLongitude': maxLng,
          'minLatitude': minLat,
          'minLongitude': minLng
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/Register", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const customerBlock = serviceResponse.data as ICustomerBlockResponse;
  return customerBlock;
}

export const saveCustomer = async (customer: Customer, businesses: string[], maxLat: number, maxLng: number, minLat: number, minLng: number) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'address': customer.address,
          'businesses': businesses,
          'city': customer.city,
          'country': customer.country,
          'countryCode': customer.countryCode,
          'county': customer.county,
          'dob': customer.dob,
          'emailId': customer.emailId,
          'firstName': customer.firstName,
          'gender': customer.gender,
          'homeLocation': customer.homeLocation,
          'latitude': customer.latitude,
          'lastName': customer.lastName,
          'locality': customer.locality,
          'longitude': customer.longitude,
          'mobileNumber': customer.mobileNumber,
          'pictureURL': customer.pictureURL,
          'secret': secret,
          'state': customer.state,
          'update': true,
          'zipCode': customer.zipCode
        },
        'currentPositionMinMax': {
          'maxLatitude': maxLat,
          'maxLongitude': maxLng,
          'minLatitude': minLat,
          'minLongitude': minLng
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/Register", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const customerBlock = serviceResponse.data as ICustomerBlockResponse;
  return customerBlock;
}

export const getProductsForCampaign = async (campaign: ICampaign, type: string) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'businessId': campaign.businessId,
        'campaignId': campaign.id,
        'type': type
      })
  };
  const response = await fetch(SERVER_URL + "/Product/GetProductsForCampaign", requestOptions);
  const responseData = await response.json();
  const products = responseData as IProduct[];
  return products;
}

export const getReservation = async (code: string) => {
  const requestOptions = {
    crossDomain: true,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };
  const response = await fetch(SERVER_URL + "/Home/GetReservation?code=" + code, requestOptions);
  const responseData = await response.json();
  const paymentViewModel = responseData as IPaymentViewModel;
  if (paymentViewModel.error) {
    throw new Error(paymentViewModel.error);
  }
  await Storage.set({ key: TOKEN, value: paymentViewModel.token! });
  return paymentViewModel;
}

export const getReservationsMap = async (businessId: string,
  branchId: string,
  customerId: string,
  startDate: string,
  selectedDate: string) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'businessId': businessId,
          'branchId': branchId,
          'customerId': customerId,
          'startDate': startDate,
          'selectedDate': selectedDate
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Business/GetReservationsMap", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const reservationsMap = serviceResponse.data as IReservationsResponse;
  return reservationsMap;
}

export const getReservationSlots = async (businessId: string,
  branchId: string,
  customerId: string,
  forUserId: string,
  selectedDate: Date,
  serviceIds: string[]) => {
  await setToken('');
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'businessId': businessId,
          'branchId': branchId,
          'customerId': customerId,
          'forUserId': forUserId,
          'selectedLocalDT': selectedDate,
          'serviceItemIds': serviceIds
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Reservation/GetReservationSlots", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const reservationSlots = serviceResponse.data as IReservationSlotsResponse;
  return reservationSlots;
}

export const processReservation = async (businessId: string,
  branchId: string,
  customerId: string,
  passId: string,
  reservation: Date,
  deleteReservation: boolean,
  startDate: string,
  selectedDate: string) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'businessId': businessId,
          'branchId': branchId,
          'customerId': customerId,
          'delete': deleteReservation,
          'passId': passId,
          'reservation': reservation,
          'startDate': startDate,
          'selectedDate': selectedDate
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/Reservation", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const reservationResponse = serviceResponse.data as IReservationResponse;
  const reservationsMap = reservationResponse.reservationsResponse as IReservationsResponse;
  return reservationsMap;
}

export const getCustomerPasses = async (customerId: string) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {},
        'operation': "GetAll",
        'queryId': "AllPassesForCustomer",
        'queryParameters': [customerId]
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/GetCustomerPass", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const passes = serviceResponse.data as ICustomerPass[];
  return passes;
}

export const getCustomerVisits = async (businessId: string,
  customerId: string) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {},
        'operation': "GetAll",
        'queryId': "AllVisits",
        'queryParameters': [businessId, customerId]
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/GetCustomerVisit", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const visits = serviceResponse.data as ICustomerVisit[];
  return visits;
}

export const processCheckIn = async (businessId: string,
  branchId: string,
  customerId: string,
  passId: string,
  messagingId: string,
  cancel: boolean) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'businessId': businessId,
          'branchId': branchId,
          'customerId': customerId,
          'cancel': cancel,
          'passId': passId,
          'messagingId': messagingId
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/CheckIn", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const checkInResponse = serviceResponse.data as ICustomerCheckInResponse;
  const pass = checkInResponse.pass as ICustomerPass;
  return pass;
}

export const setupPaymentIntent = async (country: string, customerId: string) => {
  const token = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token.value },
    body: JSON.stringify(
      {
        'data': {
          'country': country,
          'customerId': customerId
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Payment/SetupPaymentIntent", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const intentResponse = serviceResponse.data as IPaymentIntentResponse;
  const secret = intentResponse.secret;
  return secret;
}

export const processPayment = async (
  amount: number,
  paidWithPoints: boolean,
  tip: number,
  points: number,
  businessId: string,
  branchId: string,
  companyName: string,
  currency: string,
  customerId: string,
  emailId: string,
  paymentAccountId: string,
  paymentMethod: IPaymentMethod | null,
  paymentMethodType: PaymentMethodTypes,
  token: string,
  visitIds: Array<string>,
  visit: ICustomerVisit | null) => {
  const bearerToken = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + bearerToken.value },
    body: JSON.stringify(
      {
        'data': {
          'amount': amount,
          'paidWithPoints': paidWithPoints,
          'tip': tip,
          'points': points,
          'businessId': businessId,
          'branchId': branchId,
          'companyName': companyName,
          'currency': currency,
          'customerId': customerId,
          'emailId': emailId,
          'paymentAccountId': paymentAccountId,
          'paymentMethod': paymentMethod,
          'paymentMethodType': paymentMethodType,
          'token': token,
          'visitIds': visitIds,
          'ReservationVisit': visit
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Payment/ChargeVisits", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const chargeResponse = serviceResponse.data as IPaymentChargeResponse;
  return chargeResponse;
}

export const addPaymentMethod = async (
  customerId: string,
  paymentMethod: IPaymentMethod | null,
  deleteCard: boolean,
  defaultCard: boolean,
  autoPay: boolean) => {
  const bearerToken = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + bearerToken.value },
    body: JSON.stringify(
      {
        'data': {
          'customerId': customerId,
          'method': paymentMethod,
          'delete': deleteCard,
          'default': defaultCard,
          'autoPay': autoPay
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/AddPaymentMethod", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const addResponse = serviceResponse.data as IPaymentMethodResponse;
  return addResponse.customer;
}

export const deletePaymentMethod = async (
  customerId: string,
  paymentMethod: IPaymentMethod | null) => {
  const bearerToken = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + bearerToken.value },
    body: JSON.stringify(
      {
        'data': {
          'customerId': customerId,
          'method': paymentMethod,
          'delete': true
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/PaymentMethod", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  const deleteResponse = serviceResponse.data as IPaymentMethodResponse;
  return deleteResponse.customer;
}

export const confirmWalletPay = async (customerId: string, walletPay: string) => {
  const bearerToken = await Storage.get({ key: TOKEN });
  const requestOptions = {
    crossDomain: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + bearerToken.value },
    body: JSON.stringify(
      {
        'data': {
          'customerId': customerId,
          'request': walletPay
        }
      })
  };
  const response = await fetch(SERVER_URL + "/Customer/ConfirmWalletPay", requestOptions);
  const responseData = await response.json();
  const serviceResponse = responseData as IServiceResponse;
  if (serviceResponse.error) {
    throw new Error(serviceResponse.errorMessage);
  }
  return serviceResponse.data as IWalletPayRequest;
}


export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: COUNTRYCODE }),
    Storage.get({ key: MOBILENUMBER }),
    Storage.get({ key: AUTO_PAY })]);
  const isLoggedin = await response[0].value === 'true';
  const hasSeenTutorial = await response[1].value === 'true';
  const countryCode = await response[2].value || undefined;
  const mobileNumber = await response[3].value || undefined;
  const autoPay = response[4].value === null || response[4].value === 'true';
  const username = undefined;
  const data = {
    isLoggedin,
    autoPay,
    hasSeenTutorial,
    username,
    countryCode,
    mobileNumber
  }
  return data;
}

export const getCustomerData = async () => {
  const response = await Promise.all([Storage.get({ key: CUSTOMERBLOCK })]);
  var cb = response[0].value || undefined;
  if (cb) {
    const customerBlock = JSON.parse(cb);
    const customer = customerBlock.customer as ICustomer;
    const passes = customerBlock.passes as ICustomerPass[];
    const businesses = customerBlock.businessEntities as IBusinessEntity[];
    const campaigns = customerBlock.campaigns as { [key: string]: ICampaign[]; };
    const beacons = customerBlock.beacons as { [key: string]: IBeaconDevice[]; };
    const data = {
      customer,
      passes,
      businesses,
      campaigns,
      beacons
    }
    return data;
  }
}

export const getSessionData = async () => {
  const response = await Promise.all([Storage.get({ key: SESSIONSTATE })]);
  var session = response[0].value || undefined;
  if (session) {
    const sessionState = JSON.parse(session);
    const selectedBusiness = sessionState[0] as IBusinessEntity;
    const selectedPass = sessionState[1] as ICustomerPass;
    const visits = sessionState[2] as ICustomerVisit[];
    const data = {
      selectedBusiness,
      selectedPass,
      visits
    }
    return data;
  }
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
}

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({ key: HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) });
}

export const setCustomerBlockData = async (customerBlock?: ICustomerBlockResponse) => {
  if (!customerBlock) {
    await Storage.remove({ key: TOKEN });
    await Storage.remove({ key: CUSTOMERBLOCK });
  } else {
    await Storage.set({ key: TOKEN, value: customerBlock.token! });
    await Storage.set({ key: CUSTOMERBLOCK, value: JSON.stringify(customerBlock) });
  }
}

export const setSessionBlockData = async (selectedBusiness?: IBusinessEntity, selectedPass?: ICustomerPass, visits?: ICustomerVisit[]) => {
  if (!selectedBusiness || !selectedPass || !visits) {
    await Storage.remove({ key: SESSIONSTATE });
  } else {
    const session = [selectedBusiness, selectedPass, visits];
    await Storage.set({ key: SESSIONSTATE, value: JSON.stringify(session) });
  }
}

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
}

export const setCountryCodeData = async (countryCode?: string) => {
  if (!countryCode) {
    await Storage.remove({ key: COUNTRYCODE });
  } else {
    await Storage.set({ key: COUNTRYCODE, value: countryCode });
  }
}

export const setMobileNumberData = async (mobileNumber?: string) => {
  if (!mobileNumber) {
    await Storage.remove({ key: MOBILENUMBER });
  } else {
    await Storage.set({ key: MOBILENUMBER, value: mobileNumber });
  }
}

export const setAutoPayData = async (autoPay: boolean) => {
  await Storage.set({ key: AUTO_PAY, value: JSON.stringify(autoPay) });
}

export const getToken = async () => {
  const token = await Storage.get({ key: TOKEN });
  return token.value!;
}

export interface IServiceResponse {
  data: any;
  error: boolean;
  errorMessage: string;
}

export interface ITokenResponse {
  token: string;
}

export interface IPaymentMethodResponse {
  success: boolean,
  customer?: ICustomer | undefined;
}

export interface IPaymentViewModel {
  error: string,
  token: string,
  branch?: IBranchLocation | undefined;
  business?: IBusinessEntity | undefined;
  customer?: ICustomer | undefined;
  pass?: ICustomerPass | undefined;
  visit?: ICustomerVisit | undefined;
}
