import { SessionActions } from './session.actions';
import { SessionState } from './session.state';

export const sessionReducer = (state: SessionState, action: SessionActions): SessionState => {
     switch (action.type) {
          case 'set-session':
               return { ...state, ...action.data };
          case 'set-branch-id':
               return { ...state, branchId: action.branchId };
          case 'set-business-id':
               return { ...state, businessId: action.businessId };
          case 'set-customer-loading':
               return { ...state, loading: action.isLoading };
          case 'set-search-business':
               return { ...state, searchBusiness: action.searchBusiness };
          case 'set-search-pass':
               return { ...state, searchPass: action.searchPass };
          case 'set-search-unpaid':
               return { ...state, searchUnpaid: action.searchUnpaid };
          case 'set-search-visit':
               return { ...state, searchVisit: action.searchVisit };
          case 'set-filter-id':
               return { ...state, filterId: action.filterId };
          case 'set-selected-business':
               return { ...state, selectedBusiness: action.selectedBusiness };
          case 'set-selected-branch':
               return { ...state, selectedBranch: action.selectedBranch };
          case 'set-selected-pass':
               return { ...state, selectedPass: action.selectedPass };
          case 'set-selected-visit':
               return { ...state, selectedVisit: action.visit };
          case 'set-selected-visits':
               return { ...state, selectedVisits: action.visits };
          case 'add-selected-visit':
               return { ...state, selectedVisits: [...(state.selectedVisits), action.visit] };
          case 'remove-selected-visit': 
               return { ...state, selectedVisits: [...(state.selectedVisits).filter(x => x.id !== action.id)] };
          case 'set-reservations-map':
               return { ...state, reservationsMap: action.reservationsMap };
          case 'select-business':
               return { ...state, selectedBusinesses: [...(state.selectedBusinesses), action.id] };
          case 'unselect-business': 
               return { ...state, selectedBusinesses: [...(state.selectedBusinesses).filter(x => x !== action.id)] };
          case 'set-selected-res-date': 
               return { ...state, selectedResDate: action.date };
          case 'set-selected-res-time': 
               return { ...state, selectedResTime: action.time };
          case 'set-visits': 
               return { ...state, visits: action.visits };
          case 'set-wallet-pay': 
               return { ...state, walletPay: action.walletPay };
          case 'set-paid-with-points': 
               return { ...state, paidWithPoints: action.paidWithPoints };
          case 'set-pay-amount': 
               return { ...state, payAmount: action.payAmount };
          case 'set-points-to-redeem': 
               return { ...state, pointsToRedeem: action.pointsToRedeem };
          case 'set-tip-amount': 
               return { ...state, tipAmount: action.tipAmount };
          case 'set-view-pay': 
               return { ...state, viewPay: action.viewPay };
     }
}