export class ServiceItem {
  sequence: number;
  discount: boolean;
  redeemPoints: boolean;
  quantity: number;
  description: string;
  descriptionDetail: string;
  retailPrice: number;
  totalPrice: number;
  constructor(sequence: number, discount: boolean, redeemPoints: boolean, quantity: number, description: string, descriptionDetail: string, retailPrice: number, totalPrice: number) {
    this.sequence = sequence;
    this.discount = discount;
    this.redeemPoints = redeemPoints;
    this.quantity = quantity;
    this.description = description;
    this.descriptionDetail = descriptionDetail;
    this.retailPrice = retailPrice;
    this.totalPrice = totalPrice;
  }
}