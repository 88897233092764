export enum EventTypes {
    Birthday = "Birthday",
    Holiday = "Holiday",
    Signup = "Signup",
    Visit = "Visit",
}

export enum OfferTypes {
    Cash = "Cash",
    Free = "Free",
    Multiplier = "Multiplier",
    PercentDiscount = "PercentDiscount",
    Points = "Points",
}

export enum CampaignProductCategories {
    Class = "Class",
    Department = "Department",
    Fineline = "Fineline",
    Item = "Item",
}

export enum CampaignFrequency {
    None = "None",
    Hourly = "Hourly",
    Daily = "Daily",
    Monthly = "Monthly",
}

export enum CampaignSegmentTypes {
    Tier = "Tier",
    Location = "Location",
}

export enum CampaignTypes {
    Advertisement = "Advertisement",
    BuyXGetY = "BuyXGetY",
    Event = "Event",
    SpendXGetY = "SpendXGetY",
    VisitXGetY = "VisitXGetY",
}

export enum CampaignStatus {
    Draft = "Draft",
    Created = "Created",
    PendingApproval = "PendingApproval",
    Approved = "Approved",
    Active = "Active",
    Paused = "Paused",
    Ended = "Ended",
    Deleted = "Deleted",
}

export enum AuthModes {
    AzureAD = "AzureAD",
    OTP = "OTP",
}

export enum BusinessVerticals {
    Retail = "Retail",
}

export enum BusinessCategories {
    Food = "Food",
    Grocery = "Grocery",
    Salon = "Salon",
    Other = "Other",
}

export enum PaymentMethodTypes {
    BankAccount = "BankAccount",
    Card = "Card",
    Cash = "Cash",
    Klarna = "Klarna",
    Online = "Online",
    Points = "Points",
    Wallet = "Wallet",
}

export enum FeeTypes {
    Fixed = "Fixed",
    Free = "Free",
    Percent = "Percent",
}

export enum BranchStatus {
    Active = "Active",
    InActive = "InActive",
    Locked = "Locked",
}

export enum CustomerStatus {
    Active = "Active",
    InActive = "InActive",
    Locked = "Locked",
}

export enum ReservationStatuses {
    None = "None",
    Active = "Active",
    Canceled = "Canceled",
    CheckedIn = "CheckedIn",
    Closed = "Closed",
    Created = "Created",
    Paused = "Paused",
    Pending = "Pending",
}

export enum VisitSources {
    InStore = "InStore",
    External = "External"
}