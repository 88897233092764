import React from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonBackButton } from '@ionic/react';
import { connect } from '../data/connect';
import * as helpers from '../util/helpers';
import './InvoicePage.scss';
import { IBusinessEntity, IBranchLocation } from '../models/Base/BusinessEntity';
import { ICustomer } from '../models/Base/Customer';
import { ICustomerVisit } from '../models/Base/CustomerVisit';

interface OwnProps { };

interface StateProps {
     business?: IBusinessEntity;
     branch?: IBranchLocation;
     customer?: ICustomer;
     visit?: ICustomerVisit;
};

interface DispatchProps { };

interface InvoicePageProps extends OwnProps, StateProps, DispatchProps { };

const InvoicePage: React.FC<InvoicePageProps> = ({ business, branch, customer, visit }) => {

     return (
          <IonPage id="invoice-page">
               <IonHeader>
                    <IonToolbar>
                         <IonButtons slot="start">
                              <IonBackButton defaultHref="/wallet/passdetails" />
                         </IonButtons>
                         <IonTitle>Invoice {visit!.invoiceNumber}</IonTitle>
                    </IonToolbar>
               </IonHeader>

               <IonContent>
                    <div>
                         <style type="text/css">

                         </style>
                         <table className="main-tbl tbl">
                              <tr>
                                   <td style={{ verticalAlign: "middle", textAlign: "center", padding: "0 20px" }}>
                                        <img src={business!.logoUrl} alt={business!.companyName} width="80" />
                                   </td>
                                   <td className="blue-text" style={{ verticalAlign: "middle", textAlign: "center", width: "620px" }}>
                                        <h2 className="m-b-0" style={{ color: "#79a13d" }}>{business!.companyName}</h2>
                                        <p className="m-a-0"><strong>{business!.currency === "USD" ? "EIN" : "GSTIN"}.: <span>{business!.registrationId}</span></strong></p>
                                        <p className="m-t-0 address-text">
                                             <strong>Address : </strong>
                                             <span>{branch!.address}</span>
                                        </p>
                                        <table className="b-a-0-tbl blue-text">
                                             <tr>
                                                  <td style={{ width: "50%", padding: "10px" }} align="left">
                                                       <a>
                                                            <img src="/assets/img/email.png" style={{ width: "14px" }} alt="Email : " />
                                                            <span>{branch!.contactEmailId}</span>
                                                       </a>
                                                  </td>
                                                  <td style={{ width: "50%", padding: "10px" }} align="right">
                                                       <a>
                                                            <img src="/assets/img/phone.png" style={{ width: "14px" }} alt="Phone : " />
                                                            <span>{branch!.contactPhoneNumber}</span>
                                                       </a>
                                                  </td>
                                             </tr>
                                        </table>
                                   </td>
                              </tr>
                              <tr>
                                   <td colSpan={2}>
                                        <table className="b-a-0-tbl">
                                             <tr>
                                                  <td style={{ width: "60%" }}>
                                                       <table>
                                                            <tr>
                                                                 <td colSpan={2}><p><span className="blue-text">To :</span> <br />
                                                                      <textarea name="" className="address-box">{customer!.userName + ", " + customer!.locality + ", " + customer!.mobileNumber}</textarea> </p></td>
                                                            </tr>
                                                       </table>
                                                  </td>
                                                  <td style={{ verticalAlign: "middle" }}>
                                                       <table>
                                                            <tr>
                                                                 <td><p style={{ margin: "6px 0" }}><span className="blue-text">Customer Id :</span> <input type="text" name="" style={{ width: "60%", float: "right" }} value={customer!.id} /> </p></td>
                                                            </tr>
                                                            <tr>
                                                                 <td><p style={{ margin: "6px 0" }}><span className="blue-text">Invoice No :</span> <input type="text" name="" style={{ width: "60%", float: "right" }} value={visit!.invoiceNumber} /> </p></td>
                                                            </tr>
                                                            <tr>
                                                                 <td><p style={{ margin: "6px 0" }}><span className="blue-text">Invoice Date :</span> <input type="text" name="" style={{ width: "60%", float: "right" }} value={new Date(visit!.checkOut).toLocaleDateString()} /> </p></td>
                                                            </tr>
                                                            <tr>
                                                                 <td><p style={{ margin: "6px 0" }}><span className="blue-text">Invoice Time :</span> <input type="text" name="" style={{ width: "60%", float: "right" }} value={new Date(visit!.checkOut).toLocaleTimeString()} /> </p></td>
                                                            </tr>
                                                       </table>
                                                  </td>
                                             </tr>
                                        </table>
                                   </td>
                              </tr>
                              <tr>
                                   <td colSpan={2} style={{ borderBottom: "0" }} className="infopoint-logo">
                                        <table style={{ height: "350px" }}>
                                             <tr>
                                                  <th style={{ height: "30px", width: "40px" }}>#</th>
                                                  <th style={{ width: "40%" }}>Description</th>
                                                  <th style={{ width: "60px" }}>Qty</th>
                                                  <th style={{ width: "100px" }}>Rate</th>
                                                  <th style={{ width: "100px" }}>Discount</th>
                                                  <th style={{ width: "100px" }}>Amount</th>
                                             </tr>

                                             {visit && visit.serviceItems!.map((item) => (
                                                  <tr>
                                                       <td contentEditable="true" className="b-l-0" style={{ padding: "10px 0", textAlign: "center" }}>{item.sequence}</td>
                                                       <td contentEditable="true" style={{ padding: "10px 5px" }}>{item.description}</td>
                                                       <td contentEditable="true" style={{ padding: "10px 5px", textAlign: "center" }}>{item.quantity}</td>
                                                       <td contentEditable="true" style={{ padding: "10px 5px", textAlign: "right" }}>{helpers.toCurrency(customer!.country!, item.price)}</td>
                                                       <td contentEditable="true" style={{ padding: "10px 5px", textAlign: "right" }}>{helpers.toCurrency(customer!.country!, item.discountTotal)}</td>
                                                       <td contentEditable="true" style={{ padding: "10px 5px", textAlign: "right" }}>{helpers.toCurrency(customer!.country!, item.totalPrice - item.discountTotal)}</td>
                                                  </tr>
                                             ))}
                                             <tr>
                                                  <td colSpan={5} className="prize-sec" style={{ textAlign: "right", height: "20px", border: "0" }}><p style={{ margin: "0" }} className="blue-text"><strong className="amt-tag">{helpers.toCurrency(customer!.country!, visit!.serviceItems!.map(item => item.discountTotal).reduce((prev, curr) => prev + curr, 0))}</strong></p></td>
                                                  <td style={{ border: "0", textAlign: "right", padding: "0px 5px" }}><strong className="amt-tag">{helpers.toCurrency(customer!.country!, visit!.spend)}</strong></td>
                                             </tr>
                                        </table>
                                   </td>
                              </tr>
                              <tr>
                                   <td style={{ width: "50%" }} className="b-r-0 b-t-0">
                                        <p className="blue-text" style={{ marginTop: "100px" }}>Signature</p>
                                   </td>
                                   <td align="right" className="prize-sec b-l-0 b-t-0">
                                        <p className="blue-text">Total Discount : <strong className="amt-tag">{helpers.toCurrency(customer!.country!, visit!.discount)}</strong></p>
                                        <p className="blue-text">Sub total after Discount : <strong className="amt-tag">{helpers.toCurrency(customer!.country!, visit!.spend - visit!.discount)}</strong></p>
                                        <p className="blue-text">Tax : <strong className="amt-tag">{helpers.toCurrency(customer!.country!, visit!.tax)}</strong></p>
                                        <p className="blue-text net-amt"><span>Net Amount: <strong>{helpers.toCurrency(customer!.country!, visit!.paymentAmount)}</strong></span></p>
                                   </td>
                              </tr>
                              <tr>
                                   <td colSpan={2} align="center" style={{ background: "#605c5c" }}>
                                        <p className="ft-text">Powered by Infopoint</p>
                                   </td>
                              </tr>
                         </table>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          business: state.session.selectedBusiness,
          branch: state.session.selectedBranch,
          customer: state.customer.customer,
          visit: state.session.selectedVisit
     }),
     component: InvoicePage
});