import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import { setCountryCodeData, setCustomerBlockData, setMobileNumberData, setSessionBlockData } from '../data/dataApi';

interface RedirectToLoginProps {
  setIsLoggedIn: Function;
  setCustomer: Function;
  setSession: Function;
}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ setIsLoggedIn, setCustomer, setSession }) => {
  const ionRouterContext = useContext(IonRouterContext);
  useEffect(() => {
    setIsLoggedIn(false);
    setCustomerBlockData(undefined);
    setCustomer(undefined);
    setSession(undefined);
    setSessionBlockData(undefined, undefined, undefined);
    setCountryCodeData(undefined);
    setMobileNumberData(undefined);
    ionRouterContext.push('/')
  }, [setIsLoggedIn, setCustomer, setSession, ionRouterContext]);
  return null;
};

export default RedirectToLogin;