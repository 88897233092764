import React, { useEffect, useState } from 'react';
import * as helpers from '../util/helpers';
import './Cart.css';
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { connect } from '../data/connect';
import { setLoading, setSelectedVisit } from '../data/session/session.actions';
import { RouteComponentProps, useLocation } from 'react-router';
import { CustomerVisit, ICustomerVisit } from '../models/Base/CustomerVisit';
import { setToken } from '../data/dataApi';
import { CartItem } from '../models/CartItem';
import { GUID } from '../models/GUID';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
     selectedVisit?: ICustomerVisit;
}

interface DispatchProps {
     setLoading: typeof setLoading;
     setSelectedVisit: typeof setSelectedVisit;
}

interface CartProps extends OwnProps, StateProps, DispatchProps { }

const Cart: React.FC<CartProps> = ({ selectedVisit, setSelectedVisit }) => {

     const [country, setCountry] = useState('');
     const [logoUrl, setLogoUrl] = useState('');
     const [businessName, setBusinessName] = useState('');
     let query = useQuery();

     function useQuery() {
          return new URLSearchParams(useLocation().search);
     }

     useEffect(() => {
          selectedVisit = new CustomerVisit();
          selectedVisit.bookingRef = new GUID().toString();
          selectedVisit.businessId = query.get("bid")!;
          selectedVisit.branchId = query.get("brid")!;
          selectedVisit.customerId = '';
          selectedVisit.id = '';
          selectedVisit.serviceItems = [];
          setSelectedVisit(selectedVisit);
          window.addEventListener("message", async (event) => {
               if (selectedVisit && selectedVisit.serviceItems && isCartItem(event.data)) {                    
                    var cartItem = event.data as CartItem;
                    if (cartItem.remove === false) {
                         selectedVisit.serviceItems.push(CartItem.getServiceItem(cartItem));
                    } else {
                         selectedVisit.serviceItems = selectedVisit.serviceItems.filter(obj => obj.productId != cartItem.productId);
                    }
                    setSelectedVisit(selectedVisit);
               }
          }, false);
          var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
          tz.toLowerCase().indexOf('america') >= 0 ? setCountry("US") : setCountry("IN");
          const token = query.get("token");
          if (token) {
               setToken(token);
          }
          setLogoUrl("https://infopointblobstorage.blob.core.windows.net/logo-images/" + selectedVisit.businessId + ".jpg");
          const bname = query.get("bname");
          if (bname) {
               setBusinessName(bname);
          }
     }, []);

     function isCartItem(obj: any): obj is CartItem {
          return obj.sequence !== undefined
     }

     const pay = () => {
          if (selectedVisit && selectedVisit.serviceItems && selectedVisit.serviceItems.length > 0) {               
               window.parent.postMessage(selectedVisit, "*");
          }
     }

     return (
          <div className="bg">
               <div className="cart-items">
                    <div>
                         <div className="mt-2 bb-1 text-center header-sec">
                              <div className="prof-sec">
                                   <img src={logoUrl} />
                              </div>
                              <h3>{businessName}</h3>
                         </div>
                         <div className="cart-side-bar">
                              <IonGrid className="prod-list cartList">
                                   {selectedVisit && selectedVisit.serviceItems && selectedVisit.serviceItems.map((item) => (
                                        <IonRow key={item.sequence}>
                                             <IonCol>
                                                  <label className="prod-list-item ">
                                                       <div className="prod-div">
                                                            <div className="flex">
                                                                 <h2 className="prod-title flex-2">
                                                                      {item.quantity > 1 && <span>{item.quantity} x {item.description}</span>}
                                                                      {item.quantity <= 1 && <span>{item.description}</span>}
                                                                      <p className="prod-date">{item.serviceTime}</p>
                                                                 </h2>
                                                                 <h1>
                                                                      <span className="current-price">{helpers.toCurrency(country!, item.price)}</span>
                                                                      {item.retailPrice > item.price && <span className="old-price">{helpers.toCurrency(country!, item.retailPrice)}</span>}
                                                                 </h1>
                                                            </div>
                                                       </div>
                                                  </label>
                                             </IonCol>
                                        </IonRow>
                                   ))}
                              </IonGrid>
                         </div>
                         <div className="cart-footer">
                              <ul className="prod-list">
                                   <li>
                                        <label className="prod-list-item ">
                                             <div className="prod-div">
                                                  <div className="flex">
                                                       <h2 className="prod-title flex-2">
                                                            <strong>Total</strong>
                                                       </h2>
                                                       {selectedVisit && selectedVisit.serviceItems && <h1>
                                                            <strong className="current-price">{helpers.toCurrency(country!, selectedVisit.serviceItems.map(item => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0))}</strong>
                                                            <p className="total-services">{selectedVisit.serviceItems.length} Services</p>
                                                       </h1>}
                                                  </div>
                                             </div>
                                        </label>
                                   </li>
                              </ul>
                         </div>
                         <div className="text-center">
                              <button className="buynow" onClick={pay}>Book</button>
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          selectedVisit: state.session.selectedVisit
     }),
     mapDispatchToProps: {
          setLoading,
          setSelectedVisit
     },
     component: Cart
})
