import { CustomerStatus, PaymentMethodTypes } from "./Enums";
import { StorageBase, IStorageBase } from "./StorageBase";

export class Customer extends StorageBase implements ICustomer {
     firstName?: string | undefined;
     middleName?: string | undefined;
     lastName?: string | undefined;
     namePrefix?: string | undefined;
     nameSuffix?: string | undefined;
     userName?: string | undefined;
     homeLocation?: string | undefined;
     address?: string | undefined;
     addressLine2?: string | undefined;
     addressLine3?: string | undefined;
     dob!: Date;
     gender?: string | undefined;
     licenseNumber?: string | undefined;
     city?: string | undefined;
     locality?: string | undefined;
     county?: string | undefined;
     state?: string | undefined;
     zipCode?: string | undefined;
     country?: string | undefined;
     latitude!: number;
     longitude!: number;
     emailId?: string | undefined;
     countryCode?: string | undefined;
     mobileNumber?: string | undefined;
     devicePlatform?: string | undefined;
     deviceManufacturer?: string | undefined;
     deviceModel?: string | undefined;
     deviceName?: string | undefined;
     deviceVersion?: string | undefined;
     pictureURL?: string | undefined;
     language?: string | undefined;
     isBusiness!: boolean;
     createdByBusiness!: boolean;
     accessedByInfopass!: boolean;
     autoPayEnabled!: boolean;
     creditEnabled!: boolean;
     creditLimit!: number;
     companyName?: string | undefined;
     registrationId?: string | undefined;
     paymentAccountId?: string | undefined;
     paymentAccountDT?: Date | undefined;
     paymentMethods?: PaymentMethod[] | undefined;
     paymentMethodsLocal?: string | undefined;
     pushNotificationRegistered!: boolean;
     pushNotificationTag?: string | undefined;
     status!: CustomerStatus;
 
     constructor(data?: ICustomer) {
         super(data);
     }
 
     init(_data?: any) {
         super.init(_data);
         if (_data) {
             this.firstName = _data["FirstName"];
             this.middleName = _data["MiddleName"];
             this.lastName = _data["LastName"];
             this.namePrefix = _data["NamePrefix"];
             this.nameSuffix = _data["NameSuffix"];
             this.userName = _data["UserName"];
             this.homeLocation = _data["HomeLocation"];
             this.address = _data["Address"];
             this.addressLine2 = _data["AddressLine2"];
             this.addressLine3 = _data["AddressLine3"];
             this.dob = _data["Dob"] ? new Date(_data["Dob"].toString()) : <any>undefined;
             this.gender = _data["Gender"];
             this.licenseNumber = _data["LicenseNumber"];
             this.city = _data["City"];
             this.locality = _data["Locality"];
             this.county = _data["County"];
             this.state = _data["State"];
             this.zipCode = _data["ZipCode"];
             this.country = _data["Country"];
             this.latitude = _data["Latitude"];
             this.longitude = _data["Longitude"];
             this.emailId = _data["EmailId"];
             this.countryCode = _data["CountryCode"];
             this.mobileNumber = _data["MobileNumber"];
             this.devicePlatform = _data["DevicePlatform"];
             this.deviceManufacturer = _data["DeviceManufacturer"];
             this.deviceModel = _data["DeviceModel"];
             this.deviceName = _data["DeviceName"];
             this.deviceVersion = _data["DeviceVersion"];
             this.pictureURL = _data["PictureURL"];
             this.language = _data["Language"];
             this.isBusiness = _data["IsBusiness"];
             this.createdByBusiness = _data["CreatedByBusiness"];
             this.accessedByInfopass = _data["AccessedByInfopass"];
             this.autoPayEnabled = _data["AutoPayEnabled"];
             this.creditEnabled = _data["CreditEnabled"];
             this.creditLimit = _data["CreditLimit"];
             this.companyName = _data["CompanyName"];
             this.registrationId = _data["RegistrationId"];
             this.paymentAccountId = _data["PaymentAccountId"];
             this.paymentAccountDT = _data["PaymentAccountDT"] ? new Date(_data["PaymentAccountDT"].toString()) : <any>undefined;
             if (Array.isArray(_data["PaymentMethods"])) {
                 this.paymentMethods = [] as any;
                 for (let item of _data["PaymentMethods"])
                     this.paymentMethods!.push(PaymentMethod.fromJS(item));
             }
             this.paymentMethodsLocal = _data["PaymentMethodsLocal"];
             this.pushNotificationRegistered = _data["PushNotificationRegistered"];
             this.pushNotificationTag = _data["PushNotificationTag"];
             this.status = _data["Status"];
         }
     }
 
     static fromJS(data: any): Customer {
         data = typeof data === 'object' ? data : {};
         let result = new Customer();
         result.init(data);
         return result;
     }
 
     toJSON(data?: any) {
         data = typeof data === 'object' ? data : {};
         data["FirstName"] = this.firstName;
         data["MiddleName"] = this.middleName;
         data["LastName"] = this.lastName;
         data["NamePrefix"] = this.namePrefix;
         data["NameSuffix"] = this.nameSuffix;
         data["UserName"] = this.userName;
         data["HomeLocation"] = this.homeLocation;
         data["Address"] = this.address;
         data["AddressLine2"] = this.addressLine2;
         data["AddressLine3"] = this.addressLine3;
         data["Dob"] = this.dob ? this.dob.toISOString() : <any>undefined;
         data["Gender"] = this.gender;
         data["LicenseNumber"] = this.licenseNumber;
         data["City"] = this.city;
         data["Locality"] = this.locality;
         data["County"] = this.county;
         data["State"] = this.state;
         data["ZipCode"] = this.zipCode;
         data["Country"] = this.country;
         data["Latitude"] = this.latitude;
         data["Longitude"] = this.longitude;
         data["EmailId"] = this.emailId;
         data["CountryCode"] = this.countryCode;
         data["MobileNumber"] = this.mobileNumber;
         data["DevicePlatform"] = this.devicePlatform;
         data["DeviceManufacturer"] = this.deviceManufacturer;
         data["DeviceModel"] = this.deviceModel;
         data["DeviceName"] = this.deviceName;
         data["DeviceVersion"] = this.deviceVersion;
         data["PictureURL"] = this.pictureURL;
         data["Language"] = this.language;
         data["IsBusiness"] = this.isBusiness;
         data["CreatedByBusiness"] = this.createdByBusiness;
         data["AccessedByInfopass"] = this.accessedByInfopass;
         data["AutoPayEnabled"] = this.autoPayEnabled;
         data["CreditEnabled"] = this.creditEnabled;
         data["CreditLimit"] = this.creditLimit;
         data["CompanyName"] = this.companyName;
         data["RegistrationId"] = this.registrationId;
         data["PaymentAccountId"] = this.paymentAccountId;
         data["PaymentAccountDT"] = this.paymentAccountDT ? this.paymentAccountDT.toISOString() : <any>undefined;
         if (Array.isArray(this.paymentMethods)) {
             data["PaymentMethods"] = [];
             for (let item of this.paymentMethods)
                 data["PaymentMethods"].push(item.toJSON());
         }
         data["PaymentMethodsLocal"] = this.paymentMethodsLocal;
         data["PushNotificationRegistered"] = this.pushNotificationRegistered;
         data["PushNotificationTag"] = this.pushNotificationTag;
         data["Status"] = this.status;
         super.toJSON(data);
         return data; 
     }
 }
 
 export interface ICustomer extends IStorageBase {
     firstName?: string | undefined;
     middleName?: string | undefined;
     lastName?: string | undefined;
     namePrefix?: string | undefined;
     nameSuffix?: string | undefined;
     userName?: string | undefined;
     homeLocation?: string | undefined;
     address?: string | undefined;
     addressLine2?: string | undefined;
     addressLine3?: string | undefined;
     dob: Date;
     gender?: string | undefined;
     licenseNumber?: string | undefined;
     city?: string | undefined;
     locality?: string | undefined;
     county?: string | undefined;
     state?: string | undefined;
     zipCode?: string | undefined;
     country?: string | undefined;
     latitude: number;
     longitude: number;
     emailId?: string | undefined;
     countryCode?: string | undefined;
     mobileNumber?: string | undefined;
     devicePlatform?: string | undefined;
     deviceManufacturer?: string | undefined;
     deviceModel?: string | undefined;
     deviceName?: string | undefined;
     deviceVersion?: string | undefined;
     pictureURL?: string | undefined;
     language?: string | undefined;
     isBusiness: boolean;
     createdByBusiness: boolean;
     accessedByInfopass: boolean;
     autoPayEnabled: boolean;
     creditEnabled: boolean;
     creditLimit: number;
     companyName?: string | undefined;
     registrationId?: string | undefined;
     paymentAccountId?: string | undefined;
     paymentAccountDT?: Date | undefined;
     paymentMethods?: PaymentMethod[] | undefined;
     paymentMethodsLocal?: string | undefined;
     pushNotificationRegistered: boolean;
     pushNotificationTag?: string | undefined;
     status: CustomerStatus;
 }
 
 export class PaymentMethod implements IPaymentMethod {
     name?: string | undefined;
     emailId?: string | undefined;
     address?: string | undefined;
     addressLine1?: string | undefined;
     addressLine2?: string | undefined;
     addressLine3?: string | undefined;
     city?: string | undefined;
     county?: string | undefined;
     country?: string | undefined;
     latitude!: number;
     longitude!: number;
     locality?: string | undefined;
     state?: string | undefined;
     zipCode?: string | undefined;
     type!: PaymentMethodTypes;
     intentId?: string | undefined;
     methodId?: string | undefined;
     methodName?: string | undefined;
     network?: string | undefined;
     funding?: string | undefined;
     last4?: string | undefined;
     expMonth!: number;
     expYear!: number;
     default!: boolean;
 
     constructor(data?: IPaymentMethod) {
         if (data) {
             for (var property in data) {
                 if (data.hasOwnProperty(property))
                     (<any>this)[property] = (<any>data)[property];
             }
         }
     }
 
     init(_data?: any) {
         if (_data) {
             this.name = _data["Name"];
             this.emailId = _data["EmailId"];
             this.address = _data["Address"];
             this.addressLine1 = _data["AddressLine1"];
             this.addressLine2 = _data["AddressLine2"];
             this.addressLine3 = _data["AddressLine3"];
             this.city = _data["City"];
             this.county = _data["County"];
             this.country = _data["Country"];
             this.latitude = _data["Latitude"];
             this.longitude = _data["Longitude"];
             this.locality = _data["Locality"];
             this.state = _data["State"];
             this.zipCode = _data["ZipCode"];
             this.type = _data["Type"];
             this.intentId = _data["IntentId"];
             this.methodId = _data["MethodId"];
             this.methodName = _data["MethodName"];
             this.network = _data["Network"];
             this.funding = _data["Funding"];
             this.last4 = _data["Last4"];
             this.expMonth = _data["ExpMonth"];
             this.expYear = _data["ExpYear"];
             this.default = _data["Default"];
         }
     }
 
     static fromJS(data: any): PaymentMethod {
         data = typeof data === 'object' ? data : {};
         let result = new PaymentMethod();
         result.init(data);
         return result;
     }
 
     toJSON(data?: any) {
         data = typeof data === 'object' ? data : {};
         data["Name"] = this.name;
         data["EmailId"] = this.emailId;
         data["Address"] = this.address;
         data["AddressLine1"] = this.addressLine1;
         data["AddressLine2"] = this.addressLine2;
         data["AddressLine3"] = this.addressLine3;
         data["City"] = this.city;
         data["County"] = this.county;
         data["Country"] = this.country;
         data["Latitude"] = this.latitude;
         data["Longitude"] = this.longitude;
         data["Locality"] = this.locality;
         data["State"] = this.state;
         data["ZipCode"] = this.zipCode;
         data["Type"] = this.type;
         data["IntentId"] = this.intentId;
         data["MethodId"] = this.methodId;
         data["MethodName"] = this.methodName;
         data["Network"] = this.network;
         data["Funding"] = this.funding;
         data["Last4"] = this.last4;
         data["ExpMonth"] = this.expMonth;
         data["ExpYear"] = this.expYear;
         data["Default"] = this.default;
         return data; 
     }
 }
 
 export interface IPaymentMethod {
     name?: string | undefined;
     emailId?: string | undefined;
     address?: string | undefined;
     addressLine1?: string | undefined;
     addressLine2?: string | undefined;
     addressLine3?: string | undefined;
     city?: string | undefined;
     county?: string | undefined;
     country?: string | undefined;
     latitude: number;
     longitude: number;
     locality?: string | undefined;
     state?: string | undefined;
     zipCode?: string | undefined;
     type: PaymentMethodTypes;
     intentId?: string | undefined;
     methodId?: string | undefined;
     methodName?: string | undefined;
     network?: string | undefined;
     funding?: string | undefined;
     last4?: string | undefined;
     expMonth: number;
     expYear: number;
     default: boolean;
 }