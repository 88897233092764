import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonGrid, IonRow, IonCol, IonBackButton, IonList, IonItem, IonLabel, IonFooter } from '@ionic/react';
import { connect } from '../data/connect';
import * as helpers from '../util/helpers';
import { ServiceItem } from '../models/ServiceItem';
import { ICustomer } from '../models/Base/Customer';
import { ICustomerVisit } from '../models/Base/CustomerVisit';

interface OwnProps { };

interface StateProps {
     customer?: ICustomer;
     visit?: ICustomerVisit;
};

interface DispatchProps { };

interface VisitDetailsProps extends OwnProps, StateProps, DispatchProps { };

const VisitDetailsPage: React.FC<VisitDetailsProps> = ({ customer, visit }) => {

     const [serviceItems, setServiceItems] = useState<ServiceItem[]>([]);

     useEffect(() => {
          if (visit) {
               var sequence = 1;
               visit.serviceItems!.forEach(item => {
                    serviceItems.push(new ServiceItem(sequence++, false, false, item.quantity, item.description!, '', item.price, item.totalPrice));
                    item.discounts!.filter(e => e.orderLevel === false)!.map((discount) => {
                         serviceItems.push(new ServiceItem(sequence++, true, false, discount.quantity, discount.description!, discount.descriptionDetail!, 0, discount.discountTotal * -1));
                    });
               });
               visit.serviceItems!.forEach(item => {
                    item.discounts!.filter(e => e.orderLevel === true)!.map((discount) => {
                         serviceItems.push(new ServiceItem(sequence++, true, false, discount.quantity, discount.description!, discount.descriptionDetail!, 0, discount.discountTotal * -1));
                    });
               });
               if (visit.pointsValue > 0) {
                    serviceItems.push(new ServiceItem(sequence++, true, true, visit.pointsRedeemed, "Points Redeemed", "", 0, visit.pointsValue * -1));
               }
          }
     }, [visit]);

     return (
          <IonPage id="visit-details">
               <IonHeader>
                    <IonToolbar>
                         <IonButtons slot="start">
                              <IonBackButton defaultHref="/wallet/passdetails" />
                         </IonButtons>
                         <IonTitle>{visit!.invoiceNumber + ": " + new Date(visit!.checkOut).toLocaleString()}</IonTitle>
                    </IonToolbar>
               </IonHeader>
               <IonContent>
                    <IonList>
                         {serviceItems!.map((serviceItem) => (
                              <IonItem key={serviceItem.sequence}>
                                   <IonGrid>
                                        <IonRow>
                                             <IonCol size="1" className="ion-text-right blue">
                                                  <IonLabel>{serviceItem.quantity > 0 ? serviceItem.quantity : ''}</IonLabel>
                                             </IonCol>
                                             <IonCol>
                                                  <IonLabel>{serviceItem.description}</IonLabel>
                                                  {serviceItem.descriptionDetail && <IonLabel style={{color:'gray',fontSize:'12px'}}>{serviceItem.descriptionDetail}</IonLabel>}
                                             </IonCol>
                                             {serviceItem.retailPrice > 0 && <IonCol size="2" className="ion-text-right blue">
                                                  <IonLabel style={{color:'royalblue'}}>{helpers.toCurrency(customer!.country!, serviceItem.retailPrice)}</IonLabel>
                                             </IonCol>}
                                             <IonCol size="3" className="ion-text-right green">
                                                  <IonLabel color={serviceItem.totalPrice > 0 ? "success" : "danger"}>{helpers.toCurrency(customer!.country!, serviceItem.totalPrice)}</IonLabel>
                                             </IonCol>
                                        </IonRow>
                                   </IonGrid>
                              </IonItem>
                         ))}
                    </IonList>
               </IonContent>
               <IonFooter>
                    <IonGrid className="ion-margin-end">
                         <IonRow>
                              <IonCol className="ion-text-right">Total</IonCol>
                              <IonCol size="3" className="ion-text-right green">{helpers.toCurrency(customer!.country!, visit!.spend - visit!.discount)}</IonCol>
                         </IonRow>
                         <IonRow>
                              <IonCol className="ion-text-right">Tax</IonCol>
                              <IonCol size="3" className="ion-text-right">{helpers.toCurrency(customer!.country!, visit!.tax)}</IonCol>
                         </IonRow>
                         <IonRow>
                              <IonCol className="ion-text-right">Tips</IonCol>
                              <IonCol size="3" className="ion-text-right">{helpers.toCurrency(customer!.country!, visit!.tip)}</IonCol>
                         </IonRow>
                         <IonRow>
                              <IonCol className="ion-text-right payment-amount blue">{helpers.toCurrency(customer!.country!, visit!.spend - visit!.discount + visit!.tax + visit!.tip)}</IonCol>
                         </IonRow>
                         <IonRow>
                              <IonCol className="ion-text-right">{visit!.cardPayment > 0 ? 'Credit card ending ' + visit!.cardLast4 : visit!.cashPayment > 0 ? 'Cash' : ''}</IonCol>
                              <IonCol size="3" className="ion-text-right red">{helpers.toCurrency(customer!.country!, visit!.cardPayment > 0 ? visit!.cardPayment : visit!.cashPayment > 0 ? visit!.cashPayment : 0)}</IonCol>
                         </IonRow>
                    </IonGrid>
               </IonFooter>
          </IonPage>
     );
};

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          customer: state.customer.customer,
          visit: state.session.selectedVisit
     }),
     component: VisitDetailsPage
});