import { CampaignTypes, FeeTypes, OfferTypes, PaymentMethodTypes, ReservationStatuses, VisitSources } from "./Enums";
import { StorageBase, IStorageBase } from "./StorageBase";

export class CustomerVisit extends StorageBase implements ICustomerVisit {
    customerId?: string | undefined;
    customerName?: string | undefined;
    customerEmailId?: string | undefined;
    customerCountryCode?: string | undefined;
    customerMobileNumber?: string | undefined;
    branchId?: string | undefined;
    checkIn!: Date;
    checkInCode?: string | undefined;
    checkInUser?: string | undefined;
    checkInUsername?: string | undefined;
    checkOut!: Date;
    checkOutUser?: string | undefined;
    checkOutUsername?: string | undefined;
    checkOutTerminalId?: string | undefined;
    bookingRef?: string | undefined;
    serviceTime!: number;
    serviceQty!: number;
    serviceType?: string | undefined;
    serviceDesc?: string | undefined;
    cost!: number;
    spend!: number;
    discount!: number;
    tax!: number;
    tip!: number;
    fees!: number;
    basePoints!: number;
    bonusPoints!: number;
    pointsRedeemed!: number;
    pointsValue!: number;
    pointsExpiry!: Date;
    includeFees!: boolean;
    isBusiness!: boolean;
    paid!: boolean;
    paidDT!: Date;
    paymentAmount!: number;
    paidAmount!: number;
    refundAmount!: number;
    refundReason?: string | undefined;
    upiPayment!: number;
    cashPayment!: number;
    cardPayment!: number;
    cardNetwork?: string | undefined;
    cardLast4?: string | undefined;
    paymentId?: string | undefined;
    paymentDesc?: string | undefined;
    paymentReceiptUrl?: string | undefined;
    invoiceNumber?: string | undefined;
    payoutId?: string | undefined;
    payoutDT!: Date;
    payoutAmount!: number;
    reimbursedAmount!: number;
    taxFree!: boolean;
    comments?: string | undefined;
    receiptEmailId?: string | undefined;
    receiptMobileNumber?: string | undefined;
    receiptCustomerName?: string | undefined;
    payLaterFinalize!: boolean;
    payLaterAuthId?: string | undefined;
    payLaterSourceId?: string | undefined;
    payLaterChargeId?: string | undefined;
    payLaterStatus?: string | undefined;
    feeMethod?: FeeMethod | undefined;
    feeMethodLocal?: string | undefined;
    serviceItems?: ServiceItem[] | undefined;
    serviceItemsLocal?: string | undefined;
    offers?: Discount[] | undefined;
    offersLocal?: string | undefined;
    comps?: Discount[] | undefined;
    compsLocal?: string | undefined;
    reservationDT!: Date;
    reservationCanceledDT!: Date;
    reservationConfirmationDT!: Date;
    reservationConfirmationRequestDT!: Date;
    reservationStatus!: ReservationStatuses;
    reservationCode?: string | undefined;
    reservationByUserId?: string | undefined;
    reservationByUsername?: string | undefined;
    reservationForUserId?: string | undefined;
    reservationForUsername?: string | undefined;
    source!: VisitSources;
    shippingFromAddress?: ShippingAddress | undefined;
    shippingToAddress?: ShippingAddress | undefined;


    constructor(data?: ICustomerVisit) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.customerId = _data["CustomerId"];
            this.customerName = _data["CustomerName"];
            this.customerEmailId = _data["CustomerEmailId"];
            this.customerCountryCode = _data["CustomerCountryCode"];
            this.customerMobileNumber = _data["CustomerMobileNumber"];
            this.branchId = _data["BranchId"];
            this.checkIn = _data["CheckIn"] ? new Date(_data["CheckIn"].toString()) : <any>undefined;
            this.checkInCode = _data["CheckInCode"];
            this.checkInUser = _data["CheckInUser"];
            this.checkInUsername = _data["CheckInUsername"];
            this.checkOut = _data["CheckOut"] ? new Date(_data["CheckOut"].toString()) : <any>undefined;
            this.checkOutUser = _data["CheckOutUser"];
            this.checkOutUsername = _data["CheckOutUsername"];
            this.checkOutTerminalId = _data["CheckOutTerminalId"];
            this.bookingRef = _data["BookingRef"];
            this.serviceTime = _data["ServiceTime"];
            this.serviceQty = _data["ServiceQty"];
            this.serviceType = _data["ServiceType"];
            this.serviceDesc = _data["ServiceDesc"];
            this.cost = _data["Cost"];
            this.spend = _data["Spend"];
            this.discount = _data["Discount"];
            this.tax = _data["Tax"];
            this.tip = _data["Tip"];
            this.fees = _data["Fees"];
            this.basePoints = _data["BasePoints"];
            this.bonusPoints = _data["BonusPoints"];
            this.pointsRedeemed = _data["PointsRedeemed"];
            this.pointsValue = _data["PointsValue"];
            this.pointsExpiry = _data["PointsExpiry"] ? new Date(_data["PointsExpiry"].toString()) : <any>undefined;
            this.includeFees = _data["IncludeFees"];
            this.isBusiness = _data["IsBusiness"];
            this.paid = _data["Paid"];
            this.paidDT = _data["PaidDT"] ? new Date(_data["PaidDT"].toString()) : <any>undefined;
            this.paymentAmount = _data["PaymentAmount"];
            this.paidAmount = _data["PaidAmount"];
            this.refundAmount = _data["RefundAmount"];
            this.refundReason = _data["RefundReason"];
            this.upiPayment = _data["UpiPayment"];
            this.cashPayment = _data["CashPayment"];
            this.cardPayment = _data["CardPayment"];
            this.cardNetwork = _data["CardNetwork"];
            this.cardLast4 = _data["CardLast4"];
            this.paymentId = _data["PaymentId"];
            this.paymentDesc = _data["PaymentDesc"];
            this.paymentReceiptUrl = _data["PaymentReceiptUrl"];
            this.invoiceNumber = _data["InvoiceNumber"];
            this.payoutId = _data["PayoutId"];
            this.payoutDT = _data["PayoutDT"] ? new Date(_data["PayoutDT"].toString()) : <any>undefined;
            this.payoutAmount = _data["PayoutAmount"];
            this.reimbursedAmount = _data["ReimbursedAmount"];
            this.taxFree = _data["TaxFree"];
            this.comments = _data["Comments"];
            this.receiptEmailId = _data["ReceiptEmailId"];
            this.receiptMobileNumber = _data["ReceiptMobileNumber"];
            this.receiptCustomerName = _data["ReceiptCustomerName"];
            this.payLaterFinalize = _data["PayLaterFinalize"];
            this.payLaterAuthId = _data["PayLaterAuthId"];
            this.payLaterSourceId = _data["PayLaterSourceId"];
            this.payLaterChargeId = _data["PayLaterChargeId"];
            this.payLaterStatus = _data["PayLaterStatus"];
            this.feeMethod = _data["FeeMethod"] ? FeeMethod.fromJS(_data["FeeMethod"]) : <any>undefined;
            this.feeMethodLocal = _data["FeeMethodLocal"];
            if (Array.isArray(_data["ServiceItems"])) {
                this.serviceItems = [] as any;
                for (let item of _data["ServiceItems"])
                    this.serviceItems!.push(ServiceItem.fromJS(item));
            }
            this.serviceItemsLocal = _data["ServiceItemsLocal"];
            if (Array.isArray(_data["Offers"])) {
                this.offers = [] as any;
                for (let item of _data["Offers"])
                    this.offers!.push(Discount.fromJS(item));
            }
            this.offersLocal = _data["OffersLocal"];
            if (Array.isArray(_data["Comps"])) {
                this.comps = [] as any;
                for (let item of _data["Comps"])
                    this.comps!.push(Discount.fromJS(item));
            }
            this.compsLocal = _data["CompsLocal"];
            this.reservationDT = _data["ReservationDT"] ? new Date(_data["ReservationDT"].toString()) : <any>undefined;
            this.reservationCanceledDT = _data["ReservationCanceledDT"] ? new Date(_data["ReservationCanceledDT"].toString()) : <any>undefined;
            this.reservationConfirmationDT = _data["ReservationConfirmationDT"] ? new Date(_data["ReservationConfirmationDT"].toString()) : <any>undefined;
            this.reservationConfirmationRequestDT = _data["ReservationConfirmationRequestDT"] ? new Date(_data["ReservationConfirmationRequestDT"].toString()) : <any>undefined;
            this.reservationStatus = _data["ReservationStatus"];
            this.reservationCode = _data["ReservationCode"];
            this.reservationByUserId = _data["ReservationByUserId"];
            this.reservationByUsername = _data["ReservationByUsername"];
            this.reservationForUserId = _data["ReservationForUserId"];
            this.reservationForUsername = _data["ReservationForUsername"];
            this.source = _data["Source"];
            this.shippingFromAddress = _data["ShippingFromAddress"] ? ShippingAddress.fromJS(_data["ShippingFromAddress"]) : <any>undefined;
            this.shippingToAddress = _data["ShippingToAddress"] ? ShippingAddress.fromJS(_data["ShippingToAddress"]) : <any>undefined;
        }
    }

    static fromJS(data: any): CustomerVisit {
        data = typeof data === 'object' ? data : {};
        let result = new CustomerVisit();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["CustomerId"] = this.customerId;
        data["CustomerName"] = this.customerName;
        data["CustomerEmailId"] = this.customerEmailId;
        data["CustomerCountryCode"] = this.customerCountryCode;
        data["CustomerMobileNumber"] = this.customerMobileNumber;
        data["BranchId"] = this.branchId;
        data["CheckIn"] = this.checkIn ? this.checkIn.toISOString() : <any>undefined;
        data["CheckInCode"] = this.checkInCode;
        data["CheckInUser"] = this.checkInUser;
        data["CheckInUsername"] = this.checkInUsername;
        data["CheckOut"] = this.checkOut ? this.checkOut.toISOString() : <any>undefined;
        data["CheckOutUser"] = this.checkOutUser;
        data["CheckOutUsername"] = this.checkOutUsername;
        data["CheckOutTerminalId"] = this.checkOutTerminalId;
        data["BookingRef"] = this.bookingRef;
        data["ServiceTime"] = this.serviceTime;
        data["ServiceQty"] = this.serviceQty;
        data["ServiceType"] = this.serviceType;
        data["ServiceDesc"] = this.serviceDesc;
        data["Cost"] = this.cost;
        data["Spend"] = this.spend;
        data["Discount"] = this.discount;
        data["Tax"] = this.tax;
        data["Tip"] = this.tip;
        data["Fees"] = this.fees;
        data["BasePoints"] = this.basePoints;
        data["BonusPoints"] = this.bonusPoints;
        data["PointsRedeemed"] = this.pointsRedeemed;
        data["PointsValue"] = this.pointsValue;
        data["PointsExpiry"] = this.pointsExpiry ? this.pointsExpiry.toISOString() : <any>undefined;
        data["IncludeFees"] = this.includeFees;
        data["IsBusiness"] = this.isBusiness;
        data["Paid"] = this.paid;
        data["PaidDT"] = this.paidDT ? this.paidDT.toISOString() : <any>undefined;
        data["PaymentAmount"] = this.paymentAmount;
        data["PaidAmount"] = this.paidAmount;
        data["RefundAmount"] = this.refundAmount;
        data["RefundReason"] = this.refundReason;
        data["UpiPayment"] = this.upiPayment;
        data["CashPayment"] = this.cashPayment;
        data["CardPayment"] = this.cardPayment;
        data["CardNetwork"] = this.cardNetwork;
        data["CardLast4"] = this.cardLast4;
        data["PaymentId"] = this.paymentId;
        data["PaymentDesc"] = this.paymentDesc;
        data["PaymentReceiptUrl"] = this.paymentReceiptUrl;
        data["InvoiceNumber"] = this.invoiceNumber;
        data["PayoutId"] = this.payoutId;
        data["PayoutDT"] = this.payoutDT ? this.payoutDT.toISOString() : <any>undefined;
        data["PayoutAmount"] = this.payoutAmount;
        data["ReimbursedAmount"] = this.reimbursedAmount;
        data["TaxFree"] = this.taxFree;
        data["Comments"] = this.comments;
        data["ReceiptEmailId"] = this.receiptEmailId;
        data["ReceiptMobileNumber"] = this.receiptMobileNumber;
        data["ReceiptCustomerName"] = this.receiptCustomerName;
        data["PayLaterFinalize"] = this.payLaterFinalize;
        data["PayLaterAuthId"] = this.payLaterAuthId;
        data["PayLaterSourceId"] = this.payLaterSourceId;
        data["PayLaterChargeId"] = this.payLaterChargeId;
        data["PayLaterStatus"] = this.payLaterStatus;
        data["FeeMethod"] = this.feeMethod ? this.feeMethod.toJSON() : <any>undefined;
        data["FeeMethodLocal"] = this.feeMethodLocal;
        if (Array.isArray(this.serviceItems)) {
            data["ServiceItems"] = [];
            for (let item of this.serviceItems)
                data["ServiceItems"].push(item.toJSON());
        }
        data["ServiceItemsLocal"] = this.serviceItemsLocal;
        if (Array.isArray(this.offers)) {
            data["Offers"] = [];
            for (let item of this.offers)
                data["Offers"].push(item.toJSON());
        }
        data["OffersLocal"] = this.offersLocal;
        if (Array.isArray(this.comps)) {
            data["Comps"] = [];
            for (let item of this.comps)
                data["Comps"].push(item.toJSON());
        }
        data["CompsLocal"] = this.compsLocal;
        data["ReservationDT"] = this.reservationDT ? this.reservationDT.toISOString() : <any>undefined;
        data["ReservationCanceledDT"] = this.reservationCanceledDT ? this.reservationCanceledDT.toISOString() : <any>undefined;
        data["ReservationConfirmationDT"] = this.reservationConfirmationDT ? this.reservationConfirmationDT.toISOString() : <any>undefined;
        data["ReservationConfirmationRequestDT"] = this.reservationConfirmationRequestDT ? this.reservationConfirmationRequestDT.toISOString() : <any>undefined;
        data["ReservationStatus"] = this.reservationStatus;
        data["ReservationCode"] = this.reservationCode;
        data["ReservationByUserId"] = this.reservationByUserId;
        data["ReservationByUsername"] = this.reservationByUsername;
        data["ReservationForUserId"] = this.reservationForUserId;
        data["ReservationForUsername"] = this.reservationForUsername;
        data["ShippingFromAddress"] = this.shippingFromAddress ? this.shippingFromAddress.toJSON() : <any>undefined;
        data["ShippingToAddress"] = this.shippingToAddress ? this.shippingToAddress.toJSON() : <any>undefined;
        super.toJSON(data);
        return data;
    }
}

export interface ICustomerVisit extends IStorageBase {
    customerId?: string | undefined;
    customerName?: string | undefined;
    customerEmailId?: string | undefined;
    customerCountryCode?: string | undefined;
    customerMobileNumber?: string | undefined;
    branchId?: string | undefined;
    checkIn: Date;
    checkInCode?: string | undefined;
    checkInUser?: string | undefined;
    checkInUsername?: string | undefined;
    checkOut: Date;
    checkOutUser?: string | undefined;
    checkOutUsername?: string | undefined;
    checkOutTerminalId?: string | undefined;
    bookingRef?: string | undefined;
    serviceTime: number;
    serviceQty: number;
    serviceType?: string | undefined;
    serviceDesc?: string | undefined;
    cost: number;
    spend: number;
    discount: number;
    tax: number;
    tip: number;
    fees: number;
    basePoints: number;
    bonusPoints: number;
    pointsRedeemed: number;
    pointsValue: number;
    pointsExpiry: Date;
    includeFees: boolean;
    isBusiness: boolean;
    paid: boolean;
    paidDT: Date;
    paymentAmount: number;
    paidAmount: number;
    refundAmount: number;
    refundReason?: string | undefined;
    upiPayment: number;
    cashPayment: number;
    cardPayment: number;
    cardNetwork?: string | undefined;
    cardLast4?: string | undefined;
    paymentId?: string | undefined;
    paymentDesc?: string | undefined;
    paymentReceiptUrl?: string | undefined;
    invoiceNumber?: string | undefined;
    payoutId?: string | undefined;
    payoutDT: Date;
    payoutAmount: number;
    reimbursedAmount: number;
    taxFree: boolean;
    comments?: string | undefined;
    receiptEmailId?: string | undefined;
    receiptMobileNumber?: string | undefined;
    receiptCustomerName?: string | undefined;
    payLaterFinalize: boolean;
    payLaterAuthId?: string | undefined;
    payLaterSourceId?: string | undefined;
    payLaterChargeId?: string | undefined;
    payLaterStatus?: string | undefined;
    feeMethod?: FeeMethod | undefined;
    feeMethodLocal?: string | undefined;
    serviceItems?: ServiceItem[] | undefined;
    serviceItemsLocal?: string | undefined;
    offers?: Discount[] | undefined;
    offersLocal?: string | undefined;
    comps?: Discount[] | undefined;
    compsLocal?: string | undefined;
    reservationDT: Date;
    reservationCanceledDT: Date;
    reservationConfirmationDT: Date;
    reservationConfirmationRequestDT: Date;
    reservationStatus: ReservationStatuses;
    reservationCode?: string | undefined;
    reservationByUserId?: string | undefined;
    reservationByUsername?: string | undefined;
    reservationForUserId?: string | undefined;
    reservationForUsername?: string | undefined;
    source: VisitSources;
    shippingFromAddress?: ShippingAddress | undefined;
    shippingToAddress?: ShippingAddress | undefined;
}

export class FeeMethod implements IFeeMethod {
    paymentMethod!: PaymentMethodTypes;
    type!: FeeTypes;
    value!: number;
    constant!: number;
    paidByCustomer!: boolean;
    disabled!: boolean;

    constructor(data?: IFeeMethod) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.paymentMethod = _data["PaymentMethod"];
            this.type = _data["Type"];
            this.value = _data["Value"];
            this.constant = _data["Constant"];
            this.paidByCustomer = _data["PaidByCustomer"];
            this.disabled = _data["Disabled"];
        }
    }

    static fromJS(data: any): FeeMethod {
        data = typeof data === 'object' ? data : {};
        let result = new FeeMethod();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["PaymentMethod"] = this.paymentMethod;
        data["Type"] = this.type;
        data["Value"] = this.value;
        data["Constant"] = this.constant;
        data["PaidByCustomer"] = this.paidByCustomer;
        data["Disabled"] = this.disabled;
        return data;
    }
}

export interface IFeeMethod {
    paymentMethod: PaymentMethodTypes;
    type: FeeTypes;
    value: number;
    constant: number;
    paidByCustomer: boolean;
    disabled: boolean;
}

export class ServiceItem implements IServiceItem {
    sequence!: number;
    productId?: string | undefined;
    productCode?: string | undefined;
    productClass?: string | undefined;
    productDepartment?: string | undefined;
    productFineline?: string | undefined;
    productHSN?: string | undefined;
    description?: string | undefined;
    quantity!: number;
    cost!: number;
    price!: number;
    retailPrice!: number;
    discountTotal!: number;
    refundQuantity!: number;
    refundCost!: number;
    refundPrice!: number;
    refundDiscountTotal!: number;
    refundTotal!: number;
    totalPrice!: number;
    taxPercent!: number;
    taxAmount!: number;
    serviceTime!: number;
    service!: boolean;
    discounts?: Discount[] | undefined;

    constructor(data?: IServiceItem) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.sequence = _data["Sequence"];
            this.productId = _data["ProductId"];
            this.productCode = _data["ProductCode"];
            this.productClass = _data["ProductClass"];
            this.productDepartment = _data["ProductDepartment"];
            this.productFineline = _data["ProductFineline"];
            this.productHSN = _data["ProductHSN"];
            this.description = _data["Description"];
            this.quantity = _data["Quantity"];
            this.cost = _data["Cost"];
            this.price = _data["Price"];
            this.retailPrice = _data["RetailPrice"];
            this.discountTotal = _data["DiscountTotal"];
            this.refundQuantity = _data["RefundQuantity"];
            this.refundCost = _data["RefundCost"];
            this.refundPrice = _data["RefundPrice"];
            this.refundDiscountTotal = _data["RefundDiscountTotal"];
            this.refundTotal = _data["RefundTotal"];
            this.totalPrice = _data["TotalPrice"];
            this.taxPercent = _data["TaxPercent"];
            this.taxAmount = _data["TaxAmount"];
            this.serviceTime = _data["ServiceTime"];
            this.service = _data["Service"];
            if (Array.isArray(_data["Discounts"])) {
                this.discounts = [] as any;
                for (let item of _data["Discounts"])
                    this.discounts!.push(Discount.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ServiceItem {
        data = typeof data === 'object' ? data : {};
        let result = new ServiceItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Sequence"] = this.sequence;
        data["ProductId"] = this.productId;
        data["ProductCode"] = this.productCode;
        data["ProductClass"] = this.productClass;
        data["ProductDepartment"] = this.productDepartment;
        data["ProductFineline"] = this.productFineline;
        data["ProductHSN"] = this.productHSN;
        data["Description"] = this.description;
        data["Quantity"] = this.quantity;
        data["Cost"] = this.cost;
        data["Price"] = this.price;
        data["RetailPrice"] = this.retailPrice;
        data["DiscountTotal"] = this.discountTotal;
        data["RefundQuantity"] = this.refundQuantity;
        data["RefundCost"] = this.refundCost;
        data["RefundPrice"] = this.refundPrice;
        data["RefundDiscountTotal"] = this.refundDiscountTotal;
        data["RefundTotal"] = this.refundTotal;
        data["TotalPrice"] = this.totalPrice;
        data["TaxPercent"] = this.taxPercent;
        data["TaxAmount"] = this.taxAmount;
        data["ServiceTime"] = this.serviceTime;
        data["Service"] = this.service;
        if (Array.isArray(this.discounts)) {
            data["Discounts"] = [];
            for (let item of this.discounts)
                data["Discounts"].push(item.toJSON());
        }
        return data;
    }
}

export interface IShippingAddress {
    fullName?: string | undefined;
    address?: string | undefined;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    stateCode?: string | undefined;
    emailId?: string | undefined;
    phoneNumber?: string | undefined;
    zipCode?: string | undefined;
}

export class ShippingAddress implements IShippingAddress {
    fullName?: string | undefined;
    address?: string | undefined;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    stateCode?: string | undefined;
    emailId?: string | undefined;
    phoneNumber?: string | undefined;
    zipCode?: string | undefined;

    constructor(data?: IShippingAddress) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fullName = _data["FullName"];
            this.address = _data["Address"];
            this.addressLine1 = _data["AddressLine1"];
            this.addressLine2 = _data["AddressLine2"];
            this.city = _data["City"];
            this.state = _data["State"];
            this.stateCode = _data["StateCode"];
            this.emailId = _data["EmailId"];
            this.phoneNumber = _data["PhoneNumber"];
            this.zipCode = _data["ZIPCode"];
        }
    }
    static fromJS(data: any): ShippingAddress {
        data = typeof data === 'object' ? data : {};
        let result = new ShippingAddress();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["FullName"] = this.fullName;
        data["Address"] = this.address;
        data["AddressLine1"] = this.addressLine1;
        data["AddressLine2"] = this.addressLine2;
        data["City"] = this.city;
        data["State"] = this.state;
        data["StateCode"] = this.stateCode;
        data["EmailId"] = this.emailId;
        data["PhoneNumber"] = this.phoneNumber;
        data["ZIPCode"] = this.zipCode;
    }
}


export interface IServiceItem {
    sequence: number;
    productId?: string | undefined;
    productCode?: string | undefined;
    productClass?: string | undefined;
    productDepartment?: string | undefined;
    productFineline?: string | undefined;
    productHSN?: string | undefined;
    description?: string | undefined;
    quantity: number;
    cost: number;
    price: number;
    retailPrice: number;
    discountTotal: number;
    refundQuantity: number;
    refundCost: number;
    refundPrice: number;
    refundDiscountTotal: number;
    refundTotal: number;
    totalPrice: number;
    taxPercent: number;
    taxAmount: number;
    serviceTime: number;
    service: boolean;
    discounts?: Discount[] | undefined;
}

export class Discount implements IDiscount {
    campaignId?: string | undefined;
    campaignType!: CampaignTypes;
    code?: string | undefined;
    description?: string | undefined;
    descriptionDetail?: string | undefined;
    quantity!: number;
    complimentary!: boolean;
    orderLevel!: boolean;
    pointsRedemption!: boolean;
    discountType!: OfferTypes;
    discountValue!: number;
    discountTotal!: number;

    constructor(data?: IDiscount) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.campaignId = _data["CampaignId"];
            this.campaignType = _data["CampaignType"];
            this.code = _data["Code"];
            this.description = _data["Description"];
            this.descriptionDetail = _data["DescriptionDetail"];
            this.quantity = _data["Quantity"];
            this.complimentary = _data["Complimentary"];
            this.orderLevel = _data["OrderLevel"];
            this.pointsRedemption = _data["PointsRedemption"];
            this.discountType = _data["DiscountType"];
            this.discountValue = _data["DiscountValue"];
            this.discountTotal = _data["DiscountTotal"];
        }
    }

    static fromJS(data: any): Discount {
        data = typeof data === 'object' ? data : {};
        let result = new Discount();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["CampaignId"] = this.campaignId;
        data["CampaignType"] = this.campaignType;
        data["Code"] = this.code;
        data["Description"] = this.description;
        data["DescriptionDetail"] = this.descriptionDetail;
        data["Quantity"] = this.quantity;
        data["Complimentary"] = this.complimentary;
        data["OrderLevel"] = this.orderLevel;
        data["PointsRedemption"] = this.pointsRedemption;
        data["DiscountType"] = this.discountType;
        data["DiscountValue"] = this.discountValue;
        data["DiscountTotal"] = this.discountTotal;
        return data;
    }
}

export interface IDiscount {
    campaignId?: string | undefined;
    campaignType: CampaignTypes;
    code?: string | undefined;
    description?: string | undefined;
    descriptionDetail?: string | undefined;
    quantity: number;
    complimentary: boolean;
    orderLevel: boolean;
    pointsRedemption: boolean;
    discountType: OfferTypes;
    discountValue: number;
    discountTotal: number;
}