import { getUserData, setIsLoggedInData, setHasSeenTutorialData, setMobileNumberData, setCountryCodeData, setAutoPayData } from '../dataApi';
import { ActionType } from '../../util/types';
import { UserState } from './user.state';

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  dispatch(setData(data));
  dispatch(setLoading(false));
}

export const setLoading = (isLoading: boolean) => ({
  type: 'set-user-loading',
  isLoading
} as const);

export const setData = (data: Partial<UserState>) => ({
  type: 'set-user-data',
  data
} as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
};

export const setIsLoggedIn = (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(loggedIn);
  return ({
    type: 'set-is-loggedin',
    loggedIn
  } as const)
};

export const setMobileNumber = (mobileNumber: string) => async (dispatch: React.Dispatch<any>) => {
  await setMobileNumberData(mobileNumber);
  return ({
    type: 'set-mobile-number',
    mobileNumber
  } as const)
};

export const setCountryCode = (countryCode: string) => async (dispatch: React.Dispatch<any>) => {
  await setCountryCodeData(countryCode);
  return ({
    type: 'set-country-code',
    countryCode
  } as const)
};

export const setHasSeenTutorial = (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenTutorialData(hasSeenTutorial);
  return ({
    type: 'set-has-seen-tutorial',
    hasSeenTutorial
  } as const);
} 

export const setDarkMode = (darkMode: boolean) => ({
  type: 'set-dark-mode',
  darkMode
} as const);

export const setAutoPay = (autoPay: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setAutoPayData(autoPay);
  return ({
    type: 'set-auto-pay',
    autoPay
  } as const);
} 
export type UserActions =
  | ActionType<typeof setAutoPay>
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setCountryCode>
  | ActionType<typeof setMobileNumber>