import React, { useState } from 'react';
import { useIonViewWillEnter, IonHeader, IonLoading, IonToast, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonList, IonItem, IonLabel, IonInput, IonText, IonFooter, IonGrid, getConfig, IonActionSheet, IonBackButton } from '@ionic/react';
import './LoginPage.scss';
import { setCountryCode, setIsLoggedIn, setMobileNumber } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps, useLocation } from 'react-router';
import { fetchCustomerBlock, setCustomerBlockData } from '../data/dataApi';
import { Geolocation } from '@ionic-native/geolocation';
import { setCustomer, setPasses, setBusinesses, setCampaigns, setBeacons, setPayVisits } from '../data/customer/customer.actions';
import { setBranchId, setBusinessId } from '../data/session/session.actions';
import { ICustomerBlockResponse } from '../models/Service/CustomerBlockResponse';

interface OwnProps extends RouteComponentProps { }

interface Error {
  showError: boolean;
  message?: string;
}

interface StateProps {
  mode: 'ios' | 'md';
}

interface DispatchProps {
  setBranchId: typeof setBranchId;
  setBusinessId: typeof setBusinessId;
  setIsLoggedIn: typeof setIsLoggedIn;
  setCountryCode: typeof setCountryCode;
  setMobileNumber: typeof setMobileNumber;
  setCustomer: typeof setCustomer;
  setPasses: typeof setPasses;
  setPayVisits: typeof setPayVisits
  setBusinesses: typeof setBusinesses;
  setCampaigns: typeof setCampaigns;
  setBeacons: typeof setBeacons;
}

interface LoginPageProps extends OwnProps, StateProps, DispatchProps { }

const LoginPage: React.FC<LoginPageProps> = ({ history, setBranchId, setBusinessId, setIsLoggedIn, setCountryCode, setMobileNumber, setCustomer, setPasses, setPayVisits, setBusinesses, setBeacons, setCampaigns, mode }) => {
  const ios = mode === 'ios';
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>({ showError: false });
  const [customerBlock, setCustomerBlock] = useState<ICustomerBlockResponse>({});
  const [countryCode, setCountryCodeVal] = useState('');
  const [mobileNumber, setMobileNumberVal] = useState('');
  const [otpVisible, setOTPVisibility] = useState(true);
  const [otp1, setOTP1] = useState('');
  const [otp2, setOTP2] = useState('');
  const [otp3, setOTP3] = useState('');
  const [otp4, setOTP4] = useState('');
  const [otp5, setOTP5] = useState('');
  const [otp6, setOTP6] = useState('');
  const [showCountryPicker, setShowCountryPicker] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const otp1Input = React.useRef<HTMLIonInputElement>(null);
  const otp2Input = React.useRef<HTMLIonInputElement>(null);
  const otp3Input = React.useRef<HTMLIonInputElement>(null);
  const otp4Input = React.useRef<HTMLIonInputElement>(null);
  const otp5Input = React.useRef<HTMLIonInputElement>(null);
  const otp6Input = React.useRef<HTMLIonInputElement>(null);
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useIonViewWillEnter(async () => {
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    tz.toLowerCase().indexOf('america') >= 0 ? setCountryCodeVal("+1") : setCountryCodeVal("+91");
    const bid = query.get("bid");
    if (bid) {
      setBusinessId(bid);
    }
    const brid = query.get("brid");
    if (brid) {
      setBranchId(brid);
    }
  });

  const getLocation = async () => {
    try {
      const position = await Geolocation.getCurrentPosition();
      return position;
    }
    catch (e) {
      setError({ showError: true, message: (e as Error).message });
    }
  }

  const login = async (e: React.FormEvent) => {
    e.preventDefault();

    setFormSubmitted(true);
    if (!mobileNumber) {
      setMobileNumberError(true);
      setErrorMessage('Mobile number is required');
    }

    if (mobileNumber && mobileNumber.length === 10) {
      try {
        setLoading(true);
        var maxLat = 0.0, maxLng = 0.0, minLat = 0.0, minLng = 0.0;
        var location = await getLocation();
        if (location && location.coords) {
          const mapElement = document.createElement("div")
          var map = new google.maps.Map(mapElement, {
            center: {
              lat: location.coords.latitude,
              lng: location.coords.longitude
            },
            zoom: 16
          });
          var circ = new google.maps.Circle({
            center: {
              lat: location.coords.latitude,
              lng: location.coords.longitude
            },
            radius: 100000
          });
          map.fitBounds(circ.getBounds(), 0);
          var sw = circ.getBounds().getSouthWest();
          var ne = circ.getBounds().getNorthEast();
          maxLat = ne.lat();
          maxLng = ne.lng();
          minLat = sw.lat();
          minLng = sw.lng();
        }
        var devicePlatform = ios ? "iOS" : "Android";
        setCustomerBlock(await fetchCustomerBlock(null, devicePlatform, countryCode, mobileNumber, '', true, maxLat, maxLng, minLat, minLng));
        setOTP1('');
        setOTPVisibility(false);
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
        setError({ showError: true, message: (e as Error).message });
      }
    }
  };

  const processMobile = async (e: CustomEvent, val: string) => {
    setMobileNumberError(false);
    setErrorMessage('');
    setMobileNumberVal(val);
    if (val.length === 10) {
      if (otp1Input.current !== null) {
        otp1Input.current.setFocus();
      }
    } else {
      setOTPVisibility(true);
    }
  };

  const processOTP = async (e: CustomEvent, digit: number, val: string) => {
    e.preventDefault();
    if (digit === 1) {
      setMobileNumberError(false);
      setErrorMessage('');
      setOTP6('');
      setOTP5('');
      setOTP4('');
      setOTP3('');
      setOTP2('');
      setOTP1(val);
      if (val.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        return;
      }
      if (otp2Input.current !== null) {
        otp2Input.current.setFocus();
      }
    } else if (digit === 2) {
      setOTP2(val);
      if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        return;
      }
      if (val.length > 0 && otp3Input.current !== null) {
        otp3Input.current.setFocus();
      }
    } else if (digit === 3) {
      setOTP3(val);
      if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        return;
      }
      if (val.length > 0 && otp4Input.current !== null) {
        otp4Input.current.setFocus();
      }
    } else if (digit === 4) {
      setOTP4(val);
      if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        return;
      }
      if (val.length > 0 && otp5Input.current !== null) {
        otp5Input.current.setFocus();
      }
    } else if (digit === 5) {
      setOTP5(val);
      if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        return;
      }
      if (val.length > 0 && otp6Input.current !== null) {
        otp6Input.current.setFocus();
      }
    } else if (digit === 6) {
      setOTP6(val);
      if (otp1.length <= 0 && otp2.length <= 0 && otp3.length <= 0 && otp4.length <= 0 && otp5.length <= 0 && otp5.length <= 0) {
        if (otp1Input.current !== null) {
          otp1Input.current.setFocus();
        }
        return;
      } else {
        var otpString = otp1 + otp2 + otp3 + otp4 + otp5 + val;
        if (customerBlock !== null) {
          if (customerBlock.otp !== otpString) {
            setMobileNumberError(true);
            setErrorMessage('Invalid access code');
            if (otp1Input.current !== null) {
              otp1Input.current.setFocus();
            }
          } else {
            setIsLoggedIn(true);
            setCountryCode(countryCode);
            setMobileNumber(mobileNumber);
            await setCustomerBlockData(customerBlock);
            setBeacons(customerBlock.beacons);
            setBusinesses(customerBlock.businessEntities);
            setCampaigns(customerBlock.campaigns);
            if (customerBlock.customer) {
              setCustomer(customerBlock.customer);
              setPasses(customerBlock.passes);
              setPayVisits(customerBlock.visits!);
              history.replace('/wallet', { direction: 'forward' });
            } else {
              history.push('/signup', { direction: 'forward' });
            }
          }
        } else {

        }
      }
    }
  };

  return (
    <IonPage id="login-page">

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonToast
          isOpen={error.showError}
          position="top"
          onDidDismiss={() => setError({ message: "", showError: false })}
          message={error.message}
          duration={3000}
        />
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Processing...Please wait...'}
        />
        <form noValidate onSubmit={login}>
          <IonGrid>
            <IonRow>
              <IonCol size="10">
                <div style={{ marginTop: "50px" }}>
                  <IonList className="transparent">
                    <div style={{ color: "gray", fontSize: "20px" }}>Enter your mobile number</div>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="4">
                          <IonItem>
                            <IonInput
                              value={countryCode}
                              onClick={e => setShowCountryPicker(true)}
                              readonly >
                            </IonInput>
                            <IonActionSheet
                              isOpen={showCountryPicker}
                              onDidDismiss={() => setShowCountryPicker(false)}
                              buttons={[{
                                text: 'India (+91)',
                                handler: () => {
                                  setCountryCodeVal("+91");
                                }
                              }, {
                                text: 'USA (+1)',
                                handler: () => {
                                  setCountryCodeVal("+1");
                                }
                              }]}
                            >
                            </IonActionSheet>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem>
                            <IonInput name="username"
                              type="tel"
                              inputMode="tel"
                              value={mobileNumber}
                              minlength={10}
                              maxlength={10}
                              spellCheck={false}
                              autocapitalize="off"
                              placeholder="mobile number"
                              onIonChange={async e => await processMobile(e, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonGrid className="ion-margin-start" hidden={otpVisible}>
                      <IonRow>
                        <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                          <IonItem className="ion-no-padding ion-text-center">
                            <IonInput name="otp1"
                              ref={otp1Input}
                              className="ion-no-padding ion-text-center"
                              type="tel"
                              inputMode="numeric"
                              value={otp1}
                              maxlength={1}
                              onIonChange={e => processOTP(e, 1, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                          <IonItem className="ion-no-padding ion-text-center">
                            <IonInput name="otp2"
                              ref={otp2Input}
                              className="ion-no-padding ion-text-center"
                              type="tel"
                              inputMode="numeric"
                              value={otp2}
                              maxlength={1}
                              onIonChange={e => processOTP(e, 2, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                          <IonItem className="ion-no-padding ion-text-center">
                            <IonInput name="otp3"
                              ref={otp3Input}
                              className="ion-no-padding ion-text-center"
                              type="tel"
                              inputMode="numeric"
                              value={otp3}
                              maxlength={1}
                              onIonChange={e => processOTP(e, 3, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                          <IonItem className="ion-no-padding ion-text-center">
                            <IonInput name="otp4"
                              ref={otp4Input}
                              className="ion-no-padding ion-text-center"
                              type="tel"
                              inputMode="numeric"
                              value={otp4}
                              maxlength={1}
                              onIonChange={e => processOTP(e, 4, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                          <IonItem className="ion-no-padding ion-text-center">
                            <IonInput name="otp5"
                              ref={otp5Input}
                              className="ion-no-padding ion-text-center"
                              type="tel"
                              inputMode="numeric"
                              value={otp5}
                              maxlength={1}
                              onIonChange={e => processOTP(e, 5, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="2" className="ion-no-padding ion-text-center p-r-5">
                          <IonItem className="ion-no-padding ion-text-center">
                            <IonInput name="otp6"
                              ref={otp6Input}
                              className="ion-no-padding ion-text-center"
                              type="tel"
                              inputMode="numeric"
                              value={otp6}
                              maxlength={1}
                              onIonChange={e => processOTP(e, 6, e.detail.value!)}
                              required>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {formSubmitted && mobileNumberError && <IonText color="danger">
                      <p className="ion-text-center ion-no-padding ion-no-margin text-xsmall">
                        {errorMessage}
                      </p>
                    </IonText>}
                  </IonList>
                </div>
              </IonCol>

              <IonCol size="2" className="ion-no-padding ion-no-margin">
                <button className="transparent loginbutton" type="submit">
                  <img src="assets/icon/loginbutton.png" alt="login" />
                </button>
              </IonCol>

            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
      <IonFooter className="ion-text-center">
        <IonLabel position="stacked" color="medium">www.infopoint.com</IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
    setBranchId,
    setBusinessId,
    setIsLoggedIn,
    setCountryCode,
    setMobileNumber,
    setCustomer,
    setPasses,
    setPayVisits,
    setBusinesses,
    setCampaigns,
    setBeacons
  },
  component: LoginPage
})