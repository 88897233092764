import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonBackButton } from '@ionic/react';
import { connect } from '../data/connect';
import * as helpers from '../util/helpers';
import './ReceiptPage.scss';
import { ServiceItem } from '../models/ServiceItem';
import { IBusinessEntity, IBranchLocation } from '../models/Base/BusinessEntity';
import { ICustomer } from '../models/Base/Customer';
import { ICustomerVisit } from '../models/Base/CustomerVisit';

interface OwnProps { };

interface StateProps {
     business?: IBusinessEntity;
     branch?: IBranchLocation;
     customer?: ICustomer;
     visit?: ICustomerVisit;
};

interface DispatchProps { };

interface ReceiptPageProps extends OwnProps, StateProps, DispatchProps { };

const ReceiptPage: React.FC<ReceiptPageProps> = ({ business, branch, customer, visit }) => {
     
     const [serviceItems, setServiceItemsVal] = useState<ServiceItem[]>([]);

     useEffect(() => {
          var serviceItems: ServiceItem[] = [];
          var sequence = 1;
          var spend = 0, tip = 0, tax = 0, disc = 0;
          if (visit) {
               spend += visit.spend;
               tip += visit.tip;
               tax += visit.tax;
               disc += visit.discount;
               visit.serviceItems!.forEach(item => {
                    serviceItems.push(new ServiceItem(sequence++, false, false, item.quantity, item.description!, '', item.price, item.totalPrice));
                    item.discounts!.filter(e => e.orderLevel === false)!.map((discount) => {
                         serviceItems.push(new ServiceItem(sequence++, true, false, discount.quantity, discount.description!, discount.descriptionDetail!, 0, discount.discountTotal * -1));
                    });
               });
               visit.serviceItems!.forEach(item => {
                    item.discounts!.filter(e => e.orderLevel === true)!.map((discount) => {
                         serviceItems.push(new ServiceItem(sequence++, true, false, discount.quantity, discount.description!, discount.descriptionDetail!, 0, discount.discountTotal * -1));
                    });
               });
               if (visit.pointsValue > 0) {
                    serviceItems.push(new ServiceItem(sequence++, true, true, visit.pointsRedeemed, "Points Redeemed", "", 0, visit.pointsValue * -1));
               }

               setServiceItemsVal(serviceItems);
          }
     }, [visit]);

     return (
          <IonPage id="receipt-page">
               <IonHeader>
                    <IonToolbar>
                         <IonButtons slot="start">
                              <IonBackButton defaultHref="/wallet/passdetails" />
                         </IonButtons>
                         <IonTitle>Receipt {visit!.invoiceNumber}</IonTitle>
                    </IonToolbar>
               </IonHeader>
               <IonContent>
                    <div className="receipt-container">
                         <div className="receipt-header">
                              <h2>{business!.companyName}</h2>
                              <p>{branch!.addressLine1}<br />{branch!.city}<br />{branch!.state} {branch!.zipCode}</p>
                         </div>
                         <div className="receipt-body">
                              <h4>{new Date(visit!.checkOut).toLocaleString()}</h4>
                              <h2 className="invoice-no">Receipt {visit!.invoiceNumber}</h2>
                              <div>
                                   <ul className="receipt-items">
                                        <li className="line-item section-header">Items</li>
                                        {serviceItems!.map((item) =>
                                             <li className="line-item">
                                                  <div className="label"><span className="item-label flex3">{item.description}{(item.quantity > 1 ? " x " + item.quantity : "")}</span> <span className={item.totalPrice > 0 ? "price flex1" : "price flex1 red"}>{helpers.toCurrency(customer!.country!, item.totalPrice)}</span></div>
                                                  {item.descriptionDetail && <div style={{fontSize:"10px",color:"gray"}}>{item.descriptionDetail}</div>}
                                             </li>
                                        )}
                                   </ul>
                                   <ul className="receipt-items subtotal-items">
                                        <li className="line-item">
                                             <div className="label"><span className="item-label flex3">Subtotal</span> <span className="price flex1">{helpers.toCurrency(customer!.country!, visit!.spend - visit!.discount)}</span></div>
                                        </li>
                                        <li className="line-item">
                                             <div className="label"><span className="item-label flex3">Tax ({branch!.taxPercent}%)</span> <span className="price flex1">{helpers.toCurrency(customer!.country!, visit!.tax)}</span></div>
                                        </li>
                                        <li className="line-item">
                                             <div className="label"><span className="item-label flex3">Tip ({(visit!.tip / (visit!.spend - visit!.discount + visit!.tax) * 100).toFixed(0)}%)</span> <span className="price flex1">{helpers.toCurrency(customer!.country!, visit!.tip)}</span></div>
                                        </li>
                                   </ul>
                                   <ul className="receipt-items total-items">
                                        <li className="line-item">
                                             <div className="label"><span className="item-label flex3">Total</span> <span className="price flex1">{helpers.toCurrency(customer!.country!, visit!.paymentAmount)}</span></div>
                                        </li>
                                   </ul>
                                   <ul className="tender-types">
                                        <li className="tender-item flex">
                                             <div className="tender-img flex1">
                                                  <div style={{ fontSize: "30px", color: "lightseagreen" }} className={(visit!.cardNetwork === "visa" ? "fa fa-cc-visa" : visit!.cardNetwork === "mastercard" ? "fa fa-cc-mastercard" : visit!.cardNetwork === "discover" ? "fa fa-cc-discover" : visit!.cardNetwork === "amex" ? "fa fa-cc-amex" : "fa fa-credit-card")} />
                                             </div>
                                             <div className="tender-name flex3">
                                                  <div className="flex">
                                                       <div className="flex3">
                                                            {visit!.cardNetwork && visit!.cardNetwork!.toUpperCase()}
                                                            <div className="tender-cvv">{visit!.cardLast4}</div>
                                                       </div>
                                                       <div className="flex1">
                                                            <strong className="tender-amount">{helpers.toCurrency(customer!.country!, visit!.paidAmount)}</strong>
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                   </ul>
                                   <ul className="receipt-items sm-text">
                                        <li>
                                             <p>
                                                  <strong>{customer!.userName}</strong> <br />
                                                  {customer!.id} <br />
                                                  Points Earned: <strong>{visit!.basePoints + visit!.bonusPoints}</strong> <br />
                                                  {visit!.checkOutUsername && <span>Checkout Employee: <strong>{visit!.checkOutUsername}</strong></span>}
                                             </p>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div className="receipt-footer">
                              <a href="https://xuvi.com/" target="_blank">
                                   <img src={business!.logoUrl} alt={business!.companyName} width="100" />
                              </a>
                              {business!.website && <p><a href={business!.website}>{business!.website}</a></p>}
                         </div>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          business: state.session.selectedBusiness,
          branch: state.session.selectedBranch,
          customer: state.customer.customer,
          visit: state.session.selectedVisit
     }),
     component: ReceiptPage
});