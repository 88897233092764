import { createSelector } from 'reselect';
import { AppState } from './state';
import { Filter } from '../models/Filter';

const getBusinesses = (state: AppState) => state.customer.businesses;
const getPasses = (state: AppState) => state.customer.passes;
const getSearchBusinessText = (state: AppState) => state.session.searchBusiness;
const getSearchPassText = (state: AppState) => state.session.searchPass;
const getVisits = (state: AppState) => state.session.visits;
const getSearchUnpaid = (state: AppState) => state.session.searchUnpaid;
const getSearchVisitText = (state: AppState) => state.session.searchVisit;
const getFilterId = (state: AppState) => state.session.filterId;

export const getSearchedBusinesses = createSelector(
  getBusinesses, getSearchBusinessText,
  (businesses, searchBusinessText) => {
    if (!searchBusinessText) {
      return businesses;
    }
    const bs = businesses!.filter(s => s.companyName!.toLowerCase().indexOf(searchBusinessText.toLowerCase()) > -1 
                                        || s.category!.toLowerCase().indexOf(searchBusinessText.toLowerCase()) > -1
                                        || s.branchLocations!.map(e => e.city).join(",").toLowerCase().indexOf(searchBusinessText.toLowerCase()) > -1);
    return bs;
  }
);

export const getCategories = createSelector(getBusinesses, getPasses, getFilterId,
  (businesses, passes, filterId) => {
    const intersection = businesses!.map(e => ({ 'id': e.id, 'cgy': e.category })).filter(element => passes!.map(e => e.businessId).includes(element.id));
    const categories = intersection!.map(e => e.cgy).filter((category, index, arr) => arr.indexOf(category) === index)
    var filters: Filter[] = [];
    filters.push(new Filter("All", "All", filterId === undefined || filterId === "All"));
    categories.forEach((category) => {
      filters.push(new Filter(category, category, category === filterId));
    });
    return filters;
  }
);

export const getPassList = createSelector(getBusinesses, getPasses, getFilterId, getSearchPassText,
  (businesses, passes, filterId, searchPassText) => {
    let ps = passes!.filter(element => businesses!.filter(c => (!searchPassText || c.companyName!.toLowerCase().indexOf(searchPassText!.toLowerCase()) >= 0) &&  (!filterId! || c.category.toLowerCase() === filterId!.toLowerCase() || filterId === "All")).map(e => e.businessId).includes(element.businessId));
    return ps;
  }
);

export const getSearchedVisits = createSelector(
  getVisits, getSearchVisitText, getSearchUnpaid,
  (visits, searchVisitsText, searchUnpaid) => {
    if (!searchVisitsText) {
      return searchUnpaid ? visits!.filter(s => s.paymentAmount > 0 && s.paidAmount <= 0) : visits;
    }
    const vis = visits!.filter(s => (!searchUnpaid || (s.paymentAmount > 0 && s.paidAmount <= 0)) && 
                                    s.serviceDesc!.toLowerCase().indexOf(searchVisitsText.toLowerCase()) > -1 ||
                                    new Date(s.checkOut).toLocaleString()!.toLowerCase().indexOf(searchVisitsText.toLowerCase()) > -1);
    return vis;
  }
);

export const mapCenter = (state: AppState) => {
    return {
      id: 1,
      name: 'Map Center',
      lat: state.customer.customer!.latitude || 0,
      lng: state.customer.customer!.longitude || 0
    };
}