import { combineReducers } from './combineReducers';
import { sessionReducer } from './session/session.reducer';
import { customerReducer } from './customer/customer.reducer';
import { userReducer } from './user/user.reducer';

export const initialState: AppState = {
  customer: {
     customer: undefined,
     passes: [],
     visits: [],
     businesses: [],
     campaigns: undefined,
     beacons: undefined
  },
  session: {
    branchId: undefined,
    businessId: undefined,
    loading: false,
    menuEnabled: false,
    paidWithPoints: false,
    payAmount: 0,
    pointsToRedeem: 0,
    reservationsMap: undefined,
    searchUnpaid: false,
    selectedBusinesses: [],
    selectedBusiness: undefined,
    selectedBranch: undefined,
    selectedPass: undefined,
    selectedVisits: [],
    tipAmount: 0,
    viewPay: false,
    walletPay: undefined
  },
  user: {
    hasSeenTutorial: false,
    autoPay: true,
    darkMode: false,
    isLoggedin: false,
    loading: false
  }
};

export const reducers = combineReducers({
  customer: customerReducer,
  session: sessionReducer,
  user: userReducer
});

export type AppState = ReturnType<typeof reducers>;