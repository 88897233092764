import { connect } from "../data/connect"
import React, { useEffect, useRef, useState } from "react"
import { RouteComponentProps, useLocation } from 'react-router';
import { IonHeader, IonLabel, IonToolbar, IonContent, IonPage, IonSearchbar, IonList, IonItemSliding, IonItem, IonItemOption, IonItemOptions, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, getConfig, IonToast } from '@ionic/react'
import * as selectors from '../data/selectors';
import * as helpers from '../util/helpers';
import { addSelectedVisit, removeSelectedVisit, setSearchUnpaid, setSearchVisit, setSelectedBranch, setSelectedPass, setSelectedVisit, setSelectedVisits, setVisits } from '../data/session/session.actions';
import { IBusinessEntity } from "../models/Base/BusinessEntity";
import { ICustomerVisit } from "../models/Base/CustomerVisit";
import { getCustomerVisits } from "../data/dataApi";
import { ICustomerBlockResponse } from "../models/Service/CustomerBlockResponse";
import { Browser, Plugins } from "@capacitor/core";
import { bus, chevronDownCircleOutline } from "ionicons/icons";
import './Orders.scss';
const { Storage } = Plugins;

const TOKEN = 'token';

interface OwnProps extends RouteComponentProps { };
interface PassDetailsProps extends OwnProps, OrdersProps, DispatchProps { };

interface StateProps {
     business?: IBusinessEntity,
     mode: 'ios' | 'md',
     selectedVisits?: ICustomerVisit[],
     visits?: ICustomerVisit[]
}
interface Error {
     showError: boolean;
     message?: string;
}
interface DispatchProps {
     setSearchVisit: typeof setSearchVisit;
     setVisits: typeof setVisits;
     setSearchUnpaid: typeof setSearchUnpaid;
     setSelectedBranch: typeof setSelectedBranch;
     setSelectedPass: typeof setSelectedPass;
     setSelectedVisit: typeof setSelectedVisit;
     setSelectedVisits: typeof setSelectedVisits;
     addSelectedVisit: typeof addSelectedVisit;
     removeSelectedVisit: typeof removeSelectedVisit;
}
interface OrdersProps extends OwnProps, StateProps, DispatchProps { };

const Orders: React.FC<OrdersProps> = ({ history, mode, visits, setVisits, setSearchUnpaid, selectedVisits, setSelectedVisits, setSelectedVisit, setSelectedBranch }) => {
     const businessId = useRef('');
     const customerId = useRef('');
     const customer = useRef({});
     const business = useRef({});
     const customerCountry = useRef('');
     const customerBlock = useRef<ICustomerBlockResponse>();
     let query = useQuery();

     const [error, setError] = useState<Error>({ showError: false });

     function useQuery() {
          return new URLSearchParams(useLocation().search);
     }

     useEffect(() => {
          businessId.current = query.get("bid")!;

          window.addEventListener("message", async (event) => {
               if (event.data.cb !== undefined) {
                    customerBlock.current = event.data.cb as ICustomerBlockResponse;
                    business.current = customerBlock.current.businessEntities!.find(b => b.id! === businessId.current)!;
                    if (customerBlock.current.token) {
                         await Storage.set({ key: TOKEN, value: customerBlock.current.token });
                         customerId.current = customerBlock.current.customer!.id! || '';
                         customer.current = customerBlock.current.customer!;
                         console.log(businessId.current);
                         console.log(business.current)
                         customerCountry.current = customerBlock.current.customer!.country!;
                         const visits = await getCustomerVisits(businessId.current!, customerId.current!
                         );
                         setVisits(visits);
                         setSelectedVisits([]);
                    }
               }
          });
     })

     const select = (visit: ICustomerVisit) => {
          if (visit && visit.paidAmount <= 0) {
               if (selectedVisits!.indexOf(visit) < 0) {
                    addSelectedVisit(visit);
               } else {
                    removeSelectedVisit(visit.id!);
               }
          }
     }

     const refresh = async (complete: any) => {
          try {
               const visits = await getCustomerVisits(businessId.current!, customerId.current!);
               setVisits(visits);
               setSelectedVisits([]);
               if (complete) {
                    complete();
               }
          } catch (e) {
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const viewReceipt = async (visit: ICustomerVisit) => {
          if (visit && visit.paymentReceiptUrl) {
               await Browser.open({ url: visit.paymentReceiptUrl });
          }
     }

     const viewInvoice = async (visit: ICustomerVisit) => {
          if (visit && visit.invoiceNumber) {
               var currentBusinss = business.current as IBusinessEntity;
               if (currentBusinss) {
                    const branch = currentBusinss.branchLocations!.find(e => e.branchId === visit.branchId);
                    setSelectedBranch(branch!);
                    setSelectedVisit(visit);
                    history.push('/wallet/passdetails/receipt', { direction: 'forward' });
               }
          }
     }

     const viewDetails = async (visit: ICustomerVisit) => {
          if (visit) {
               setSelectedVisit(visit);
               history.push('/wallet/passdetails/visitdetails', { direction: 'forward' });
          }
     }
     return (
          <IonPage id="pass-details">
               <IonHeader>
                    <IonToolbar>
                         <div className={mode === "md" ? "" : "ion-padding-top"} style={{ position: "relative" }}>
                              <IonGrid>
                                   <IonRow>
                                        <IonCol>
                                             <IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchVisit(e.detail.value)}></IonSearchbar>
                                        </IonCol>

                                   </IonRow>
                              </IonGrid>
                         </div>
                    </IonToolbar>
               </IonHeader>
               <IonContent>
                    <IonToast
                         isOpen={error.showError}
                         position="top"
                         onDidDismiss={() => setError({ message: "", showError: false })}
                         message={error.message}
                         duration={3000}
                    />
                    <IonRefresher slot="fixed" onIonRefresh={(e) => refresh(e.detail.complete)} pullFactor={0.5} pullMin={200} pullMax={300}>
                         <IonRefresherContent pullingIcon={chevronDownCircleOutline} />
                    </IonRefresher>
                    <IonList>
                         {visits && visits.filter(e => e.invoiceNumber && e.invoiceNumber !== '').map((visit) => (
                              <IonItemSliding key={visit.id} onClick={() => select(visit)}>
                                   <IonItem lines="none" className="ion-no-margin ion-no-padding">
                                        <div className={selectedVisits!.indexOf(visit) >= 0 ? "panel selected" : "panel"}>
                                             <IonGrid>
                                                  <IonRow>
                                                       <IonCol size="8" className="ion-no-margin ion-no-padding">
                                                            <div className="service">
                                                                 <IonLabel className="serviceDesc">{visit.serviceDesc}</IonLabel>
                                                                 <div className="paymentDesc">{visit.invoiceNumber}</div>
                                                                 <div className="paymentDesc">{visit.paymentDesc}</div>
                                                            </div>
                                                            <IonGrid className="ion-no-margin ion-no-padding card-footer">
                                                                 <div className="dateTime">
                                                                      {
                                                                           (new Date(visit.checkOut)).getFullYear() > 2020 &&
                                                                           <p>
                                                                                <span className="date">Checkout Date:</span>
                                                                                <span>{(new Date(visit.checkOut)).toLocaleString()}</span>
                                                                           </p>
                                                                      }

                                                                      {
                                                                           (new Date(visit.reservationDT)).getFullYear() > 2020 &&
                                                                           <p>
                                                                                <span className="date">Reservation Date:</span>
                                                                                <span>{new Date(visit.reservationDT).toLocaleString()}</span>
                                                                           </p>
                                                                      }
                                                                 </div>
                                                                 <div className="dateTime pointsTag">
                                                                      <p>
                                                                           <span className="date">Points</span>
                                                                           <span className="points">{visit.basePoints + visit.bonusPoints}</span>
                                                                      </p>
                                                                 </div>
                                                            </IonGrid>
                                                       </IonCol>
                                                       <IonCol size="4" className="ion-no-margin ion-no-padding prizeList">
                                                            <div className="vl"></div>
                                                            <IonGrid className="ion-no-margin ion-no-padding">
                                                                 <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Cost</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount green">{helpers.toCurrency(customerCountry.current!, visit.spend)}</span></IonCol></IonRow>
                                                                 <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Discount</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount red">- {helpers.toCurrency(customerCountry.current!, visit.discount)}</span></IonCol></IonRow>
                                                                 <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Tax</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount green">+ {helpers.toCurrency(customerCountry.current!, visit.tax)}</span></IonCol></IonRow>
                                                                 <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Tips</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount green">+ {helpers.toCurrency(customerCountry.current!, visit.tip)}</span></IonCol></IonRow>
                                                                 <IonRow className="amount-row"><IonCol className="ion-text-right"><span className="total">{helpers.toCurrency(customerCountry.current!, visit.paymentAmount)}</span></IonCol></IonRow>
                                                            </IonGrid>
                                                       </IonCol>
                                                  </IonRow>
                                             </IonGrid>
                                             {visit.paymentAmount > 0 && visit.paidAmount <= 0 && <div style={{ color: "red", fontWeight: "bold", position: "absolute", top: "33%", left: "45%" }}><img src="/assets/img/unpaid.png" height="40" /></div>}
                                        </div>
                                   </IonItem>
                                   <IonItemOptions side="end" >
                                        <IonItemOption>
                                             <IonGrid>
                                                  <IonRow>
                                                       <div className="slide-btn" onClick={() => viewDetails(visit)}>Details</div>
                                                  </IonRow>
                                                  <IonRow>
                                                       <div className="slide-btn" onClick={() => viewInvoice(visit)}>Receipt</div>
                                                  </IonRow>
                                             </IonGrid>
                                        </IonItemOption>
                                   </IonItemOptions>
                              </IonItemSliding>
                         ))}
                    </IonList>

               </IonContent>
          </IonPage>
     );

}

export default connect({
     mapStateToProps: (state) => ({
          mode: getConfig()!.get('mode'),
          visits: selectors.getSearchedVisits(state),
          // customer: state.customer.customer,
          selectedVisits: state.session.selectedVisits,
     }),
     mapDispatchToProps: {
          addSelectedVisit,
          removeSelectedVisit,
          setSearchUnpaid,
          setSearchVisit,
          setSelectedBranch,
          setSelectedPass,
          setSelectedVisit,
          setSelectedVisits,
          setVisits
     },
     component: Orders
})
