import { IBusinessEntity, IBranchLocation } from '../../models/Base/BusinessEntity';
import { ICustomerPass } from '../../models/Base/CustomerPass';
import { ICustomerVisit } from '../../models/Base/CustomerVisit';
import { IReservationsResponse } from '../../models/Service/ReservationsResponse';
import { ActionType } from '../../util/types';
import { getSessionData } from '../dataApi';
import { SessionState } from './session.state';

export const loadSessionData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getSessionData();
  if (data != null) {
    dispatch(setSession(data));
  }
  dispatch(setLoading(false));
}

export const setSession = (data: Partial<SessionState>) => ({
  type: 'set-session',
  data
} as const);

export const setLoading = (isLoading: boolean) => ({
  type: 'set-customer-loading',
  isLoading
} as const);

export const setSearchBusiness = (searchBusiness?: string) => ({ 
  type: 'set-search-business', 
  searchBusiness 
} as const);

export const setSearchPass = (searchPass?: string) => ({ 
  type: 'set-search-pass', 
  searchPass 
} as const);

export const setSearchVisit = (searchVisit?: string) => ({ 
  type: 'set-search-visit', 
  searchVisit 
} as const);

export const setSearchUnpaid = (searchUnpaid: boolean) => ({ 
  type: 'set-search-unpaid', 
  searchUnpaid 
} as const);

export const setFilterId = (filterId?: string) => ({ 
  type: 'set-filter-id', 
  filterId 
} as const);

export const setSelectedBusiness = (selectedBusiness?: IBusinessEntity) => ({
    type: 'set-selected-business',
    selectedBusiness
} as const);

export const setSelectedBranch = (selectedBranch: IBranchLocation) => ({
  type: 'set-selected-branch',
  selectedBranch
} as const);

export const setSelectedPass = (selectedPass?: ICustomerPass) => ({
  type: 'set-selected-pass',
  selectedPass
} as const);

export const setSelectedVisit = (visit?: ICustomerVisit) => ({
  type: 'set-selected-visit',
  visit
} as const);

export const setSelectedVisits = (visits: ICustomerVisit[]) => ({
  type: 'set-selected-visits',
  visits
} as const);

export const addSelectedVisit = (visit: ICustomerVisit) => ({
  type: 'add-selected-visit',
  visit
} as const);

export const removeSelectedVisit = (id: string) => ({
  type: 'remove-selected-visit',
  id
} as const);

export const setReservationsMap = (reservationsMap: IReservationsResponse) => ({
  type: 'set-reservations-map',
  reservationsMap
} as const);

export const selectBusiness = (id: string) => ({
  type: 'select-business',
  id
} as const);

export const unselectBusiness = (id: string) => ({
  type: 'unselect-business',
  id
} as const);

export const setSelectedResDate = (date: Date) => ({
  type: 'set-selected-res-date',
  date
} as const);

export const setSelectedResTime = (time: Date) => ({
  type: 'set-selected-res-time',
  time
} as const);

export const setVisits = (visits: ICustomerVisit[]) => ({
  type: 'set-visits',
  visits
} as const);

export const setBranchId = (branchId: string) => ({
  type: 'set-branch-id',
  branchId
} as const);

export const setBusinessId = (businessId: string) => ({
  type: 'set-business-id',
  businessId
} as const);

export const setWalletPay = (walletPay: string | undefined) => ({
  type: 'set-wallet-pay',
  walletPay
} as const);

export const setPaidWithPoints = (paidWithPoints: boolean) => ({
  type: 'set-paid-with-points',
  paidWithPoints
} as const);

export const setPayAmount = (payAmount: number) => ({
  type: 'set-pay-amount',
  payAmount
} as const);

export const setTipAmount = (tipAmount: number) => ({
  type: 'set-tip-amount',
  tipAmount
} as const);

export const setPointsToRedeem = (pointsToRedeem: number) => ({
  type: 'set-points-to-redeem',
  pointsToRedeem
} as const);

export const setViewPay = (viewPay: boolean) => ({
  type: 'set-view-pay',
  viewPay
} as const);

export type SessionActions =
  | ActionType<typeof setBranchId>
  | ActionType<typeof setBusinessId>
  | ActionType<typeof setSession>
  | ActionType<typeof setLoading>
  | ActionType<typeof setSearchBusiness>
  | ActionType<typeof setSearchPass>
  | ActionType<typeof setSearchUnpaid>
  | ActionType<typeof setSearchVisit>
  | ActionType<typeof setFilterId>
  | ActionType<typeof selectBusiness>
  | ActionType<typeof unselectBusiness>
  | ActionType<typeof setSelectedBusiness>
  | ActionType<typeof setSelectedBranch>
  | ActionType<typeof setSelectedPass>
  | ActionType<typeof setSelectedVisit>
  | ActionType<typeof setSelectedVisits>
  | ActionType<typeof addSelectedVisit>
  | ActionType<typeof removeSelectedVisit>
  | ActionType<typeof setReservationsMap>
  | ActionType<typeof setSelectedResDate>
  | ActionType<typeof setSelectedResTime>
  | ActionType<typeof setVisits>
  | ActionType<typeof setWalletPay>
  | ActionType<typeof setPaidWithPoints>
  | ActionType<typeof setPayAmount>
  | ActionType<typeof setPointsToRedeem>
  | ActionType<typeof setTipAmount>
  | ActionType<typeof setViewPay>