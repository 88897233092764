
export class StorageBase implements IStorageBase {
    id?: string | undefined;
    businessId?: string | undefined;
    collection?: string | undefined;
    createdDT!: Date;
    lastModifiedDT!: Date;
    lastModifiedUser?: string | undefined;
    eTag?: string | undefined;

    constructor(data?: IStorageBase) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["Id"];
            this.businessId = _data["BusinessId"];
            this.collection = _data["Collection"];
            this.createdDT = _data["CreatedDT"] ? new Date(_data["CreatedDT"].toString()) : undefined as any;
            this.lastModifiedDT = _data["LastModifiedDT"] ? new Date(_data["LastModifiedDT"].toString()) : undefined as any;
            this.lastModifiedUser = _data["LastModifiedUser"];
            this.eTag = _data["ETag"];
        }
    }

    static fromJS(data: any): StorageBase {
        data = typeof data === 'object' ? data : {};
        let result = new StorageBase();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Id"] = this.id;
        data["BusinessId"] = this.businessId;
        data["Collection"] = this.collection;
        data["CreatedDT"] = this.createdDT ? this.createdDT.toISOString() : undefined as any;
        data["LastModifiedDT"] = this.lastModifiedDT ? this.lastModifiedDT.toISOString() : undefined as any;
        data["LastModifiedUser"] = this.lastModifiedUser;
        data["ETag"] = this.eTag;
        return data; 
    }
}

export interface IStorageBase {
    id?: string | undefined;
    businessId?: string | undefined;
    collection?: string | undefined;
    createdDT: Date;
    lastModifiedDT: Date;
    lastModifiedUser?: string | undefined;
    eTag?: string | undefined;
}