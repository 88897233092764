import React, { useRef, useEffect } from 'react';
import { connect } from '../data/connect';
import { setLoading, setReservationsMap, setSelectedBranch, setSelectedResDate } from '../data/session/session.actions';
import { getReservationsMap } from '../data/dataApi';
import { MapPosition } from '../models/MapPosition';
import { BranchLocation, IBusinessEntity, IBranchLocation } from '../models/Base/BusinessEntity';
import { ICustomerPass } from '../models/Base/CustomerPass';

interface OwnProps {
  locations: BranchLocation[]
  mapCenter: MapPosition
}

interface StateProps {
  selectedBusiness?: IBusinessEntity;
  selectedPass?: ICustomerPass;
  loading: boolean;
}

interface DispatchProps {
  setSelectedBranch: typeof setSelectedBranch;
  setReservationsMap: typeof setReservationsMap;
  setSelectedResDate: typeof setSelectedResDate;
  setLoading: typeof setLoading;
}

interface MapProps extends OwnProps, StateProps, DispatchProps { }

const Map: React.FC<MapProps> = ({ mapCenter, locations, selectedBusiness, selectedPass, setSelectedBranch, setReservationsMap, setSelectedResDate, setLoading }) => {
  const mapEle = useRef<HTMLDivElement>(null);
  const map = useRef<google.maps.Map>();

  const selectBranch = async (branch: IBranchLocation) => {
    setLoading(true);
    const date = new Date();
    const dt = ("00" + (date.getMonth() + 1)).slice(-2)
      + "/" + ("00" + date.getDate()).slice(-2)
      + "/" + date.getFullYear() + " "
      + ("00" + date.getHours()).slice(-2) + ":"
      + ("00" + date.getMinutes()).slice(-2)
      + ":" + ("00" + date.getSeconds()).slice(-2);
    const reservations = await getReservationsMap(selectedBusiness!.id!, branch.branchId!, selectedPass!.customerId!, dt, dt);
    setReservationsMap(reservations);
    setSelectedResDate(new Date());
    setSelectedBranch(branch);
    setLoading(false);
  }

  useEffect(() => {
    if (map.current) return;
    try {
      map.current = new google.maps.Map(mapEle.current!, {
        center: {
          lat: mapCenter.lat,
          lng: mapCenter.lng
        },
        zoom: 10,
        disableDefaultUI: true
      });
      addMarkers();
      google.maps.event.addListenerOnce(map.current, 'idle', () => {
        if (mapEle.current) {
          mapEle.current.classList.add('show-map');
        }
      });
    } catch { }

    function addMarkers() {
      locations.forEach((markerData) => {
        let infoWindow = new google.maps.InfoWindow({
          content: `<span id="infow">${markerData.branchName}</span>`
        });

        google.maps.event.addListener(infoWindow, 'domready', function () {
          document.getElementById("infow")!.onclick = function (e) {

          };
        });

        let marker = new google.maps.Marker({
          position: new google.maps.LatLng(markerData.latitude, markerData.longitude),
          map: map.current!,
          title: markerData.branchName,
        });

        marker.addListener('click', async () => {
          await selectBranch(markerData);
          //infoWindow.open(map.current!, marker);
        });

      });
    }

  }, [mapCenter, locations]);

  return (
    <div ref={mapEle} className="map-canvas"></div>
  );
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    selectedBusiness: state.session.selectedBusiness,
    selectedPass: state.session.selectedPass,
    loading: state.session.loading
  }),
  mapDispatchToProps: {
    setSelectedBranch,
    setReservationsMap,
    setSelectedResDate,
    setLoading
  },
  component: Map
})

//export default Map;