import React, { useEffect, useRef, useState } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonTitle, IonMenuButton, IonButton, IonIcon, IonSearchbar, IonHeader, getConfig, IonList, IonGrid, IonLabel, IonRow, IonCard, IonCol, IonDatetime, IonSlides, IonSlide, IonItem, IonModal, IonLoading, IonToast, IonThumbnail, IonCardHeader, IonCardContent, IonSpinner, IonRefresher, IonRefresherContent, useIonViewWillEnter } from '@ionic/react';
import { setFilterId, setLoading, setPaidWithPoints, setPayAmount, setPointsToRedeem, setReservationsMap, setSearchPass, setSelectedBranch, setSelectedBusiness, setSelectedPass, setSelectedResDate, setSelectedResTime, setSelectedVisits, setTipAmount, setViewPay, setVisits, setWalletPay } from '../data/session/session.actions';
import { connect } from '../data/connect';
import { RouteComponentProps, useLocation } from 'react-router';
import './WalletPage.scss';
import { call, cart, chevronDownCircleOutline, gridOutline, handRight, locate, menu } from 'ionicons/icons';
import * as selectors from '../data/selectors';
import * as helpers from '../util/helpers';
import { Filter } from '../models/Filter';
import Map from '../components/Map';
import { MapPosition } from '../models/MapPosition';
import { confirmWalletPay, getCustomerPasses, getCustomerVisits, getProductsForCampaign, getReservationsMap, getToken, processCheckIn, processReservation, SERVER_URL, setSessionBlockData } from '../data/dataApi';
import { alertController } from '@ionic/core';
import { setPasses, setPayVisits, setReservations } from '../data/customer/customer.actions';
import { Browser } from '@capacitor/core';
import { Geolocation } from '@ionic-native/geolocation';
import { HubConnectionBuilder } from '@aspnet/signalr';
import Pay from '../components/Pay';
import { IBusinessEntity, IBranchLocation } from '../models/Base/BusinessEntity';
import { ICampaign } from '../models/Base/Campaign';
import { ICustomer } from '../models/Base/Customer';
import { ICustomerPass } from '../models/Base/CustomerPass';
import { ICustomerVisit } from '../models/Base/CustomerVisit';
import { IProduct } from '../models/Base/Product';
import { IReservationsResponse } from '../models/Service/ReservationsResponse';
import { CampaignTypes, OfferTypes } from '../models/Base/Enums';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
     businesses?: IBusinessEntity[];
     campaigns?: { [key: string]: ICampaign[]; };
     customer?: ICustomer;
     filters?: Filter[];
     loading: boolean;
     mapCenter: MapPosition;
     mode: 'ios' | 'md';
     passes?: ICustomerPass[];
     payVisits?: ICustomerVisit[];
     reservationsMap?: IReservationsResponse;
     selectedBranch?: IBranchLocation;
     selectedResDate?: Date;
     selectedResTime?: Date;
     viewPay: boolean;
     walletPay?: string;
}

interface DispatchProps {
     setSearchPass: typeof setSearchPass;
     setFilterId: typeof setFilterId;
     setSelectedBusiness: typeof setSelectedBusiness;
     setSelectedBranch: typeof setSelectedBranch;
     setSelectedPass: typeof setSelectedPass;
     setSelectedVisits: typeof setSelectedVisits;
     setReservationsMap: typeof setReservationsMap;
     setSelectedResDate: typeof setSelectedResDate;
     setSelectedResTime: typeof setSelectedResTime;
     setLoading: typeof setLoading;
     setPasses: typeof setPasses;
     setReservations: typeof setReservations;
     setVisits: typeof setVisits;
     setPayVisits: typeof setPayVisits;
     setWalletPay: typeof setWalletPay;
     setPaidWithPoints: typeof setPaidWithPoints;
     setPayAmount: typeof setPayAmount;
     setPointsToRedeem: typeof setPointsToRedeem;
     setTipAmount: typeof setTipAmount;
     setViewPay: typeof setViewPay;
}

interface Error {
     showError: boolean;
     message?: string;
}

interface WalletPageProps extends OwnProps, StateProps, DispatchProps { }

const WalletPage: React.FC<WalletPageProps> = ({ history, location, match, businesses, campaigns, filters, customer, passes, payVisits, viewPay, setPayVisits, setSearchPass, setFilterId, selectedBranch, setSelectedBusiness, setSelectedBranch, setSelectedPass, setSelectedVisits, setReservationsMap, setWalletPay, mapCenter, reservationsMap, setSelectedResDate, setSelectedResTime, selectedResDate, selectedResTime, loading, setLoading, setPasses, setReservations, setVisits, mode, walletPay, setViewPay }) => {

     const [loadingRes, setLoadingRes] = useState<boolean>(false);
     const [error, setError] = useState<Error>({ showError: false });
     const [viewProducts, setViewProducts] = useState(false);
     const [products, setProducts] = useState<IProduct[]>([]);
     const [viewCheckin, setViewCheckin] = useState(false);
     const [selectedPass, setSelectedPassVal] = useState<ICustomerPass>();
     const [reservationsEnabled, setReservationsEnabled] = useState(true);
     const businessesRef = useRef(businesses);
     const passesRef = useRef(passes);
     const paymentVisits = useRef<ICustomerVisit[] | undefined>(payVisits);
     let query = useQuery();

     function useQuery() {
          return new URLSearchParams(useLocation().search);
     }

     useIonViewWillEnter(async () => {
          if (paymentVisits && paymentVisits.current && paymentVisits.current.length > 0) {
               const business = businessesRef.current!.find(e => e.id == paymentVisits.current![0].businessId)!;
               setSelectedBusiness(business);
               const pass = passesRef.current!.find(e => e.businessId == business.id)!;
               setSelectedPass(pass);
               setSelectedVisits(paymentVisits.current);
               setViewPay(true);
          }

          if (customer) {
               if (!walletPay) {
                    const wp = query.get("data")!;
                    const pay = await confirmWalletPay(customer.id!, wp);
                    if (pay && pay.visit) {
                         const business = businessesRef.current!.find(e => e.id == pay.visit!.businessId)!;
                         setSelectedBusiness(business);
                         const pass = passesRef.current!.find(e => e.businessId == business.id)!;
                         setSelectedPass(pass);
                         var visits: ICustomerVisit[] = [];
                         visits.push(pay.visit);
                         setSelectedVisits(visits);
                         setViewPay(true);
                    }
               } else {
                    const wp = decodeURIComponent(walletPay);
                    const pay = await confirmWalletPay(customer.id!, wp);
                    if (pay && pay.visit) {
                         const business = businessesRef.current!.find(e => e.id == pay.visit!.businessId)!;
                         setSelectedBusiness(business);
                         const pass = passesRef.current!.find(e => e.businessId == business.id)!;
                         setSelectedPass(pass);
                         var visits: ICustomerVisit[] = [];
                         visits.push(pay.visit);
                         setSelectedVisits(visits);
                         setViewPay(true);
                    }
                    setWalletPay(undefined);
               }
          }
     });

     useEffect(() => {
          try {
               if (customer) {
                    const connection = new HubConnectionBuilder().withUrl(SERVER_URL + "/actionhub", { accessTokenFactory: () => getToken() }).build();
                    connection.start().then(function () {
                         console.log('Connected!');
                    }).catch(function (err) {
                         if (err) {
                              return console.error(err.toString());
                         }
                    });

                    connection.on("CapturePayment", (payVisits: ICustomerVisit[]) => {
                         if (payVisits && payVisits.length > 0) {
                              setPayVisits(payVisits);
                              const business = businessesRef.current!.find(e => e.id == payVisits[0].businessId)!;
                              setSelectedBusiness(business);
                              const pass = passesRef.current!.find(e => e.businessId == business.id)!;
                              setSelectedPass(pass);
                              setSelectedVisits(payVisits);
                              setViewPay(true);
                         }
                    });
               }
          } catch (e) {
               console.log(e);
          }
     }, [customer]);

     const slideOpts = {
          initialSlide: 0,
          speed: 400
     };

     const refresh = async (complete: any) => {
          try {
               const passes = await getCustomerPasses(customer!.id!);
               setPasses(passes);
               complete();
          } catch (e) {
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const getLine1Text = (campaign: ICampaign) => {
          let line1Text: string = '';
          switch (campaign && campaign.type) {
               case CampaignTypes.BuyXGetY:
                    line1Text = "Buy " + campaign.xValue + " " + (campaign.productsX && campaign.productsX.length === 1 ? campaign.xValue > 1 ? campaign.productsX[0].description + "s" : campaign.productsX[0].description : " from these ");
                    break;
               case CampaignTypes.SpendXGetY:
                    line1Text = "Spend " + (campaign.xValue > 0 ? campaign.xValue.toLocaleString(customer!.country === "US" ? "en-US" : "en-IN", { minimumFractionDigits: 0, style: "currency", currency: customer!.country === "US" ? "USD" : "INR" }) : "any amount");
                    break;
               case CampaignTypes.VisitXGetY:
                    line1Text = "Visit " + campaign.xValue + (campaign.xValue > 0 ? " times" : " time");
                    break;
          }
          return line1Text;
     }

     const getLine2Text = (campaign: ICampaign) => {
          let line2Text: string = '';
          if (campaign) {
               const start = new Date(campaign.start) > new Date() ? new Date(campaign.start) : new Date();
               const end = new Date(campaign.end);
               const days = Math.floor((Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) - Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) / (1000 * 60 * 60 * 24));
               line2Text = "in the next " + days + (days === 1 ? " day" : " days")
          }
          return line2Text;
     }

     const getLine3Text = (campaign: ICampaign) => {
          let line3Text: string = '';
          if (campaign) {
               switch (campaign.offerType) {
                    case OfferTypes.Cash:
                         line3Text = "Get " + campaign.offerValue.toLocaleString(customer!.country === "US" ? "en-US" : "en-IN", { minimumFractionDigits: 0, style: "currency", currency: customer!.country === "US" ? "USD" : "INR" });
                         break;
                    case OfferTypes.Free:
                         line3Text = "Get " + (campaign.offerValue > 0 ? campaign.offerValue + " Free" : "Free");
                         break;
                    case OfferTypes.Multiplier:
                         line3Text = "Get " + campaign.offerValue + "x";
                         break;
                    case OfferTypes.PercentDiscount:
                         line3Text = "Get " + (campaign.type === CampaignTypes.BuyXGetY ? campaign.offerValue + "% off" : campaign.offerValue + "%");
                         break;
                    case OfferTypes.Points:
                         line3Text = "Get " + campaign.offerValue;
                         break;
               }
          }
          return line3Text;
     }

     const getLine4Text = (campaign: ICampaign) => {
          let line4Text: string = '';
          if (campaign) {
               switch (campaign.offerType) {
                    case OfferTypes.Cash:
                         line4Text = "Cash";
                         break;
                    case OfferTypes.Free:
                         line4Text = "" + (campaign.productsY && campaign.productsY.length === 1 ? campaign.productsY[0].description : " from these ");
                         break;
                    case OfferTypes.Multiplier:
                         line4Text = "Points";
                         break;
                    case OfferTypes.PercentDiscount:
                         line4Text = "" + (campaign.productsY && campaign.type === CampaignTypes.BuyXGetY
                              ? campaign.productsY && campaign.productsY.length === 1
                                   ? campaign.yQuantity > 1
                                        ? campaign.yQuantity + " " + campaign.productsY[0].description + "s"
                                        : campaign.productsY[0].description
                                   : " from these "
                              : campaign.offerValue + "%");
                         break;
                    case OfferTypes.Points:
                         line4Text = "Bonus Points";
                         break;
               }
          }
          return line4Text;
     }

     const showCheckIn = async (pass: ICustomerPass) => {
          try {
               setLoading(true);
               let business = businesses && businesses.find(c => c.id === pass.businessId);
               let branches = pass && business!.branchLocations;
               if (business && branches) {
                    setSelectedResDate(new Date());
                    let enabled = branches.some(d => d.reservationsDisabled === false);
                    setReservationsEnabled(enabled!);
                    setSelectedBusiness(business);
                    setSelectedPass(pass);
                    setSelectedPassVal(pass);
                    setSelectedBranch(branches[0]);
                    const date = new Date();
                    let dt = ("00" + (date.getMonth() + 1)).slice(-2)
                         + "/" + ("00" + date.getDate()).slice(-2)
                         + "/" + date.getFullYear() + " "
                         + ("00" + date.getHours()).slice(-2) + ":"
                         + ("00" + date.getMinutes()).slice(-2)
                         + ":" + ("00" + date.getSeconds()).slice(-2);
                    const reservations = await getReservationsMap(business.id!, branches[0].branchId!, pass.customerId!, dt, dt);
                    setReservationsMap(reservations);
                    setReservations(reservations.reservations);
                    setViewCheckin(true);
               }
               setLoading(false);
          }
          catch (e) {
               setLoading(false);
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const reservationDateSelected = async (date: Date) => {
          try {
               if (selectedPass && selectedBranch) {
                    setLoadingRes(true);
                    setSelectedResDate(date);
                    let business = businesses && businesses.find(c => c.id === selectedPass.businessId);
                    const start = new Date();
                    let startdt = ("00" + (start.getMonth() + 1)).slice(-2)
                         + "/" + ("00" + start.getDate()).slice(-2)
                         + "/" + start.getFullYear() + " "
                         + ("00" + start.getHours()).slice(-2) + ":"
                         + ("00" + start.getMinutes()).slice(-2)
                         + ":" + ("00" + start.getSeconds()).slice(-2);
                    let dt = ("00" + (date.getMonth() + 1)).slice(-2)
                         + "/" + ("00" + date.getDate()).slice(-2)
                         + "/" + date.getFullYear() + " "
                         + ("00" + date.getHours()).slice(-2) + ":"
                         + ("00" + date.getMinutes()).slice(-2)
                         + ":" + ("00" + date.getSeconds()).slice(-2);
                    const reservations = await getReservationsMap(business!.id!, selectedBranch.branchId!, selectedPass.customerId!, startdt, dt);
                    setReservationsMap(reservations);
                    setReservations(reservations.reservations);
                    setLoadingRes(false);
               }
          }
          catch (e) {
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const reservationTimeSelected = async (time: Date) => {
          try {
               if (reservationsMap) {
                    setSelectedResTime(time);
                    let rm = reservationsMap;
                    let resTimes: { [key: string]: boolean; } = {};
                    Object.entries(rm.times!).map(([tm, selected]) => {
                         resTimes[tm] = !selected && new Date(time).getDate() === selectedResDate!.getDate() && new Date(time).getTime() === new Date(tm).getTime();
                    });
                    rm.times = resTimes;
                    setReservationsMap(rm);
               }
          }
          catch (e) {
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const showProducts = async (campaign: ICampaign, type: string) => {
          try {
               setLoading(true);
               let products = await getProductsForCampaign(campaign, type);
               if (products) {
                    setProducts(products);
                    setViewProducts(true);
               }
               setLoading(false);
          }
          catch (e) {
               setLoading(false);
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const getCallNumber = (branch: IBranchLocation) => {
          return "tel:" + branch.countryCode + branch.contactPhoneNumber;
     }

     const reserve = async () => {
          if (selectedPass && selectedBranch && selectedResDate && selectedResTime) {
               const deleteRes = selectedPass.reservationBranch === selectedBranch.branchId && new Date(selectedPass.reservation).getDate() === selectedResDate.getDate();
               await alertController.create(
                    {
                         message: deleteRes ? "Delete reservation?" : "Confirm reservation?",
                         buttons: [
                              {
                                   text: 'No',
                                   role: 'cancel'
                              },
                              {
                                   text: 'Yes',
                                   handler: async () => {
                                        try {
                                             setLoading(true);
                                             const date = new Date();
                                             let dt = ("00" + (date.getMonth() + 1)).slice(-2)
                                                  + "/" + ("00" + date.getDate()).slice(-2)
                                                  + "/" + date.getFullYear() + " "
                                                  + ("00" + date.getHours()).slice(-2) + ":"
                                                  + ("00" + date.getMinutes()).slice(-2)
                                                  + ":" + ("00" + date.getSeconds()).slice(-2);
                                             let seldt = ("00" + (selectedResDate.getMonth() + 1)).slice(-2)
                                                  + "/" + ("00" + selectedResDate.getDate()).slice(-2)
                                                  + "/" + selectedResDate.getFullYear() + " "
                                                  + ("00" + selectedResTime.getHours()).slice(-2) + ":"
                                                  + ("00" + selectedResTime.getMinutes()).slice(-2)
                                                  + ":00";
                                             let resdt = new Date(seldt + " UTC");
                                             const reservations = await processReservation(selectedPass.businessId!, selectedBranch.branchId!, selectedPass.customerId!, selectedPass.id!, resdt, deleteRes, dt, seldt);
                                             setReservationsMap(reservations);
                                             setReservations(reservations.reservations);
                                             // const pass = reservations.passList!.filter(c => c.id === selectedPass.id)[0];
                                             // if (pass) {
                                             //      setSelectedPass(pass);
                                             //      setSelectedPassVal(pass);
                                             // }
                                             setLoading(false);
                                        }
                                        catch (e) {
                                             setLoading(false);
                                             setError({ showError: true, message: (e as Error).message });
                                        }
                                   }
                              }
                         ]
                    }).then(alert => alert.present());
          }
     }

     const checkIn = async () => {
          if (selectedPass && selectedBranch) {
               const cancel = selectedPass.checkInBranch === selectedBranch.branchId && new Date(selectedPass.checkIn).getDate() === new Date().getDate();
               await alertController.create(
                    {
                         message: cancel ? "Cancel check-in?" : "Confirm check-in?",
                         buttons: [
                              {
                                   text: 'No',
                                   role: 'cancel'
                              },
                              {
                                   text: 'Yes',
                                   handler: async () => {
                                        try {
                                             setLoading(true);
                                             const pass = await processCheckIn(selectedPass.businessId!, selectedBranch.branchId!, selectedPass.customerId!, selectedPass.id!, selectedBranch.messagingId!, cancel);
                                             setSelectedPass(pass);
                                             setSelectedPassVal(pass);
                                             setLoading(false);
                                        }
                                        catch (e) {
                                             setLoading(false);
                                             setError({ showError: true, message: (e as Error).message });
                                        }
                                   }
                              }
                         ]
                    }).then(alert => alert.present());
          }
     }

     const showPassDetails = async (pass: ICustomerPass) => {
          if (pass) {
               try {
                    setLoading(true);
                    const visits = await getCustomerVisits(pass.businessId!, pass.customerId!);
                    setVisits(visits);
                    setSelectedPass(pass);
                    let business = businesses && businesses.find(c => c.id === pass.businessId);
                    setSelectedBusiness(business);
                    setSessionBlockData(business, pass, visits);
                    setSelectedVisits([]);
                    history.push('/wallet/passdetails', { direction: 'forward' });
                    setLoading(false);
               } catch (e) {
                    setLoading(false);
                    setError({ showError: true, message: (e as Error).message });
               }
          }
     }

     const getLocation = async () => {
          try {
               const position = await Geolocation.getCurrentPosition();
               return position;
          }
          catch (e) {
               setError({ showError: true, message: (e as Error).message });
          }
     }

     const launchNav = async (lat: number, lng: number) => {
          setLoading(true);
          var location = await getLocation();
          if (location && location.coords) {
               await Browser.open({ url: "https://www.google.com/maps/dir/?api=1&origin=" + location.coords.latitude + "," + location.coords.longitude + "&destination=" + lat + "," + lng });
          }
          setLoading(false);
     }

     return (
          <IonPage>
               <IonHeader>
                    <IonToolbar>
                         <IonButtons slot="start">
                              <IonMenuButton />
                         </IonButtons>
                         {customer &&
                              <IonTitle>Hello {customer.userName}</IonTitle>
                         }
                    </IonToolbar>
               </IonHeader>
               <IonHeader>
                    <IonToolbar>
                         <div className={mode === "ios" ? "ion-padding-top" : ""}>
                              <IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchPass(e.detail.value)}></IonSearchbar>
                         </div>
                         <div className={mode === "ios" ? "ion-padding-bottom ios-padding-start ion-text-center" : "ion-padding-vertical ios-padding-start ion-text-center"}>
                              {filters && filters.map((cgy) => (
                                   <div key={cgy.id} className={cgy.selected ? "filter-btn-sel" : "filter-btn"} onClick={() => setFilterId(cgy.id)}>{cgy.name}</div>
                              ))}
                         </div>
                    </IonToolbar>
               </IonHeader>
               <IonContent>
                    <IonToast
                         isOpen={error.showError}
                         position="top"
                         onDidDismiss={() => setError({ message: "", showError: false })}
                         message={error.message}
                         duration={3000}
                    />
                    <IonLoading
                         isOpen={loading}
                         onDidDismiss={() => setLoading(false)}
                         message={'Processing...Please wait...'}
                    />
                    <IonRefresher slot="fixed" onIonRefresh={(e) => refresh(e.detail.complete)} pullFactor={0.5} pullMin={200} pullMax={300}>
                         <IonRefresherContent pullingIcon={chevronDownCircleOutline} />
                    </IonRefresher>
                    <IonList className="ion-padding-top">
                         {passes && passes.map((pass) => (
                              <IonCard key={pass.id} style={{ background: pass.tierCardColor }} className="pass">
                                   <IonGrid>
                                        <IonRow>
                                             <IonCol className="ion-no-margin ion-no-padding">
                                                  <IonLabel className="title">
                                                       {businesses && businesses.find(c => c.id === pass.businessId)!.companyName}
                                                  </IonLabel>
                                             </IonCol>
                                             <IonCol size="3" className="title ion-text-right ion-no-margin ion-no-padding">
                                                  <IonLabel>
                                                       {helpers.toCurrency(customer!.country!, ((pass.pointsAccrued - pass.pointsRedeemed - pass.pointsExpired) / pass.pointsRedemptionRatio))}
                                                  </IonLabel>
                                             </IonCol>
                                        </IonRow>
                                        <IonRow>
                                             <IonCol className="ion-no-margin ion-no-padding subtitle">
                                                  <IonDatetime readonly className="ion-no-margin ion-no-padding" displayFormat="MMM D YYYY, HH:mm" value={new Date(pass.checkOut).getFullYear() > 1900 ? pass.checkOut.toLocaleString() : ''} />
                                             </IonCol>
                                             <IonCol size="3" className="ion-text-right ion-no-margin ion-no-padding subtitle">
                                                  <IonLabel>
                                                       {pass.tierName}
                                                  </IonLabel>
                                             </IonCol>
                                        </IonRow>
                                        <IonRow>
                                             <IonCol>
                                                  <IonCard color="light" className="ad">
                                                       <IonSlides pager={true} options={slideOpts}>
                                                            <IonSlide>
                                                                 <img src={businesses!.find(c => c.id === pass.businessId)!.logoUrl} alt={businesses!.find(c => c.id === pass.businessId)!.companyName} />
                                                            </IonSlide>
                                                            {campaigns && campaigns[pass.businessId!].filter(e => new Date(e.end) >= new Date()).map((campaign) => (
                                                                 <IonSlide style={{ background: campaign.creativeBGColor }}>
                                                                      <img src="/assets/img/creative-frame1.png" alt="bg" />
                                                                      <div className="absolute special-offer">
                                                                           <img src="/assets/img/special-offer.png" alt="special offer" />
                                                                      </div>
                                                                      <IonLabel className="absolute hello">Hello {customer!.firstName}</IonLabel>
                                                                      <div style={{ color: campaign.creativeBGColor === "White" ? "RoyalBlue" : "White" }} className="absolute line1">{getLine1Text(campaign)}<a onClick={() => showProducts(campaign, "X")}>{campaign.productsX!.length > 1 ? "products" : ""}</a></div>
                                                                      <IonLabel style={{ color: campaign.creativeBGColor === "White" ? "DimGray" : "White" }} className="absolute line2">{getLine2Text(campaign)}</IonLabel>
                                                                      <IonLabel style={{ color: campaign.creativeBGColor === "White" ? "SeaGreen" : "Gold" }} className="absolute line3">{getLine3Text(campaign)}</IonLabel>
                                                                      <IonLabel style={{ color: campaign.creativeBGColor === "White" ? "DimGray" : "White" }} className="absolute line4">{getLine4Text(campaign)}<a onClick={() => showProducts(campaign, "Y")}>{campaign.offerType === OfferTypes.Free && campaign.productsY!.length > 1 ? "products" : ""}</a></IonLabel>
                                                                 </IonSlide>
                                                            ))}
                                                            <IonSlide>
                                                                 <IonGrid className="ion-no-margin ion-no-padding map-canvas show-map">
                                                                      <Map locations={businesses!.find(c => c.id === pass.businessId)!.branchLocations!} mapCenter={mapCenter!} />
                                                                 </IonGrid>
                                                                 <IonGrid className="map-canvas" />
                                                            </IonSlide>
                                                       </IonSlides>
                                                  </IonCard>
                                             </IonCol>
                                        </IonRow>
                                        <IonRow>
                                             <IonCol className="ion-no-margin ion-no-padding">
                                                  <IonGrid>
                                                       <IonRow>
                                                            <IonCol size="4">
                                                                 <IonLabel className="stats">{pass.businessId}-{pass.customerId}</IonLabel>
                                                                 <IonLabel className="stats">Visits: {pass.visits}</IonLabel>
                                                                 <IonLabel className="stats">Spend: {helpers.toCurrency(customer!.country!, pass.spend)}</IonLabel>
                                                                 <IonLabel className="stats">Points Earned: {pass.pointsAccrued}</IonLabel>
                                                                 <IonLabel className="stats">Points Redeemed: {pass.pointsRedeemed}</IonLabel>
                                                            </IonCol>
                                                            <IonCol>
                                                                 <div className="btn" onClick={() => showPassDetails(pass)}>
                                                                      <IonIcon style={{ marginTop: "10px" }} slot="icon-only" icon={gridOutline} color="success" size="large" />
                                                                      <div style={{ fontSize: "10px" }}>Menu</div>
                                                                 </div>
                                                            </IonCol>
                                                            <IonCol>
                                                                 <div className="btn" onClick={() => showPassDetails(pass)}>
                                                                      <IonIcon style={{ marginTop: "10px" }} slot="icon-only" icon={cart} color="success" size="large" />
                                                                      <div style={{ fontSize: "10px" }}>Visits</div>
                                                                 </div>
                                                            </IonCol>
                                                            <IonCol>
                                                                 <div className="btn" onClick={() => showCheckIn(pass)}>
                                                                      <IonIcon style={{ marginTop: "10px" }} slot="icon-only" icon={handRight} color="success" size="large" />
                                                                      <div style={{ fontSize: "10px" }}>Reservation</div>
                                                                 </div>
                                                            </IonCol>
                                                       </IonRow>
                                                  </IonGrid>
                                             </IonCol>
                                        </IonRow>
                                   </IonGrid>
                              </IonCard>
                         ))}
                    </IonList>
               </IonContent>
               <IonModal isOpen={viewProducts}>
                    <IonList>
                         {products && products.map((product) => (
                              <IonItem key={product.id}>
                                   <IonThumbnail slot="start">
                                        <img src={product.imageUrl} alt={product.description} />
                                   </IonThumbnail>
                                   <IonLabel>{product.description}</IonLabel>
                                   <IonLabel className="ion-text-right" slot="end">{helpers.toCurrency(customer!.country!, product.price)}</IonLabel>
                              </IonItem>
                         ))}
                    </IonList>
                    <IonButton onClick={() => setViewProducts(false)}>Close</IonButton>
               </IonModal>
               <IonModal isOpen={viewCheckin} showBackdrop={true}>
                    <IonGrid className="container">
                         <IonRow>
                              <IonCol className="ion-no-margin ion-no-padding">
                                   <IonLabel className="title">
                                        {selectedPass && businesses && businesses.find(c => c.id === selectedPass.businessId)!.companyName}
                                   </IonLabel>
                              </IonCol>
                              <IonCol size="3" className="title ion-text-right ion-no-margin ion-no-padding">
                                   <IonLabel>
                                        {selectedPass && helpers.toCurrency(customer!.country!, ((selectedPass.pointsAccrued - selectedPass.pointsRedeemed - selectedPass.pointsExpired) / selectedPass.pointsRedemptionRatio))}
                                   </IonLabel>
                              </IonCol>
                         </IonRow>
                         <IonRow>
                              <IonCol className="ion-no-margin ion-no-padding subtitle">
                                   <IonDatetime readonly className="ion-no-margin ion-no-padding" displayFormat="MMM D YYYY, HH:mm" value={selectedPass && new Date(selectedPass!.checkOut).getFullYear() > 1900 ? selectedPass!.checkOut.toLocaleString() : ''} />
                              </IonCol>
                              <IonCol size="3" className="ion-text-right ion-no-margin ion-no-padding subtitle">
                                   <IonLabel>
                                        {selectedPass && selectedPass!.tierName}
                                   </IonLabel>
                              </IonCol>
                         </IonRow>
                         <IonRow>
                              <IonCol>
                                   <IonGrid className="ion-no-margin ion-no-padding map">
                                        <Map locations={selectedPass && businesses && businesses.find(c => c.id === selectedPass.businessId)!.branchLocations || []} mapCenter={mapCenter!} />
                                   </IonGrid>
                              </IonCol>
                         </IonRow>
                         {selectedBranch && selectedPass &&
                              <IonRow>
                                   <IonCol>
                                        <IonCard className="reservation">
                                             <IonCardHeader className="header">
                                                  <IonGrid className="ion-no-margin ion-no-padding">
                                                       <IonRow>
                                                            <IonCol className="ion-no-margin ion-no-padding">
                                                                 <div className="title">{selectedBranch.branchName}</div>
                                                                 <div className="subtitle">{(selectedPass.reservationBranch === selectedBranch.branchId && new Date(selectedPass.reservation) > new Date() ? "Next reservation: " + new Date(selectedPass.reservation).toLocaleDateString() + ", " + new Date(selectedPass.reservation).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "No reservations")}</div>
                                                                 <div className="subtitle">{(selectedPass.reservationBranch === selectedBranch.branchId && new Date(selectedPass.checkIn) > new Date() ? "Check-in: " + new Date(selectedPass.checkIn).toLocaleDateString() + ", " + new Date(selectedPass.checkIn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "Not checked-in")}</div>
                                                            </IonCol>
                                                            <IonCol size="2" className="ion-no-margin ion-no-padding">
                                                                 <IonButton className="btn" color="light" onClick={() => launchNav(selectedBranch.latitude, selectedBranch.longitude)}>
                                                                      <IonIcon slot="icon-only" icon={locate} color="success" />
                                                                 </IonButton>
                                                            </IonCol>
                                                            <IonCol size="2" className="ion-no-margin ion-no-padding">
                                                                 <IonButton className="btn" color="light" href={getCallNumber(selectedBranch)}>
                                                                      <IonIcon slot="icon-only" icon={call} color="success" />
                                                                 </IonButton>
                                                            </IonCol>
                                                       </IonRow>
                                                  </IonGrid>
                                             </IonCardHeader>
                                             {reservationsEnabled && <IonCardContent className="ion-no-margin ion-no-padding">
                                                  <div className="res-panel">
                                                       {/* {reservationsMap && Object.entries(reservationsMap.dates!).map(([date, selected]) => (
                                                            <div onClick={() => reservationDateSelected(new Date(date))}>
                                                                 <IonDatetime readonly className={selected || selectedResDate!.getDate() === new Date(date).getDate() ? "res-date selected" : "res-date unselected"} displayFormat="MMM D" value={new Date(date).toLocaleDateString()} />
                                                            </div>
                                                       ))} */}
                                                  </div>
                                                  <div className={loadingRes ? "ion-text-center ion-margin-top ion-show" : "ion-text-center ion-margin-top ion-hide"}>
                                                       <IonSpinner name="dots" color="primary" />
                                                  </div>
                                                  <div className={loadingRes ? "res-panel ion-hide" : "res-panel ion-show"}>
                                                       {reservationsMap && Object.entries(reservationsMap.times!).map(([time, selected]) => (
                                                            <div onClick={() => reservationTimeSelected(new Date(time))}>
                                                                 <IonLabel className={selected ? "res-time selected" : "res-time unselected"}>{new Date(time + "Z").toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</IonLabel>
                                                            </div>
                                                       ))}
                                                  </div>
                                                  {reservationsMap && (reservationsMap.times! || Object.keys(reservationsMap.times!).length <= 0) &&
                                                       <div className="ion-text-center ion-margin-top">
                                                            <div>no reservations available</div>
                                                       </div>
                                                  }
                                             </IonCardContent>}
                                        </IonCard>
                                   </IonCol>
                              </IonRow>}
                         <IonRow>
                              <IonCol className="ion-text-center">
                                   <IonButton className="m-r-10" color="warning" onClick={() => setViewCheckin(false)}>Close</IonButton>
                                   {reservationsEnabled && selectedPass && selectedBranch && selectedResDate && <IonButton className="m-r-10" color="danger" onClick={() => reserve()}>{(selectedPass.reservationBranch === selectedBranch.branchId && new Date(selectedPass.reservation).getDate() === selectedResDate.getDate() ? "Unreserve" : "Reserve")}</IonButton>}
                                   {selectedPass && selectedBranch && <IonButton color="success" onClick={() => checkIn()}>{selectedPass.checkInBranch === selectedBranch.branchId && new Date(selectedPass.checkIn).getDate() === new Date().getDate() && (new Date(selectedPass.checkOut) < new Date(selectedPass.checkIn)) ? "Cancel CheckIn" : "CheckIn"}</IonButton>}
                              </IonCol>
                         </IonRow>
                    </IonGrid>
               </IonModal>
               <IonModal isOpen={viewPay} showBackdrop={true}>
                    <Pay history={history} location={location} match={match} />
               </IonModal>
          </IonPage>
     );
};

export default connect<OwnProps, StateProps, DispatchProps>({
     mapStateToProps: (state) => ({
          businesses: selectors.getSearchedBusinesses(state),
          campaigns: state.customer.campaigns,
          customer: state.customer.customer,
          filters: selectors.getCategories(state),
          loading: state.session.loading,
          mapCenter: selectors.mapCenter(state),
          mode: getConfig()!.get('mode'),
          passes: selectors.getPassList(state),
          payVisits: state.customer.visits,
          reservationsMap: state.session.reservationsMap,
          selectedBranch: state.session.selectedBranch,
          selectedResDate: state.session.selectedResDate,
          selectedResTime: state.session.selectedResTime,
          viewPay: state.session.viewPay,
          walletPay: state.session.walletPay
     }),
     mapDispatchToProps: {
          setSearchPass,
          setFilterId,
          setReservationsMap,
          setSelectedBusiness,
          setSelectedBranch,
          setSelectedPass,
          setSelectedVisits,
          setSelectedResDate,
          setSelectedResTime,
          setLoading,
          setPasses,
          setPaidWithPoints,
          setPayAmount,
          setPayVisits,
          setPointsToRedeem,
          setReservations,
          setTipAmount,
          setViewPay,
          setVisits,
          setWalletPay
     },
     component: WalletPage
});