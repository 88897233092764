import { ServiceItem } from "./Base/CustomerVisit";

export class CartItem {
     sequence: number;
     productClass: string;
     productCode: string;
     productDepartment: string;
     productFineline: string;
     productHSN: string;
     productId: string;
     description: string;
     quantity: number;
     cost: number;
     price: number;
     retailPrice: number;
     remove: boolean;
     service: boolean;
     serviceTime: number;
     taxPercent: number;
     totalPrice: number;
     constructor(sequence: number, productClass: string, productCode: string, productDepartment: string, productFineline: string, productHSN: string, productId: string, description: string, quantity: number, cost: number, price: number, retailPrice: number, remove: boolean, service: boolean, serviceTime: number, taxPercent: number, totalPrice: number) {
          this.sequence = sequence;
          this.productClass = productClass;
          this.productCode = productCode;
          this.productDepartment = productDepartment;
          this.productFineline = productFineline;
          this.productHSN = productHSN;
          this.productId = productId;
          this.description = description;
          this.quantity = quantity;
          this.cost = cost;
          this.price = price;
          this.retailPrice = retailPrice;
          this.remove = remove;
          this.service = service;
          this.serviceTime = serviceTime;
          this.taxPercent = taxPercent;
          this.totalPrice = totalPrice;
     }

     static getServiceItem(cartItem: CartItem) {
          var serviceItem = new ServiceItem();
          serviceItem.description = cartItem.description;
          serviceItem.discounts = [];
          serviceItem.discountTotal = 0;
          serviceItem.cost = cartItem.cost;
          serviceItem.price = cartItem.price;
          serviceItem.productClass = cartItem.productClass;
          serviceItem.productCode = cartItem.productCode;
          serviceItem.productDepartment = cartItem.productDepartment;
          serviceItem.productFineline = cartItem.productFineline;
          serviceItem.productHSN = cartItem.productHSN;
          serviceItem.productId = cartItem.productId;
          serviceItem.quantity = cartItem.quantity;
          serviceItem.retailPrice = cartItem.retailPrice;
          serviceItem.refundCost = 0;
          serviceItem.refundDiscountTotal = 0;
          serviceItem.refundPrice = 0;
          serviceItem.refundQuantity = 0;
          serviceItem.refundTotal = 0;
          serviceItem.sequence = cartItem.sequence;
          serviceItem.service = cartItem.service;
          serviceItem.serviceTime = cartItem.serviceTime;
          serviceItem.taxAmount = parseFloat((cartItem.totalPrice * cartItem.taxPercent / 100).toFixed(2));
          serviceItem.taxPercent = cartItem.taxPercent;
          serviceItem.totalPrice = cartItem.totalPrice;
          return serviceItem;
     }
}