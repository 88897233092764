import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonHeader, IonLabel, IonToolbar, IonButtons, IonContent, IonButton, IonBackButton, IonPage, IonTitle, IonSearchbar, IonList, IonItemSliding, IonItem, IonItemOption, IonItemOptions, IonGrid, IonRow, IonCol, IonFooter, IonRefresher, IonRefresherContent, IonModal, getConfig, IonToast, IonToggle } from '@ionic/react'
import { connect } from '../data/connect';
import { addSelectedVisit, removeSelectedVisit, setSearchUnpaid, setSearchVisit, setSelectedBranch, setSelectedPass, setSelectedVisit, setSelectedVisits, setViewPay, setVisits } from '../data/session/session.actions';
import './PassDetailsPage.scss';
import { getCustomerVisits } from '../data/dataApi';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { Browser } from '@capacitor/core';
import * as helpers from '../util/helpers';
import * as selectors from '../data/selectors';
import Pay from '../components/Pay';
import { IBusinessEntity } from '../models/Base/BusinessEntity';
import { ICustomerPass } from '../models/Base/CustomerPass';
import { ICustomer } from '../models/Base/Customer';
import { ICustomerVisit } from '../models/Base/CustomerVisit';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  business?: IBusinessEntity,
  customer?: ICustomer,
  mode: 'ios' | 'md',
  pass?: ICustomerPass,
  selectedVisits?: ICustomerVisit[],
  searchUnpaid: boolean,
  viewPay: boolean,
  visits?: ICustomerVisit[]
};

interface DispatchProps {
  setSearchVisit: typeof setSearchVisit;
  setVisits: typeof setVisits;
  setSearchUnpaid: typeof setSearchUnpaid;
  setSelectedBranch: typeof setSelectedBranch;
  setSelectedPass: typeof setSelectedPass;
  setSelectedVisit: typeof setSelectedVisit;
  setSelectedVisits: typeof setSelectedVisits;
  addSelectedVisit: typeof addSelectedVisit;
  removeSelectedVisit: typeof removeSelectedVisit;
  setViewPay: typeof setViewPay;
};

interface Error {
  showError: boolean;
  message?: string;
}

interface PassDetailsProps extends OwnProps, StateProps, DispatchProps { };

const PassDetails: React.FC<PassDetailsProps> = ({ history, location, match, business, customer, pass, searchUnpaid, selectedVisits, viewPay, visits, setVisits, setSelectedBranch, setSelectedVisit, setSelectedVisits, setSearchVisit, addSelectedVisit, removeSelectedVisit, mode, setSearchUnpaid, setViewPay }) => {

  const [error, setError] = useState<Error>({ showError: false });

  const select = (visit: ICustomerVisit) => {
    if (visit && visit.paidAmount <= 0) {
      if (selectedVisits!.indexOf(visit) < 0) {
        addSelectedVisit(visit);
      } else {
        removeSelectedVisit(visit.id!);
      }
    }
  }

  const selectAll = (unselect: boolean) => {
    setSelectedVisits([]);
    if (!unselect && visits) {
      visits.filter(e => e.paidAmount <= 0)!.forEach((visit) => addSelectedVisit(visit));
    }
  }

  const refresh = async (complete: any) => {
    try {
      const visits = await getCustomerVisits(pass!.businessId!, pass!.customerId!);
      setVisits(visits);
      setSelectedVisits([]);
      if (complete) {
        complete();
      }
    } catch (e) {
      setError({ showError: true, message: (e as Error).message });
    }
  }

  const viewReceipt = async (visit: ICustomerVisit) => {
    if (visit && visit.paymentReceiptUrl) {
      await Browser.open({ url: visit.paymentReceiptUrl });
    }
  }

  const viewInvoice = async (visit: ICustomerVisit) => {
    if (visit && visit.invoiceNumber) {
      const branch = business!.branchLocations!.find(e => e.branchId === visit.branchId);
      setSelectedBranch(branch!);
      setSelectedVisit(visit);
      history.push('/wallet/passdetails/receipt', { direction: 'forward' });
    }
  }

  const viewDetails = async (visit: ICustomerVisit) => {
    if (visit) {
      setSelectedVisit(visit);
      history.push('/wallet/passdetails/visitdetails', { direction: 'forward' });
    }
  }

  return (
    <IonPage id="pass-details">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/wallet" />
          </IonButtons>
          <IonTitle>{business!.companyName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonHeader>
        <IonToolbar>
          <div className={mode === "md" ? "" : "ion-padding-top"} style={{ position: "relative" }}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchVisit(e.detail.value)}></IonSearchbar>
                </IonCol>
                <IonCol size="2">
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "10px" }}>Unpaid</div>
                    <IonToggle checked={searchUnpaid} onIonChange={(e: CustomEvent) => setSearchUnpaid(e.detail.checked)} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={error.showError}
          position="top"
          onDidDismiss={() => setError({ message: "", showError: false })}
          message={error.message}
          duration={3000}
        />
        <IonRefresher slot="fixed" onIonRefresh={(e) => refresh(e.detail.complete)} pullFactor={0.5} pullMin={200} pullMax={300}>
          <IonRefresherContent pullingIcon={chevronDownCircleOutline} />
        </IonRefresher>
        <IonList>
          {visits && visits.filter(e => e.invoiceNumber && e.invoiceNumber !== '').map((visit) => (
            <IonItemSliding key={visit.id} onClick={() => select(visit)}>
              <IonItem lines="none" className="ion-no-margin ion-no-padding">
                <div className={selectedVisits!.indexOf(visit) >= 0 ? "panel selected" : "panel"}>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="8" className="ion-no-margin ion-no-padding">
                        <div className="service">
                          <IonLabel className="serviceDesc">{visit.serviceDesc}</IonLabel>
                          <div className="paymentDesc">{visit.invoiceNumber}</div>
                          <div className="paymentDesc">{visit.paymentDesc}</div>
                        </div>
                        <IonGrid className="ion-no-margin ion-no-padding">
                          <span className="date">{new Date(visit.checkOut).toLocaleString()}</span>
                          <span className="points">{visit.basePoints + visit.bonusPoints} points</span>
                        </IonGrid>
                      </IonCol>
                      <IonCol size="4" className="ion-no-margin ion-no-padding">
                        <div className="vl"></div>
                        <IonGrid className="ion-no-margin ion-no-padding">
                          <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Cost</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount green">{helpers.toCurrency(customer!.country!, visit.spend)}</span></IonCol></IonRow>
                          <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Discount</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount red">- {helpers.toCurrency(customer!.country!, visit.discount)}</span></IonCol></IonRow>
                          <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Tax</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount green">+ {helpers.toCurrency(customer!.country!, visit.tax)}</span></IonCol></IonRow>
                          <IonRow className="amount-row"><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount-title">Tips</span></IonCol><IonCol className="ion-no-margin ion-no-padding ion-text-right"><span className="amount green">+ {helpers.toCurrency(customer!.country!, visit.tip)}</span></IonCol></IonRow>
                          <IonRow className="amount-row"><IonCol className="ion-text-right"><span className="total">{helpers.toCurrency(customer!.country!, visit.paymentAmount)}</span></IonCol></IonRow>
                        </IonGrid>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {visit.paymentAmount > 0 && visit.paidAmount <= 0 && <div style={{ color: "red", fontWeight: "bold", position: "absolute", top: "33%", left: "45%" }}><img src="/assets/img/unpaid.png" height="40" /></div>}
                </div>
              </IonItem>
              <IonItemOptions side="end" >
                <IonItemOption>
                  <IonGrid>
                    <IonRow>
                      <div className="slide-btn" onClick={() => viewDetails(visit)}>Details</div>
                    </IonRow>
                    <IonRow>
                      <div className="slide-btn" onClick={() => viewInvoice(visit)}>Receipt</div>
                    </IonRow>
                  </IonGrid>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
        </IonList>

      </IonContent>
      <IonFooter className="ion-text-center">
        <IonButton className="m-r-10" onClick={() => selectAll(true)} disabled={selectedVisits!.length <= 0}>Unselect All</IonButton>
        <IonButton className="m-r-10" onClick={() => selectAll(false)}>Select All</IonButton>
        <IonButton onClick={() => setViewPay(true)} disabled={selectedVisits!.length <= 0}>Pay {selectedVisits!.length > 0 ? helpers.toCurrency(customer!.country!, selectedVisits!.map(item => item.paymentAmount).reduce((prev, curr) => prev + curr, 0)) : ""}</IonButton>
      </IonFooter>
      <IonModal isOpen={viewPay} showBackdrop={true}>
        <Pay history={history} location={location} match={match} />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    business: state.session.selectedBusiness,
    customer: state.customer.customer,
    mode: getConfig()!.get('mode'),
    pass: state.session.selectedPass,
    searchUnpaid: state.session.searchUnpaid,
    selectedVisits: state.session.selectedVisits,
    viewPay: state.session.viewPay,
    visits: selectors.getSearchedVisits(state)
  }),
  mapDispatchToProps: {
    addSelectedVisit,
    removeSelectedVisit,
    setSearchUnpaid,
    setSearchVisit,
    setSelectedBranch,
    setSelectedPass,
    setSelectedVisit,
    setSelectedVisits,
    setVisits,
    setViewPay
  },
  component: PassDetails
});